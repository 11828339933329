import { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MDTabs from "components/MDTabs/MDTabs";
import { MDAutoComplete } from "components/MDAutoComplete";
import { Button, Card, CircularProgress, Grid } from "@mui/material";
import { TextField, Autocomplete } from "@mui/material";

// Yup Validation imports
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import validationParams from "validationParams";

// Datepicker import
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

//Radio group
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// API Import
import API from "APIUrls";
import APIService from "services/api-service";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function CustomAccordionPanel(props) {
    const { children, summary, expanded, onChange } = props;

    return (
        <Accordion expanded={expanded} onChange={onChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
            >
                <Typography>{summary}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}

CustomAccordionPanel.propTypes = {
    children: PropTypes.node,
    summary: PropTypes.string.isRequired,
    expanded: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};


const DischargeSummaryForm = () => {

    const { patientId } = useParams();

    const [mortalityDate, setMortalityDate] = useState(dayjs());
    const [selectedLocOfDate, setSelectedLocOfDate] = useState("or");
    const [selectedDischargeLocation, setSelectedDischargeLocation] = useState("home");
    const [selectedCauseOfDeath, setSelectedCauseOfDeath] = useState("cardiac");

    const [values, setValues] = useState(defaultValues);

    const [responseError, setResponseError] = useState(null);
    const [responseListError, setResponseListError] = useState(null);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [readmit, setReadmit] = useState("");

    const responseRef = useRef({
        responseFlag: false,
        responseCode: 200,
        responseMessage: "",
    });

    const validationSchema = Yup.object().shape({

    });
    const {
        handleSubmit,
        register,
        formState: { errors },
        getValues,
        reset,
        setValue,
    } = useForm({ resolver: yupResolver(validationSchema) });


    // Load form data from localStorage when the component mounts
    useEffect(() => {
        const savedFormData = localStorage.getItem("formData");
        if (savedFormData) {
            // console.log(savedFormData);
            const parsedFormData = JSON.parse(savedFormData);
            // console.log(parsedFormData, "data");
            setValues(parsedFormData);
        }
    }, []);

    var defaultValues = {
        txtAspirin: "",
        txtAceInhibitors: "",
        txtBetaBlockers: "",
        txtLipidLowering: "",
        txtOthAntiPlatelets: "",
        txtDischargeLocation: "",
        txtMortality: "",
        txtDischargeStatus: "",
        txtStatusAfterSurgery: "",
        txtOperativeDeath: "",
        txtMortalityDate: "",
        txtLocationOfDeath: "",
        txtCauseOfDeath: "",
        txtReadmit: "",
        txtReadmissionReason: "",
    };

    useEffect(() => {
        async function fetch() {
            try {
                // debugger
                const formDischargeSummaary = localStorage.getItem(
                    "formDischargeSummaary_" + patientId
                );
                if (formDischargeSummaary === null) {
                    const payload = {
                        intSPType: 2,
                        txtUUID: patientId,
                    };
                    const tempResponseData = await APIService.post(
                        API.DischargeSummaryForm,
                        payload
                    );
                    console.log(tempResponseData);

                    if (
                        tempResponseData?.code === 200 ||
                        tempResponseData?.code === "200"
                    ) {
                        //   setTblList(tempResponseData.data ?? []);
                        //   setRowCount(tempResponseData.count ?? 0);

                        // console.log(tempResponseData.data[0].participant_id);
                        if (tempResponseData.data[0]) {
                            const temp = tempResponseData.data[0];
                            const defaultValues1 = {
                                intID: temp.id,
                                txtAspirin: temp.aspirin,
                                txtAceInhibitors: temp.ace_inhibitors,
                                txtBetaBlockers: temp.beta_blockers,
                                txtLipidLowering: temp.lipid_lowering,
                                txtOthAntiPlatelets: temp.oth_anti_platelets,
                                txtDischargeLocation: temp.discharge_location,
                                txtMortality: temp.mortality,
                                txtDischargeStatus: temp.discharge_status,
                                txtStatusAfterSurgery: temp.status_after_surgery,
                                txtOperativeDeath: temp.operative_death,
                                txtMortalityDate: temp.mortality_date,
                                txtLocationOfDeath: temp.location_of_death,
                                txtCauseOfDeath: temp.cause_of_death,
                                txtReadmit: temp.readmit,
                                txtReadmissionReason: temp.readmission_reason,
                            };
                            setSelectedLocOfDate(temp.location_of_death);
                            setMortalityDate(temp.mortality_date)
                            setSelectedDischargeLocation(temp.discharge_location)
                            setSelectedCauseOfDeath(temp.cause_of_death)

                            console.log(defaultValues1, "data");
                            reset(defaultValues1);
                            setValues(defaultValues1);
                            localStorage.setItem(
                                "formDischargeSummaary_" + patientId,
                                JSON.stringify(defaultValues1)
                            );
                        } else {
                            var defaultValues1 = {
                                txtAspirin: "",
                                txtAceInhibitors: "",
                                txtBetaBlockers: "",
                                txtLipidLowering: "",
                                txtOthAntiPlatelets: "",
                                txtDischargeLocation: "",
                                txtMortality: "",
                                txtDischargeStatus: "",
                                txtStatusAfterSurgery: "",
                                txtOperativeDeath: "",
                                txtMortalityDate: "",
                                txtLocationOfDeath: "",
                                txtCauseOfDeath: "",
                                txtReadmit: "",
                                txtReadmissionReason: "",
                            };
                            setValues(defaultValues1);
                            localStorage.setItem(
                                "formDischargeSummaary_" + patientId,
                                JSON.stringify(defaultValues1)
                            );
                        }
                    }
                    setIsLoading(false);
                } else {
                    setValues(JSON.parse(formDischargeSummaary));
                    setSelectedLocOfDate(JSON.parse(formDischargeSummaary).txtLocationOfDeath);
                    setMortalityDate(dayjs(JSON.parse(formDischargeSummaary).txtMortalityDate));
                    setSelectedCauseOfDeath(JSON.parse(formDischargeSummaary).txtCauseOfDeath);
                    setSelectedDischargeLocation(JSON.parse(formDischargeSummaary).txtDischargeLocation)
                    //setDate(dayjs(JSON.parse(formDischargeSummaary).txtDOB))

                    reset(JSON.parse(formDischargeSummaary));
                }
            } catch (error) {
                setIsError(true);
                setIsLoading(false);
                console.error(error);
                return;
            }
        }
        fetch();
    }, []);


    const handleChange = (event) => {
        const { name, value } = event.target;

        setValues((prevValues) => {
            // Update form values
            const updatedValues = { ...prevValues, [name]: value };

            // Save the updated values to localStorage
            localStorage.setItem(
                "formDischargeSummaary_" + patientId,
                JSON.stringify(updatedValues)
            );

            // Return the updated values to update state
            return updatedValues;
        });
    };
    const handleDropdownChange = (name, value) => {
        // const { name, value } = event.target;
        //debugger;
        setValues((prevValues) => {
            // Update form values
            const updatedValues = { ...prevValues, [name]: value };

            // Save the updated values to localStorage
            localStorage.setItem(
                "formDischargeSummaary_" + patientId,
                JSON.stringify(updatedValues)
            );

            // Return the updated values to update state
            return updatedValues;
        });
    };

    const navigate = useNavigate();

    const YesNoOptions = [
        { id: 'yes', value: 'yes', label: 'Yes', firstLetter: 'Y' },
        { id: 'no', value: 'no', label: 'No', firstLetter: 'N' },
    ];

    const dischargeLocationOptions = [
        { id: 'home', value: 'Home', label: 'Home', firstLetter: 'H' },
        { id: 'extendedCareTCU', value: 'ExtendedCareTCU', label: 'Extended Care/TCU', firstLetter: 'E' },
        { id: 'otherHospital', value: 'OtherHospital', label: 'Other Hospital', firstLetter: 'O' },
        { id: 'nursingHome', value: 'NursingHome', label: 'Nursing Home', firstLetter: 'N' },
        { id: 'other', value: 'Other', label: 'Other', firstLetter: 'O' },
    ];

    const DischargeStatusOptions = [
        { id: 'alive', value: 'alive', label: 'Alive', firstLetter: 'A' },
        { id: 'dead', value: 'dead', label: 'Dead', firstLetter: 'D' },
    ];

    const LocationOfDeathOptions = [
        { id: 'or', value: 'or', label: 'OR', firstLetter: 'O' },
        { id: 'hospital', value: 'hospital', label: 'Hospital', firstLetter: 'H' },
        { id: 'home', value: 'home', label: 'Home', firstLetter: 'H' },
        { id: 'otherFacility', value: 'otherFacility', label: 'Other Facility', firstLetter: 'O' },
    ];

    const PrimaryCauseOfDeathOptions = [
        { id: 'cardiac', value: 'cardiac', label: 'Cardiac', firstLetter: 'C' },
        { id: 'neurological', value: 'neurological', label: 'Neurological', firstLetter: 'N' },
        { id: 'renal', value: 'renal', label: 'Renal', firstLetter: 'R' },
        { id: 'vascular', value: 'vascular', label: 'Vascular', firstLetter: 'V' },
        { id: 'infection', value: 'infection', label: 'Infection', firstLetter: 'I' },
        { id: 'pulmonary', value: 'pulmonary', label: 'Pulmonary', firstLetter: 'P' },
        { id: 'valvular', value: 'valvular', label: 'Valvular', firstLetter: 'V' },
        { id: 'other', value: 'other', label: 'Other', firstLetter: 'O' },
    ];

    const ReadmissionReasonOptions = [
        { id: 'anticoagulantComplications', value: 'anticoagulantComplications', label: 'Anticoagulant Complications', firstLetter: 'A' },
        { id: 'arrhythmiasHeartBlock', value: 'arrhythmiasHeartBlock', label: 'Arrhythmias/Heart Block/Pacemaker Insertion/AICD', firstLetter: 'A' },
        { id: 'chf', value: 'chf', label: 'CHF', firstLetter: 'C' },
        { id: 'miRecurrentAngina', value: 'miRecurrentAngina', label: 'MI/Recurrent Angina', firstLetter: 'M' },
        { id: 'pericardialEffusionTamponade', value: 'pericardialEffusionTamponade', label: 'Pericardial Effusion/Tamponade', firstLetter: 'P' },
        { id: 'pneumoniaRespiratoryComplication', value: 'pneumoniaRespiratoryComplication', label: 'Pneumonia/Respiratory Complication', firstLetter: 'P' },
        { id: 'valveDysfunction', value: 'valveDysfunction', label: 'Valve Dysfunction', firstLetter: 'V' },
        { id: 'infectionDeepSternum', value: 'infectionDeepSternum', label: 'Infection Deep Sternum', firstLetter: 'I' },
        { id: 'infectionLeg', value: 'infectionLeg', label: 'Infection Leg', firstLetter: 'I' },
        { id: 'cardiacCath', value: 'cardiacCath', label: 'Cardiac Cath', firstLetter: 'C' },
        { id: 'ptcaStent', value: 'ptcaStent', label: 'PTCA Stent', firstLetter: 'P' },
        { id: 'renalFailure', value: 'renalFailure', label: 'Renal Failure', firstLetter: 'R' },
        { id: 'tia', value: 'tia', label: 'TIA', firstLetter: 'T' },
        { id: 'reopGraftOcclusion', value: 'reopGraftOcclusion', label: 'Reop for Graft Occlusion', firstLetter: 'R' },
        { id: 'reopBleeding', value: 'reopBleeding', label: 'Reop for Bleeding', firstLetter: 'R' },
        { id: 'permanentCva', value: 'permanentCva', label: 'Permanent CVA', firstLetter: 'P' },
        { id: 'acuteVascularComplication', value: 'acuteVascularComplication', label: 'Acute Vascular Complication', firstLetter: 'A' },
        { id: 'other', value: 'other', label: 'Other', firstLetter: 'O' },
    ];

    const onSubmit = async (data) => {
        try {
            setIsBtnLoading(true);
            // Retrieve and parse data from localStorage
            // const savedFormData = localStorage.getItem('formData');
            // const formData = savedFormData ? JSON.parse(savedFormData) : {};
            // console.log(formData)

            // // Add additional properties to formData if needed
            // formData.intSPType = 1;
            //formData.txtReadmit = readmissionToICU;

            // Create a FormData object
            const formData = new FormData();

            // Populate FormData with array values
            for (const key in data) {
                formData.append(key, data[key]);
            }

            formData.append("intSPType", 1);
            formData.append("intID", patientId);
            // formData.append("txtReadmissionToICU", readmissionToICU);
            //formData.append("txtDOB", date);
            formData.txtDischargeLocation = selectedDischargeLocation;
            formData.txtLocationOfDeath = selectedLocOfDate;
            formData.txtCauseOfDeath = selectedCauseOfDeath;

            // Send the formData to the server
            const saveResult = await APIService.post(API.DischargeSummaryForm, formData);
            console.log(saveResult, "saveResult");
            responseRef.current.responseFlag = true;
            responseRef.current.responseCode = saveResult.code;
            responseRef.current.responseMessage = saveResult.message;
            console.log(responseRef);

            // Check if the data is successfully saved to the database
            if (saveResult.code === 200 || saveResult.code === "200") {
                // If successful, remove data from local storage
                localStorage.removeItem("formDischargeSummaary_" + patientId);

                // Reset form values and loading state
                //setValues({});
                setIsBtnLoading(false);
                setIsLoading(false);
                // Redirect to /patients/patients
                navigate('/patients/patients');
            }
            if (saveResult.code === 201 || saveResult.code === "201") {
                setIsBtnLoading(false);
            }

        } catch (error) {
            console.error("Error:", error);
            setIsBtnLoading(false);
            if (error?.code === 5001) setResponseError(error?.message);
            if (error?.code === 5002) setResponseListError(error?.errors);
        }
        //reset({ ...defaultValues });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box sx={{ padding: "10px" }}>
                <Card sx={{ padding: "40px" }}>

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        encType="multipart/form-data"
                    >
                        <Typography
                            className="custom-heading"
                            style={{ marginBottom: "20px", fontWeight: "bold" }}
                        >
                            S. Discharge
                        </Typography>
                        <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                            <MDBox mb={3} flexGrow={1} maxWidth="calc(33% - 10px)" mr={2}>
                                <MDAutoComplete
                                    options={YesNoOptions}
                                    register={register}
                                    getValues={getValues}
                                    errors={errors}
                                    setValue={setValue}
                                    defaultValue={values?.txtAspirin}
                                    id={"txtAspirin"}
                                    handleDropdownChange={handleDropdownChange}
                                    value={values?.txtAspirin || ''}
                                    error={errors?.txtAspirin || responseListError?.txtAspirin}
                                    label={"Aspirin"}
                                />

                            </MDBox>
                            <MDBox mb={3} flexGrow={1} maxWidth="calc(33% - 10px)" mr={2}>
                                <MDAutoComplete
                                    options={YesNoOptions}
                                    register={register}
                                    getValues={getValues}
                                    errors={errors}
                                    setValue={setValue}
                                    defaultValue={getValues("txtAceInhibitors")}
                                    id={"txtAceInhibitors"}
                                    handleDropdownChange={handleDropdownChange}
                                    value={values?.txtAceInhibitors || ''}
                                    error={errors?.txtAceInhibitors || responseListError?.txtAceInhibitors}
                                    label={"Ace Inhibitors"}

                                />

                            </MDBox>
                            <MDBox mb={3} flexGrow={1} maxWidth="calc(33% - 10px)">
                                <MDAutoComplete
                                    options={YesNoOptions}
                                    register={register}
                                    getValues={getValues}
                                    errors={errors}
                                    setValue={setValue}
                                    defaultValue={values?.txtBetaBlockers}
                                    id={"txtBetaBlockers"}
                                    handleDropdownChange={handleDropdownChange}
                                    value={values?.txtBetaBlockers || ''}
                                    error={errors?.txtBetaBlockers || responseListError?.txtBetaBlockers}
                                    label={"Beta Blockers"}

                                />

                            </MDBox>
                        </MDBox>

                        <MDBox display="flex" flexDirection="row" justifyContent="space-between">

                            <MDBox mb={3} width="calc(50% - 10px)">
                                <MDAutoComplete
                                    options={YesNoOptions}
                                    register={register}
                                    getValues={getValues}
                                    errors={errors}
                                    setValue={setValue}
                                    defaultValue={values?.txtLipidLowering}
                                    id={"txtLipidLowering"}
                                    handleDropdownChange={handleDropdownChange}
                                    value={values?.txtLipidLowering || ''}
                                    error={errors?.txtLipidLowering || responseListError?.txtLipidLowering}
                                    label={"Lipid Lowering"}
                                />

                            </MDBox>
                            <MDBox mb={3} width="calc(50% - 10px)">
                                <MDAutoComplete
                                    options={YesNoOptions}
                                    register={register}
                                    getValues={getValues}
                                    errors={errors}
                                    setValue={setValue}
                                    defaultValue={values?.txtOthAntiPlatelets}
                                    id={"txtOthAntiPlatelets"}
                                    handleDropdownChange={handleDropdownChange}
                                    value={values?.txtOthAntiPlatelets || ''}
                                    error={errors?.txtOthAntiPlatelets || responseListError?.txtOthAntiPlatelets}
                                    label={"Other Anti-Platelets"}
                                />

                            </MDBox>
                        </MDBox>

                        <MDBox display="flex" flexDirection="row" justifyContent="space-between">
                            <MDBox mb={3} width="100%">
                                <FormControl>
                                    <FormLabel id="ima-radio-buttons-group-label" sx={{ fontSize: '14px' }}>
                                        Discharge Location:
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="ima-radio-buttons-group-label"
                                        defaultValue={selectedDischargeLocation}
                                        value={selectedDischargeLocation}

                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            // setValue("txtDischargeLocation", selectedValue);
                                            setSelectedDischargeLocation(selectedValue);

                                            handleDropdownChange("txtDischargeLocation", selectedValue);

                                            // // Update the form values stored in local storage
                                            // setValues((prevValues) => {
                                            //     const updatedValues = { ...prevValues, txtGender: selectedValue };

                                            //     // Save the updated values to localStorage
                                            //     localStorage.setItem("formData", JSON.stringify(updatedValues));

                                            //     // Return the updated values to update state
                                            //     return updatedValues;
                                            // });
                                        }} row
                                    >
                                        {dischargeLocationOptions.map((option) => (
                                            <FormControlLabel
                                                key={option.id}
                                                value={option.value}
                                                control={<Radio />}
                                                label={option.label}
                                            />
                                        ))}
                                    </RadioGroup>

                                </FormControl>
                            </MDBox>
                        </MDBox>

                        {/* Demographics Section */}
                        <Typography
                            className="custom-heading"
                            style={{ marginBottom: "20px", fontWeight: "bold" }}
                        >
                            T. Mortality
                        </Typography>
                        <MDBox display="flex" flexDirection="row" justifyContent="space-between">
                            {/* Patient Medical Record Number */}

                            <MDBox mb={3} width="calc(33% - 10px)">
                                <MDAutoComplete
                                    options={YesNoOptions}
                                    register={register}
                                    getValues={getValues}
                                    errors={errors}
                                    setValue={setValue}
                                    defaultValue={values?.txtMortality}
                                    id={"txtMortality"}
                                    handleDropdownChange={handleDropdownChange}
                                    value={values?.txtMortality || ''}
                                    error={errors?.txtMortality || responseListError?.txtMortality}
                                    label={"Mortality - Mortality"}
                                />
                            </MDBox>

                            {/* Last Name */}

                            <MDBox mb={3} width="calc(33% - 10px)">
                                <MDAutoComplete
                                    options={DischargeStatusOptions}
                                    register={register}
                                    getValues={getValues}
                                    errors={errors}
                                    setValue={setValue}
                                    defaultValue={values?.txtDischargeStatus}
                                    id={"txtDischargeStatus"}
                                    handleDropdownChange={handleDropdownChange}
                                    value={values?.txtDischargeStatus || ''}
                                    error={errors?.txtDischargeStatus || responseListError?.txtDischargeStatus}
                                    label={"Discharge Status"}
                                />

                            </MDBox>

                            <MDBox mb={3} width="calc(33% - 10px)">
                                <MDAutoComplete
                                    options={DischargeStatusOptions}
                                    register={register}
                                    getValues={getValues}
                                    errors={errors}
                                    setValue={setValue}
                                    defaultValue={values?.txtStatusAfterSurgery}
                                    id={"txtStatusAfterSurgery"}
                                    handleDropdownChange={handleDropdownChange}
                                    value={values?.txtStatusAfterSurgery || ''}
                                    error={errors?.txtStatusAfterSurgery || responseListError?.txtStatusAfterSurgery}
                                    label={"Status at 30 days after surgery"}
                                />

                            </MDBox>
                        </MDBox>

                        <MDBox display="flex" flexDirection="row" justifyContent="space-between">

                            <MDBox mb={3} width="calc(33% - 10px)">
                                <MDAutoComplete
                                    options={YesNoOptions}
                                    register={register}
                                    getValues={getValues}
                                    errors={errors}
                                    setValue={setValue}
                                    defaultValue={values?.txtOperativeDeath}
                                    id={"txtOperativeDeath"}
                                    handleDropdownChange={handleDropdownChange}
                                    value={values?.txtOperativeDeath || ''}
                                    error={errors?.txtOperativeDeath || responseListError?.txtOperativeDeath}
                                    label={"Mortality - Operative Death"}
                                />
                            </MDBox>

                            {/* Mortality Date */}
                            <MDBox mb={3} width="60%" className="datepicker-parent">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                        <DatePicker
                                            label="Mortality Date"
                                            inputVariant="outlined"
                                            className="datepicker"
                                            value={mortalityDate}
                                            format="DD/MM/YYYY"
                                            onChange={(e) => {
                                                setMortalityDate(e);
                                                setValue(
                                                    "txtMortalityDate",
                                                    dayjs(e).format("YYYY-MM-DD")
                                                );
                                                handleDropdownChange("txtMortalityDate", e);
                                            }}
                                            renderInput={(params) => (
                                                <MDInput
                                                    fullWidth
                                                    {...params}
                                                    {...register("txtMortalityDate")}
                                                    value={values?.txtMortalityDate || ""}
                                                    helperText={errors.txtMortalityDate || responseListError?.txtMortalityDate}
                                                />
                                            )}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </MDBox>

                        </MDBox>


                        <MDBox display="flex" flexDirection="row" justifyContent="space-between">

                            {/* Location of Death */}

                            <MDBox mb={3} width="50%">
                                <FormControl>
                                    <FormLabel id="ima-radio-buttons-group-label" sx={{ fontSize: '14px' }}>
                                        Location of Death:
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="ima-radio-buttons-group-label"
                                        defaultValue={selectedLocOfDate}
                                        value={selectedLocOfDate}

                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            // setValue("txtLocationOfDeath", selectedValue);
                                            setSelectedLocOfDate(selectedValue);

                                            handleDropdownChange("txtLocationOfDeath", selectedValue);
                                            // // Update the form values stored in local storage
                                            // setValues((prevValues) => {
                                            //     const updatedValues = { ...prevValues, txtLocationOfDeath: selectedValue };

                                            //     // Save the updated values to localStorage
                                            //     localStorage.setItem("formData", JSON.stringify(updatedValues));

                                            //     // Return the updated values to update state
                                            //     return updatedValues;
                                            // });
                                        }}
                                        row
                                    >
                                        {LocationOfDeathOptions.map((option) => (
                                            <FormControlLabel
                                                key={option.id}
                                                value={option.value}
                                                control={<Radio />}
                                                label={option.label}
                                            />
                                        ))}
                                    </RadioGroup>

                                </FormControl>
                            </MDBox>

                        </MDBox>

                        <MDBox display="flex" flexDirection="row" justifyContent="space-between">

                            <MDBox mb={3} width="100%">
                                <FormControl>
                                    <FormLabel id="ima-radio-buttons-group-label" sx={{ fontSize: '14px' }}>
                                        Primary Cause of Death (select only one):
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="ima-radio-buttons-group-label"
                                        defaultValue={selectedCauseOfDeath}
                                        value={selectedCauseOfDeath}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            // setValue("txtCauseOfDeath", selectedValue);
                                            setSelectedCauseOfDeath(selectedValue);

                                            handleDropdownChange("txtCauseOfDeath", selectedValue);
                                            // // Update the form values stored in local storage
                                            // setValues((prevValues) => {
                                            //     const updatedValues = { ...prevValues, txtCauseOfDeath: selectedValue };

                                            //     // Save the updated values to localStorage
                                            //     localStorage.setItem("formData", JSON.stringify(updatedValues));

                                            //     // Return the updated values to update state
                                            //     return updatedValues;
                                            // });
                                        }}
                                        row
                                    >
                                        {PrimaryCauseOfDeathOptions.map((option) => (
                                            <FormControlLabel
                                                key={option.id}
                                                value={option.value}
                                                control={<Radio />}
                                                label={option.label}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </MDBox>
                        </MDBox>

                        <Typography
                            className="custom-heading"
                            style={{ marginBottom: "20px", fontWeight: "bold" }}
                        >
                            U. Readmission
                        </Typography>

                        <MDBox display="flex" flexDirection="row" justifyContent="space-between">

                            <MDBox mb={3} width="calc(50% - 5px)">
                                <MDAutoComplete
                                    options={YesNoOptions}
                                    register={register}
                                    getValues={getValues}
                                    errors={errors}
                                    setValue={setValue}
                                    setData={setReadmit}
                                    defaultValue={readmit}
                                    id={"txtReadmit"}
                                    handleDropdownChange={handleDropdownChange}
                                    value={readmit}
                                    error={
                                        errors?.txtReadmit ||
                                        responseListError?.txtReadmit
                                    }
                                    label={"Readmit <=30 Days from Date of Procedure"}
                                />
                                {/* <Autocomplete
                                    id="tags-outlined"
                                    className="drop-down"
                                    options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                    groupBy={(option) => option.firstLetter}
                                    getOptionLabel={(option) => option.label}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    defaultValue={YesNoOptions.find((i) => i.id === readmit)}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={"Readmit <=30 Days from Date of Procedure"}
                                            placeholder={"Readmit <=30 Days from Date of Procedure"}
                                            error={isError ? true : false}
                                            value={values?.txtReadmit || ""}
                                        />
                                    )}
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            setReadmit(newValue.value);
                                            handleDropdownChange("txtReadmit", newValue.value);
                                        }
                                    }}
                                /> */}

                            </MDBox>

                            {readmit === "yes" && (
                                <>
                                    <MDBox mb={3} width="calc(50% - 5px)">
                                        <MDAutoComplete
                                            options={ReadmissionReasonOptions}
                                            register={register}
                                            getValues={getValues}
                                            errors={errors}
                                            setValue={setValue}
                                            defaultValue={values?.txtReadmissionReason}
                                            id={"txtReadmissionReason"}
                                            handleDropdownChange={handleDropdownChange}
                                            value={values?.txtReadmissionReason || ''}
                                            error={errors?.txtReadmissionReason || responseListError?.txtReadmissionReason}
                                            label={"Readmission Reason"}
                                        />
                                    </MDBox>
                                </>
                            )}
                        </MDBox>

                        <Typography className="invalid-feedback">
                            {responseError}
                        </Typography>
                        <MDBox display="flex" flexDirection="column" mb={3}>
                            {!isBtnLoading ? (
                                <MDBox mt={4} display="flex" justifyContent="end">
                                    <MDButton variant="gradient" color="info" type="submit">
                                        submit
                                    </MDButton>
                                </MDBox>
                            ) : (
                                <MDBox mt={4} display="flex" justifyContent="end">
                                    <Box sx={{ display: "flex" }}>
                                        <CircularProgress />
                                    </Box>
                                </MDBox>
                            )}
                        </MDBox>
                    </form>
                    {/* {isBtnLoading && <p>Data Updated Successfully...</p>} */}

                </Card>
            </Box>
            <Footer responseRef={responseRef} />

        </DashboardLayout>

    )
}

export default DischargeSummaryForm;