// Authentication layout components
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";
import "./notfound.css";
// Images
import PageLayout from "examples/LayoutContainers/PageLayout";
function NotFound() {
  return (
    <PageLayout>
      <div id="notfound">
        <div class="notfound">
          <div class="notfound-404">
            <h1>404</h1>
          </div>
          <h2>We are sorry, Page not found!</h2>
          <p>
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
          </p>
          <p></p>
          <a href="/">Back To Login</a>
        </div>
      </div>
    </PageLayout>
  );
}

export default NotFound;
