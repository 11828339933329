import { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";

// Icons
import EditIcon from "@mui/icons-material/Edit";

// Yup Validation imports
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import validationParams from "validationParams";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MDTabs from "components/MDTabs/MDTabs";
import { MDAutoComplete } from "components/MDAutoComplete";
import { Button, Card, CircularProgress, Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// API Import
import API from "APIUrls";
import APIService from "services/api-service";

//Date Table
import { MaterialReactTable } from "material-react-table";
import MDTypography from "components/MDTypography";

// Datepicker import
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function AddNew(props) {
    const [date, setDate] = useState(dayjs());

    return (
        <Box sx={{ padding: "10px" }}>
            <form
                onSubmit={props.handleSubmit(props.onSubmit)}
                encType="multipart/form-data"
            >
                <Typography
                    class="custom-heading"
                    style={{ marginBottom: "40px", fontWeight: "bold" }}
                >
                    Patient's Basic Information
                </Typography>
                <MDBox display="flex" flexDirection="row">
                    <MDBox
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        width="100%"
                        mr={2}
                    >
                        <MDBox mb={2} width="100%">
                            <MDInput
                                type="name"
                                fullWidth
                                name="txtFirstName"
                                label="First Name"
                                register={props.register}
                                error={
                                    props.errors.txtFirstName ||
                                    props.responseListError?.txtFirstName
                                }
                                errors={
                                    props.errors.txtFirstName ||
                                    props.responseListError?.txtFirstName
                                }
                            />
                        </MDBox>
                        <MDBox mb={2} width="100%">
                            <MDInput
                                type="name"
                                fullWidth
                                name="txtContactNumber"
                                label="Contact Number"
                                register={props.register}
                                error={
                                    props.errors.txtContactNumber ||
                                    props.responseListError?.txtContactNumber
                                }
                                errors={
                                    props.errors.txtContactNumber ||
                                    props.responseListError?.txtContactNumber
                                }
                            />
                        </MDBox>
                        <MDBox mb={2} width="100%" className="datepicker-parent">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        label="Date of Birth"
                                        inputVariant="outlined"
                                        className="datepicker"
                                        value={date}
                                        defaultValue={props.getValues("txtDOB")}
                                        format="DD/MM/YYYY"
                                        onChange={(e) => {
                                            setDate(e);
                                            props.setValue(
                                                "txtDOB",
                                                dayjs(e).format("YYYY-MM-DD")
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <MDInput
                                                fullWidth
                                                {...params}
                                                {...props.register("txtDOB")}
                                                error={
                                                    props.errors.txtDOB ||
                                                    props.responseListError?.txtDOB
                                                }
                                                errors={
                                                    props.errors.txtDOB ||
                                                    props.responseListError?.txtDOB
                                                }
                                            />
                                        )}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </MDBox>
                        <MDBox mb={2} width="100%">
                            <MDInput
                                type="name"
                                fullWidth
                                name="txtAge"
                                label="Age"
                                register={props.register}
                                error={
                                    props.errors.txtAge ||
                                    props.responseListError?.txtAge
                                }
                                errors={
                                    props.errors.txtAge ||
                                    props.responseListError?.txtAge
                                }
                            />
                        </MDBox>
                        <MDBox mb={2} width="100%">
                            <MDInput
                                type="name"
                                fullWidth
                                name="txtAddress"
                                label="Address"
                                register={props.register}
                                error={
                                    props.errors.txtAddress ||
                                    props.responseListError?.txtAddress
                                }
                                errors={
                                    props.errors.txtAddress ||
                                    props.responseListError?.txtAddress
                                }
                            />
                        </MDBox>
                    </MDBox>
                    <MDBox
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        width="100%"
                        mr={2}
                    >

                        <MDBox mb={2} width="100%">
                            <MDInput
                                type="name"
                                fullWidth
                                name="txtLastName"
                                label="Last Name"
                                register={props.register}
                                error={
                                    props.errors.txtLastName ||
                                    props.responseListError?.txtLastName
                                }
                                errors={
                                    props.errors.txtLastName ||
                                    props.responseListError?.txtLastName
                                }
                            />
                        </MDBox>

                        <MDBox mb={2} width="100%">
                            <MDInput
                                type="name"
                                fullWidth
                                name="txtEmail"
                                label="Email"
                                register={props.register}
                                error={
                                    props.errors.txtEmail || props.responseListError?.txtEmail
                                }
                                errors={
                                    props.errors.txtEmail || props.responseListError?.txtEmail
                                }
                            />
                        </MDBox>
                        <MDBox mb={2} width="100%">
                            <MDInput
                                type="name"
                                fullWidth
                                name="txtBloodGroup"
                                label="Blood Group"
                                register={props.register}
                                error={
                                    props.errors.txtBloodGroup ||
                                    props.responseListError?.txtBloodGroup
                                }
                                errors={
                                    props.errors.txtBloodGroup ||
                                    props.responseListError?.txtBloodGroup
                                }
                            />
                        </MDBox>
                        <MDBox mb={2} width="100%">
                            <MDAutoComplete
                                options={props.genderOptions}
                                register={props.register}
                                getValues={props.getValues}
                                errors={props.errors}
                                setValue={props.setValue}
                                defaultValue={props.getValues("txtGender")}
                                id={"txtGender"}
                                isErrorMessage={
                                    props.errors.txtGender?.message ||
                                    props.responseListError?.txtGender?.message
                                }
                                isError={
                                    props.errors.txtGender ||
                                        props.responseListError?.txtGender
                                        ? true
                                        : false
                                }
                                label={"Gender"}
                            />
                        </MDBox>
                        <MDBox mb={2} width="100%">
                            <MDAutoComplete
                                options={props.stateOptions}
                                register={props.register}
                                getValues={props.getValues}
                                errors={props.errors}
                                setValue={props.setValue}
                                defaultValue={props.getValues("txtState")}
                                id={"txtState"}
                                isErrorMessage={
                                    props.errors.txtState?.message ||
                                    props.responseListError?.txtState?.message
                                }
                                isError={
                                    props.errors.txtState ||
                                        props.responseListError?.txtState
                                        ? true
                                        : false
                                }
                                label={"State"}
                            />
                        </MDBox>
                        <MDBox mb={2} width="100%">
                            <MDAutoComplete
                                options={props.districtOptions}
                                register={props.register}
                                getValues={props.getValues}
                                errors={props.errors}
                                setValue={props.setValue}
                                defaultValue={props.getValues("txtDistrict")}
                                id={"txtDistrict"}
                                isErrorMessage={
                                    props.errors.txtDistrict?.message ||
                                    props.responseListError?.txtDistrict?.message
                                }
                                isError={
                                    props.errors.txtDistrict ||
                                        props.responseListError?.txtDistrict
                                        ? true
                                        : false
                                }
                                label={"District"}
                            />
                        </MDBox>
                    </MDBox>
                </MDBox>
                <Typography className="invalid-feedback">
                    {props.responseError}
                </Typography>
                <MDBox display="flex" flexDirection="column" mb={3}>
                    {!props.isBtnLoading ? (
                        <MDBox mt={4} display="flex" justifyContent="end">
                            <MDButton variant="gradient" color="info" type="submit">
                                submit
                            </MDButton>
                        </MDBox>
                    ) : (
                        <MDBox mt={4} display="flex" justifyContent="end">
                            <Box sx={{ display: "flex" }}>
                                <CircularProgress />
                            </Box>
                        </MDBox>
                    )}
                </MDBox>
            </form>
        </Box>
    );
}

const Patient = () => {

    const [selectedPatient, setSelectedPatient] = useState(null);

    const [ddGender, setDDGender] = useState([]);
    const genderOptions = ddGender;

    const [ddStates, setDDStates] = useState([]);
    const stateOptions = ddStates;

    const [ddDistricts, setDistricts] = useState([]);
    const districtOptions = ddDistricts;

    const [values, setValues] = useState(0);

    const [responseError, setResponseError] = useState(null);
    const [responseListError, setResponseListError] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const responseRef = useRef({
        responseFlag: false,
        responseCode: 200,
        responseMessage: "",
    });

    const [tblList, setTblList] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    let defaultValues = {
        txtFirstName: "",
        txtLastName: "",
        txtContactNumber: "",
        txtEmail: "",
        txtDOB: "",
        txtBloodGroup: "",
        txtAge: "",
        txtGender: "",
        txtState: "",
        txtDistrict: "",
        txtAddress: "",
    };

    const fetchData = async () => {
        setIsLoading(true);
        setIsError(false);
        try {
            const payload = {
                intSPType: 2,
                columnFilters: JSON.stringify(columnFilters ?? []),
                sorting: JSON.stringify(sorting ?? []),
                intLimit: pagination.pageSize,
                intOffset: pagination.pageIndex * pagination.pageSize,
            };

            const tempDDBranch = await APIService.post(API.Patient, payload);

            if (tempDDBranch?.code === 200 || tempDDBranch?.code === "200") {
                setTblList(tempDDBranch.data ?? []);
                setRowCount(tempDDBranch.count ?? 0);

                console.log(tempDDBranch.data);
            }
            setIsLoading(false);
        } catch (error) {
            setIsError(true);
            setIsLoading(false);
            console.error(error);
            return;
        }
        setIsRefetching(false);
    };

    useEffect(() => {
        fetchData();
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ]);

    const navigate = useNavigate();

    const handleEdit = (row, formType) => {
        let url;
        switch (formType) {
            case "personal":
                url = `/patients/personalData/${row.id}`;
                break;
            case "preoperative":
                url = `/patients/preoperativeData/${row.id}`;
                break;
            case "postoperative":
                url = `/patients/postoperativeData/${row.id}`;
                break;
            case "discharge":
                url = `/patients/dischargeSummary/${row.id}`;
                break;
            default:
                break;
        }
        navigate(url);
    };

    const columns = useMemo(() => [
        // {
        //     accessor: "txtFirstName", // Change accessorKey to accessor
        //     header: "First Name", // Change header to Header
        //     Cell: ({ row }) => (
        //       <a
        //         href={`/patients/personalData/${row.original.id}`}
        //         className="color--purple btn"
        //       >
        //         {row.original.first_name}
        //       </a>
        //     ),
        //   },
        {
            accessorKey: "txtFirstName",
            header: "First Name",
            Cell: ({ cell, row }) => row.original.first_name,
        },
        {
            accessorKey: "txtLastName",
            header: "Last Name",
            Cell: ({ cell, row }) => row.original.last_name,
        },
        {
            accessorKey: "txtContactNumber",
            header: "Contact Number",
            Cell: ({ cell, row }) => row.original.contact_number,
        },
        {
            accessorKey: "txtEmail",
            header: "Email",
            Cell: ({ cell, row }) => row.original.email_id,
        },
        {
            accessorKey: "txtDOB",
            header: "Date of Birth",
            Cell: ({ cell, row }) => moment(row.original.date_of_birth).format("DD/MM/YYYY"),
        },
        {
            accessorKey: "txtBloodGroup",
            header: "Blood Group",
            Cell: ({ cell, row }) => row.original.blood_group,
        },
        {
            accessorKey: "txtAge",
            header: "Age",
            Cell: ({ cell, row }) => row.original.age,
        },
        {
            accessorKey: "txtGender",
            header: "Gender",
            Cell: ({ cell, row }) => row.original.gender_name,
        },
        {
            accessorKey: "txtAddress",
            header: "Address",
            Cell: ({ cell, row }) => row.original.address,
        },
        {
            accessorKey: "txtState",
            header: "State",
            Cell: ({ cell, row }) => row.original.state_name,
        }, 
        {
            accessorKey: "txtDistrict",
            header: "District",
            Cell: ({ cell, row }) => row.original.district_name,
        },
        {
            header: "Created At",
            Cell: ({ row }) => (
                <MDTypography variant={"p"}>
                    {moment(row.original.created_at).format("DD/MM/YYYY")}
                    <br />
                    {moment(row.original.created_at).format("hh:mm A")}
                </MDTypography>
            ),
            muiTableHeadCellProps: {
                align: "center",
            },
            muiTableBodyCellProps: {
                align: "center",
            },
        },
        // Edit button column for Personal Data Form
        {
            header: "Personal Data Form",
            Cell: ({ row }) => (
                <EditIcon
                    className={`color--purple btn`}
                    onClick={() => {
                        handleEdit(row.original, "personal");
                    }}
                />
            ),
        },
        // Edit button column for Pre-operative Data Form
        {
            header: "Pre-operative Data Form",
            Cell: ({ row }) => (
                <EditIcon
                    className={`color--purple btn`}
                    onClick={() => {
                        handleEdit(row.original, "preoperative");
                    }}
                />
            ),
        },
        // Edit button column for Post Operative Data Form
        {
            header: "Post Operative Data Form",
            Cell: ({ row }) => (
                <EditIcon
                    className={`color--purple btn`}
                    onClick={() => {
                        handleEdit(row.original, "postoperative");
                    }}
                />
            ),
        },
        // Edit button column for Discharge Summary Form
        {
            header: "Discharge Summary Form",
            Cell: ({ row }) => (
                <EditIcon
                    className={`color--purple btn`}
                    onClick={() => {
                        handleEdit(row.original, "discharge");
                    }}
                />
            ),
        },
    ]);

    useEffect(function () {
        const fetchDDData = async () => {

            //Gender dropdown
            let payload = {
                intSPType: 2,
                intID: 4,
            };
            const tempGender = await APIService.post(API.Status, payload);
            setDDGender(tempGender.data);


            payload = {
                intSPType: 2,
                //intID: 4,
            };
            const tempState = await APIService.post(API.States, payload);
            setDDStates(tempState.data);

            payload = {
                intSPType: 2,
                //intID: 4,
            };
            const tempDistrict = await APIService.post(API.Districts, payload);
            setDistricts(tempDistrict.data);
        };

        fetchDDData();
    }, []);

    // const handleEdit = (id) => {
    //     //setSelectedPatient(data);
    //     setIsEdit(true);
    //     navigate(`/patients/patientAllDetails/${id}`);

    // };

    const handleChange = (event, newValue) => {
        setIsEdit(false);
        setValues(newValue);
        defaultValues = {
            //txtBranchID: "",
            txtFirstName: "",
            //txtStatus: "2",
        };
        reset({ ...defaultValues });
    };

    const validationSchema = Yup.object().shape({
        txtFirstName: validationParams.text,
        txtLastName: validationParams.text,
        txtContactNumber: validationParams.text,
        txtEmail: validationParams.text,
        //txtDOB: validationParams.text,
        txtBloodGroup: validationParams.text,
        txtAge: validationParams.text,
        txtGender: validationParams.dropdown,
        txtState: validationParams.dropdown,
        txtDistrict: validationParams.dropdown,
        txtAddress: validationParams.text,
    });

    const {
        handleSubmit,
        register,
        formState: { errors },
        getValues,
        reset,
        setValue,
    } = useForm({ resolver: yupResolver(validationSchema) });

    const onSubmit = async (data) => {
        try {
            console.log("Submitting data:", data);
            setIsBtnLoading(true);
            data.intSPType = 1;
            //data.txtCreatedUser = await JSON.parse(localStorage.getItem("user")).uuid;;
            const tempResult = await APIService.post(API.Patient, data);
            responseRef.current.responseFlag = true;
            responseRef.current.responseCode = tempResult.code;
            responseRef.current.responseMessage = tempResult.message;
            console.log(responseRef);

            if (tempResult.code === 200 || tempResult.code === "200") {
                setTimeout(() => {
                    // window.location.reload();
                    setValues(0);
                    setIsEdit(0);
                    fetchData();
                    setIsBtnLoading(false);
                    setIsLoading(false);
                }, 3000);
            }
            if (tempResult.code === 201 || tempResult.code === "201") {
                setIsBtnLoading(false);
            }
        } catch (error) {
            console.error("Error:", error);
            setIsBtnLoading(false);
            if (error?.code === 5001) setResponseError(error?.message);
            if (error?.code === 5002) setResponseListError(error?.errors);
        }
    };

    useEffect(() => {
        if (selectedPatient) {
            reset({
                txtFirstName: selectedPatient.first_name,
                txtLastName: selectedPatient.last_name,
            });
        }
    }, [selectedPatient]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {/* <MDBox mb={2} /> */}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox pt={3}>
                                <MDTabs value={values} handleChange={handleChange} />
                                <CustomTabPanel value={values} index={0}>
                                    {isEdit ? (
                                        <AddNew
                                            handleSubmit={handleSubmit}
                                            register={register}
                                            errors={errors}
                                            onSubmit={onSubmit}
                                            getValues={getValues}
                                            setValue={setValue}
                                            isEdit={isEdit}
                                            isBtnLoading={isBtnLoading}
                                            setIsEdit={setIsEdit}
                                            selectedPatient={selectedPatient}
                                            genderOptions={genderOptions}
                                            stateOptions={stateOptions}
                                            districtOptions={districtOptions}
                                            responseError={responseError}
                                            responseListError={responseListError}
                                        />
                                    ) : (
                                        <Grid item xs={12}>
                                            <MaterialReactTable
                                                columns={columns}
                                                data={tblList}
                                                onRowClick={(row) => handleEdit(row.original)}
                                                // enableRowSelection
                                                getRowId={(row) => row.name}
                                                // enableColumnFilterModes
                                                initialState={{ showColumnFilters: false }}
                                                enableRowNumbers
                                                enableGlobalFilter={false}
                                                rowNumberMode="original"
                                                manualFiltering
                                                manualPagination
                                                manualSorting
                                                muiToolbarAlertBannerProps={
                                                    isError
                                                        ? {
                                                            color: "error",
                                                            children: "Error loading data",
                                                        }
                                                        : undefined
                                                }
                                                // onRowClick={(row) => handleEdit(row.original)}
                                                onColumnFiltersChange={setColumnFilters}
                                                onGlobalFilterChange={setGlobalFilter}
                                                onPaginationChange={setPagination}
                                                onSortingChange={setSorting}
                                                rowCount={rowCount}
                                                state={{
                                                    columnFilters,
                                                    globalFilter,
                                                    isLoading,
                                                    pagination,
                                                    showAlertBanner: isError,
                                                    showProgressBars: isRefetching,
                                                    sorting,
                                                }}
                                                renderTopToolbarCustomActions={({ table }) => (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            gap: "1rem",
                                                            p: "0.5rem",
                                                            flexWrap: "wrap",
                                                        }}
                                                    >
                                                    </Box>
                                                )}
                                            />
                                        </Grid>
                                    )}
                                </CustomTabPanel>
                                <CustomTabPanel value={values} index={1}>
                                    <AddNew
                                        handleSubmit={handleSubmit}
                                        register={register}
                                        errors={errors}
                                        onSubmit={onSubmit}
                                        getValues={getValues}
                                        setValue={setValue}
                                        isEdit={isEdit}
                                        isBtnLoading={isBtnLoading}
                                        setIsEdit={setIsEdit}
                                        selectedPatient={selectedPatient}
                                        genderOptions={genderOptions}
                                        stateOptions={stateOptions}
                                        districtOptions={districtOptions}
                                        responseError={responseError}
                                        responseListError={responseListError}
                                    />
                                </CustomTabPanel>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            <Footer responseRef={responseRef} />
        </DashboardLayout>
    );
};

export default Patient;
