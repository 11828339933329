// const BaseURL = "https://backend.besmart.imaginetventures.me/api/";
const BaseURL = "https://backend.cabg.imaginetventures.me/api/"
// const BaseURL = "http://localhost:3000/api/";
const API = {
  login: BaseURL + "authentication/login",
  Patient: BaseURL + "patients/patients",
  PersonalDataForm : BaseURL + "patients/personalData",
  PreoperativeDataForm : BaseURL + "patients/preoperativeData",
  PostoperativeDataForm : BaseURL + "patients/postoperativeData",
  DischargeSummaryForm : BaseURL + "patients/dischargeSummary",

  Status: BaseURL + "status",
  States: BaseURL + "states",
  Districts: BaseURL + "districts",

  Me: BaseURL + "me",
};
export default API;

 