import Dashboard from "layouts/dashboard";

// @mui icons
import Icon from "@mui/material/Icon";

import Patient from "patients/patient_info";
//import PatientAllDetails from "patients/patientAllDetails";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/home",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Patients",
    key: "patients",  // Unique key for "Patients"
    icon: <Icon fontSize="small">peopleAlt</Icon>,
    route: "/patients/patients",
    component: <Patient />,
  },
  // {
  //   type: "collapse",
  //   name: "Patient All Details",
  //   key: "patientDetails",  // Unique key for "Patient All Details"
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/patients/patientAllDetails",
  //   component: <PatientAllDetails />,
  //   hidden: true
  // },
];

export default routes;


// {
//   accessorKey: "txtFirstName",
//   header: "First Name",
//   Cell: ({ cell, row }) => (
//       <a
//           href={`/patients/patientAllDetails/${row.original.uuid}`}
//           className="color--purple btn"
//           onClick={(e) => {
//               e.preventDefault(); // Prevent default link behavior
//               handleEdit(row.original);
//               navigate(`/patients/patientAllDetails/${row.original.uuid}`);
//           }}
//       >
//           {row.original.first_name}
//       </a>
//   ),
// },
