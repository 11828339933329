import { Autocomplete, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";

export function MDAudoMui(props) {
  const [inputValue, setInputValue] = useState("");

  // Adjusted to use `find` for simplicity and correctness
  useEffect(() => {
    const defaultOption = props.options.find(option => option.id === props.defaultValue);
    setInputValue(defaultOption ? defaultOption.label : "");
  }, [props.defaultValue, props.options]);

  return (
    <>
      <Autocomplete
        id={props.id}
        className="drop-down"
        options={props.options.sort((a, b) => -b.firstLetter?.localeCompare(a.firstLetter))}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        defaultValue={props.options.find(option => option.id === props.defaultValue)} // Adjust this line
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            placeholder={props.label}
            error={Boolean(props.isError)}
            //value={props.value}
          />
        )}
        onChange={(event, newValue) => {
          // Correctly handle the setting of new values
          const newValueId = newValue ? newValue.id : "";
          props.setValue && props.setValue(props.id, newValueId);
          props.setData && props.setData(newValueId);
          props.handleDropdownChange && props.handleDropdownChange(props.id, newValueId);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
      />
      {props.isErrorMessage && (
        <MDTypography variant="caption" color="error" fontWeight="light">
          {props.isErrorMessage}
        </MDTypography>
      )}
    </>
  );
}

export const MDAutoComplete = React.memo(MDAudoMui);
