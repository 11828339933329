import HttpService from "./htttp.service";

class APIService {
  // authEndpoint = process.env.API_URL;

  post = async (url, payload) => {
    return await HttpService.post(url, payload);
  };

  
}

export default new APIService();
