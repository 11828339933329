import * as Yup from "yup";

const validationParams = {
  text: Yup.string()
    .required("This field is required")
    .min(2, "Atleat 2 characters is required")
    .max(255, "Value must be less than 255 characters"),
  dropdown: Yup.string().required("This field is required"),
  phone: Yup.string()
    .matches(
      /^[0-9]{10}$/,
      "Phone number must be 10 digits long and contain only numbers"
    )
    .required("Phone number is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=]).*$/,
      "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  number: Yup.number()
    .required("Number is required")
    .typeError("Please enter a valid number"),

  time: Yup.mixed().test(
      "is-valid-time",
      "Time is required",
      function (value) {
        // Check if the value is a valid Date object
        if (value instanceof Date && !isNaN(value)) {
          return true;
        }
        return false;
      }
    ),
  datetime: Yup.mixed().test(
      "is-valid-date-time",
      "This field is required",
      function (value) {
        // Check if the value is a valid Date object
        if (value instanceof Date && !isNaN(value)) {
          return true;
        }
        return false;
      }
    ),
};

export default validationParams;
