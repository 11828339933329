import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Material Dashboard 2 React components
import Popup from "PopUp/popup";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MDTabs from "components/MDTabs/MDTabs";
import { TextField, Autocomplete } from "@mui/material";
import { MDAutoComplete } from "components/MDAutoComplete";
import { Button, Card, CircularProgress, Grid } from "@mui/material";

// Yup Validation imports
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import validationParams from "validationParams";

//Radio group
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// API Import
import API from "APIUrls";
import APIService from "services/api-service";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function CustomAccordionPanel(props) {
  const { children, summary, expanded, onChange } = props;

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

CustomAccordionPanel.propTypes = {
  children: PropTypes.node,
  summary: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};


const PreOperativeForm = () => {

  const { patientId } = useParams();

  const [smoker, setSmoker] = useState("");
  const [diabetes, setDiabetes] = useState("");
  const [renalFailure, setRenalFailure] = useState("");
  const [cerebrovascularAccident, setCerebrovascularAccident] = useState("");
  const [infectiousEndocarditis, setInfectiousEndocarditis] = useState("");
  const [cerebrovascularDisease, setCerebrovascularDisease] = useState("");
  const [cvInterventions, setCVInterventions] = useState("");
  const [priorPTCA, setPriorPTCA] = useState("");
  const [previousStentPlacement, setPreviousStentPlacement] = useState("");
  const [thrombolysis, setThrombolysis] = useState("");
  const [myocardialInfarction, setMyocardialInfarction] = useState("");
  const [angina, setAngina] = useState("");
  const [anginaType, setAnginaType] = useState("");
  const [cardiogenicShock, setCardiogenicShock] = useState("");
  const [arrhythmia, setArrhythmia] = useState("");

  const [ejectionType, setEjectionType] = useState("");
  const [pulmonary, setPulmonary] = useState("");
  const [aorticStenosis, setAorticStenosis] = useState("");
  const [selectedDiabetesControl, setSelectedDiabetesControl] = useState("none");
  const [selectedWhenOptions, setSelectedWhenOptions] = useState("recent");
  const [selectedInfectiousEndoType, setSelectedInfectiousEndoType] = useState("treated");
  const [selectedChronicLungDisease, setSelectedChronicLungDisease] = useState("no");
  const [selectedCVDType, setSelectedCVDType] = useState("coma");
  const [selectedPriorPTCAInterval, setSelectedPriorPCTAInterval] = useState("<=6");
  const [selectedPreStentPlaceInterval, setSelectedPreStentPlaceInterval] = useState("<=6");
  const [selectedThrombolysisInterval, setSelectedThrombolysisInterval] = useState("<=6");
  const [selectedWhennOptions, setSelectedWhennOptions] = useState("");
  const [selectedShockType, setSelectedShockType] = useState("RefractoryShock");
  const [selectedArrhythmiaType, setSelectedArrhythmiaType] = useState("SustVT_VF");
  const [selectedCcsClassification, setSelectedCssClassification] = useState("0");
  const [selectedNyhaClassification, setSelectedNyhaClassification] = useState("I");
  const [selectedAorticInsufficiency, setSelectedAorticInsufficiency] = useState("0");
  const [selectedMitralInsufficiency, setSelectedMitralInsufficiency] = useState("0");
  const [selectedTricuspidInsufficiency, setSelectedTricuspidInsufficiency] = useState("0");
  const [selectedPulmonicInsufficiency, setSelectedPulmonicInsufficiency] = useState("0");
  const [setCurrentSmoker, setSelectedCurrentSMoker] = useState("");

  const [values, setValues] = useState(defaultValues);

  const [responseError, setResponseError] = useState(null);
  const [responseListError, setResponseListError] = useState(null);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const [formData, setFormData] = useState({});

  const responseRef = useRef({
    responseFlag: false,
    responseCode: 200,
    responseMessage: "",
  });


  const validationSchema = Yup.object().shape({

  });

  const YesNoOptions = [
    { id: 'yes', value: 'yes', label: 'Yes', firstLetter: 'Y' },
    { id: 'no', value: 'no', label: 'No', firstLetter: 'N' },
  ];

  const diabetesControlOptions = [
    { id: 'none', value: 'none', label: 'None', firstLetter: 'N' },
    { id: 'diet ', value: 'diet ', label: 'Diet ', firstLetter: 'D' },
    { id: 'oral', value: 'oral', label: 'Oral', firstLetter: 'O' },
    { id: 'insulin', value: 'insulin', label: 'Insulin', firstLetter: 'I' },
  ];

  const whenOptions = [
    { id: 'recent', value: 'recent', label: 'Recent <= 2 weeks', firstLetter: 'R' },
    { id: 'remote', value: 'remote', label: 'Remote > 2 weeks', firstLetter: 'R' },
  ];

  const EndocarditisTypeOptions = [
    { id: 'treated', value: 'treated', label: 'Treated', firstLetter: 'T' },
    { id: 'active', value: 'active', label: 'Active', firstLetter: 'A' },
  ];

  const chronicLungDiseaseOptions = [
    { id: 'no', value: 'no', label: 'No', firstLetter: 'N' },
    { id: 'mild', value: 'mild', label: 'Mild', firstLetter: 'M' },
    { id: 'moderate', value: 'moderate', label: 'Moderate', firstLetter: 'M' },
    { id: 'severe', value: 'severe', label: 'Severe', firstLetter: 'S' },
  ];

  const cvdTypeOptions = [
    { id: 'coma', value: 'coma', label: 'Coma', firstLetter: 'C' },
    { id: 'cva', value: 'cva', label: 'CVA', firstLetter: 'C' },
    { id: 'rind', value: 'rind', label: 'RIND', firstLetter: 'R' },
    { id: 'tia', value: 'tia', label: 'TIA', firstLetter: 'T' },
    { id: 'nonInvasive', value: 'nonInvasive', label: 'Non Invasive > 75%', firstLetter: 'N' },
    { id: 'previousSurgery', value: 'previousSurgery', label: 'Previous Carotid Surgery', firstLetter: 'P' },
  ];

  const StentPlaceIntervalOptions = [
    { id: '<=6', value: '<= 6 hours', label: '<= 6 hours', firstLetter: '<' },
    { id: '>6', value: '> 6 hours', label: '> 6 hours', firstLetter: '>' }
  ];

  const ThrombolysisIntervalOptions = [
    { id: '<=6', value: '<= 6 hours', label: '<= 6 hours', firstLetter: '<' },
    { id: '>6', value: '> 6 hours', label: '> 6 hours', firstLetter: '>' }
  ];

  const PriorPCTAIntervalOptions = [
    { id: '<=6', value: '<= 6 hours', label: '<= 6 hours', firstLetter: '<' },
    { id: '>6', value: '> 6 hours', label: '> 6 hours', firstLetter: '>' }
  ];

  const WhenOptions = [
    { id: '<=6', value: '<= 6 hours', label: '<= 6 hours', firstLetter: '<' },
    { id: '>6but<24', value: '> 6 hours but < 24 hours', label: '> 6 hours but < 24 hours', firstLetter: '>' },
    { id: '1-7days', value: '1 - 7 days', label: '1 - 7 days', firstLetter: '1' },
    { id: '8-21days', value: '8 - 21 days', label: '8 - 21 days', firstLetter: '8' },
    { id: '>21days', value: '> 21 days', label: '> 21 days', firstLetter: '>' },
  ];

  const AginaTypeOptions = [
    { id: 'Stable', value: 'Stable', label: 'Stable', firstLetter: 'S' },
    { id: 'Unstable', value: 'Unstable', label: 'Unstable', firstLetter: 'U' },
  ];

  const UnstableTypeOptions = [
    { id: 'RestAngina', value: 'Rest Angina', label: 'Rest Angina', firstLetter: 'R' },
    { id: 'NewClass3', value: 'New Class 3', label: 'New Class 3', firstLetter: 'N' },
    { id: 'RecentAccel', value: 'Recent Accel', label: 'Recent Accel', firstLetter: 'R' },
    { id: 'VariantAngina', value: 'Variant Angina', label: 'Variant Angina', firstLetter: 'V' },
    { id: 'NonQMI', value: 'Non-Q MI', label: 'Non-Q MI', firstLetter: 'N' },
    { id: 'PostInfarctAngina', value: 'Post-Infarct Angina', label: 'Post-Infarct Angina', firstLetter: 'P' },
  ];

  const ShockTypeOptions = [
    { id: 'RefractoryShock', value: 'Refractory Shock', label: 'Refractory Shock', firstLetter: 'R' },
    { id: 'HemodynamicInstability', value: 'Hemodynamic Instability', label: 'Hemodynamic Instability', firstLetter: 'H' },
  ];

  const ArrhythmiaTypeOptions = [
    { id: 'SustVT_VF', value: 'Sust VT/VF', label: 'Sust VT/VF', firstLetter: 'S' },
    { id: 'HeartBlock', value: 'Heart Block', label: 'Heart Block', firstLetter: 'H' },
    { id: 'AFib_Flutter', value: 'AFib/Flutter', label: 'AFib/Flutter', firstLetter: 'A' },
  ];

  const DiseasedVesselsOptions = [
    { id: 'None', value: 'None', label: 'None', firstLetter: 'N' },
    { id: 'One', value: 'One', label: 'One', firstLetter: 'O' },
    { id: 'Two', value: 'Two', label: 'Two', firstLetter: 'T' },
    { id: 'Three', value: 'Three', label: 'Three', firstLetter: 'T' },
  ];

  const EjectionFractionMethodOptions = [
    { id: 'LVgram', value: 'LV gram', label: 'LV gram', firstLetter: 'L' },
    { id: 'Radionucleotide', value: 'Radionucleotide', label: 'Radionucleotide', firstLetter: 'R' },
    { id: 'Estimate', value: 'Estimate', label: 'Estimate', firstLetter: 'E' },
    { id: 'ECHO', value: 'ECHO', label: 'ECHO', firstLetter: 'E' },
  ];

  const DiseasedCoronaryVesselsOptions = [
    { id: 'none', value: 'None', label: 'None', firstLetter: 'N' },
    { id: 'one', value: 'One', label: 'One', firstLetter: 'O' },
    { id: 'two', value: 'Two', label: 'Two', firstLetter: 'T' },
    { id: 'three', value: 'Three', label: 'Three', firstLetter: 'T' },
  ];

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm({ resolver: yupResolver(validationSchema) });

  // Load form data from localStorage when the component mounts
  useEffect(() => {
    const savedFormData = localStorage.getItem("formData");
    if (savedFormData) {
      // console.log(savedFormData);
      const parsedFormData = JSON.parse(savedFormData);
      // console.log(parsedFormData, "data");
      setValues(parsedFormData);
    }
  }, []);

  var defaultValues = {
    txtWeight: "",
    txtHeight: "",
    txtSmoker: "",
    txtCurrentSmoker: "",
    txtFamilyHistoryCAD: "",
    txtDiabetes: "",
    txtDiabetesControl: "",
    txtHypercholesterolemia: "",
    txtLastCreatininePreop: "",
    txtRenalFailure: "",
    txtDialysis: "",
    txtHypertension: "",
    txtCerebrovascularAccident: "",
    txtWhenOptions: "",
    txtInfectiousEndocarditis: "",
    txtInfectiousEndocarditisType: "",
    txtChronicLungDisease: "",
    txtImmunosuppressiveTreatment: "",
    txtPeripheralVascularDisease: "",
    txtCerebrovascularDisease: "",
    txtCVDType: "",
    txtCvInterventions: "",
    txtPriorCardiacOperationsBypass: "",
    txtPriorCardiacOperationsWithoutBypass: "",
    txtPreviousSurgeryCABG: "",
    txtPreviousSurgeryValve: "",
    txtPreviousSurgeryOtherCardiac: "",
    txtPriorPTCA: "",
    txtPriorPTCAInterval: "",
    txtPreviousStentPlacement: "",
    txtPreviousStentPlacementInterval: "",
    txtThrombolysis: "",
    txtThrombolysisInterval: "",
    txtPreviousBalloonValvuloplasty: "",
    txtMyocardialInfarction: "",
    txtWhennOptions: "",
    txtConHeartFail: "",
    txtAngina: "",
    txtAnginaType: "",
    txtUnstableType: "",
    txtCardiogenicShock: "",
    txtShockType: "",
    txtResuscitation: "",
    txtArrhythmia: "",
    txtArrhythmiaType: "",
    txtCcsClassification: "",
    txtNyhaClassification: "",
    txtDigitalis: "",
    txtBetaBlockers: "",
    txtNitratesIV: "",
    txtAnticoagulants: "",
    txtDiuretics: "",
    txtInotropicAgents: "",
    txtSteroids: "",
    txtAspirin: "",
    txtAceInhibitors: "",
    txtOthAntiPlatelets: "",
    txtDiseasedCoronaryVessels: "",
    txtLeftMainDisease: "",
    txtEjectionType: "",
    txtEjectionFractionMethod: "",
    txtPulmonary: "",
    txtPulmonaryArteryMeanPressure: "",
    txtAorticStenosis: "",
    txtGradient: "",
    txtAorticInsufficiency: "",
    txtMitralInsufficiency: "",
    txtTricuspidInsufficiency: "",
    txtPulmonicInsufficiency: "",
    txtMitralStenosis: "",
    txtTricuspidStenosis: "",
    txtPulmonicStenosis: "",
  };

  useEffect(() => {
    async function fetch() {
      try {

        const formPreOperativeData = localStorage.getItem(
          "formPreOperativeData_" + patientId
        );
        if (formPreOperativeData === null) {
          const payload = {
            intSPType: 2,
            txtUUID: patientId,
          };
          const tempResponseData = await APIService.post(
            API.PreoperativeDataForm,
            payload
          );
          console.log(tempResponseData)

          if (
            tempResponseData?.code === 200 ||
            tempResponseData?.code === "200"
          ) {
            //   setTblList(tempResponseData.data ?? []);
            //   setRowCount(tempResponseData.count ?? 0);

            // console.log(tempResponseData.data[0].participant_id);
            if (tempResponseData.data[0]) {
              const temp = tempResponseData.data[0];
              var defaultValues1 = {
                intID: temp.id,
                txtWeight: temp.weight,
                txtHeight: temp.height,
                txtSmoker: temp.smoker,
                txtCurrentSmoker: temp.current_smoker,
                txtFamilyHistoryCAD: temp.family_history_cad,
                txtDiabetes: temp.diabetes,
                txtDiabetesControl: temp.diabetes_control,
                txtHypercholesterolemia: temp.hypercholesterolemia,
                txtLastCreatininePreop: temp.last_creatinine_preop,
                txtRenalFailure: temp.renal_failure,
                txtDialysis: temp.dialysis,
                txtHypertension: temp.hypertension,
                txtCerebrovascularAccident: temp.cerebrovascular_accident,
                txtWhenOptions: temp.when_options,
                txtInfectiousEndocarditis: temp.infectious_endocarditis,
                txtInfectiousEndocarditisType: temp.infectious_endocarditis_type,
                txtChronicLungDisease: temp.chronic_lung_disease,
                txtImmunosuppressiveTreatment: temp.immunosuppressive_treatment,
                txtPeripheralVascularDisease: temp.peripheral_vascular_disease,
                txtCerebrovascularDisease: temp.cerebrovascular_disease,
                txtCVDType: temp.cvd_type,
                txtCvInterventions: temp.cv_interventions,
                txtPriorCardiacOperationsBypass: temp.prior_cardiac_operations_bypass,
                txtPriorCardiacOperationsWithoutBypass: temp.prior_cardiac_operations_without_bypass,
                txtPreviousSurgeryCABG: temp.previous_surgery_cabg,
                txtPreviousSurgeryValve: temp.previous_surgery_valve,
                txtPreviousSurgeryOtherCardiac: temp.previous_surgery_other_cardiac,
                txtPriorPTCA: temp.prior_ptca,
                txtPriorPTCAInterval: temp.prior_ptca_interval,
                txtPreviousStentPlacement: temp.previous_stent_placement,
                txtPreviousStentPlacementInterval: temp.previous_stent_placement_interval,
                txtThrombolysis: temp.thrombolysis,
                txtThrombolysisInterval: temp.thrombolysis_interval,
                txtPreviousBalloonValvuloplasty: temp.previous_balloon_valvuloplasty,
                txtMyocardialInfarction: temp.myocardial_infarction,
                txtWhennOptions: temp.whenn_options,
                txtConHeartFail: temp.con_heart_fail,
                txtAngina: temp.angina,
                txtAnginaType: temp.angina_type,
                txtUnstableType: temp.unstable_type,
                txtCardiogenicShock: temp.cardiogenic_shock,
                txtShockType: temp.shock_type,
                txtResuscitation: temp.resuscitation,
                txtArrhythmia: temp.arrhythmia,
                txtArrhythmiaType: temp.arrhythmia_type,
                txtCcsClassification: temp.ccs_classification,
                txtNyhaClassification: temp.nyha_classification,
                txtDigitalis: temp.digitalis,
                txtBetaBlockers: temp.beta_blockers,
                txtNitratesIV: temp.nitrates_iv,
                txtAnticoagulants: temp.anticoagulants,
                txtDiuretics: temp.diuretics,
                txtInotropicAgents: temp.inotropic_agents,
                txtSteroids: temp.steroids,
                txtAspirin: temp.aspirin,
                txtAceInhibitors: temp.ace_inhibitors,
                txtOthAntiPlatelets: temp.oth_anti_platelets,
                txtDiseasedCoronaryVessels: temp.diseased_coronary_vessels,
                txtLeftMainDisease: temp.left_main_disease,
                txtEjectionType: temp.ejection_type,
                txtEjectionFractionMethod: temp.ejection_fraction_method,
                txtPulmonary: temp.pulmonary,
                txtPulmonaryArteryMeanPressure: temp.pulmonary_artery_mean_pressure,
                txtAorticStenosis: temp.aortic_stenosis,
                txtGradient: temp.gradient,
                txtAorticInsufficiency: temp.aortic_insufficiency,
                txtMitralInsufficiency: temp.mitral_insufficiency,
                txtTricuspidInsufficiency: temp.tricuspid_insufficiency,
                txtPulmonicInsufficiency: temp.pulmonic_insufficiency,
                txtMitralStenosis: temp.mitral_stenosis,
                txtTricuspidStenosis: temp.tricuspid_stenosis,
                txtPulmonicStenosis: temp.pulmonic_stenosis,
              };
              setSelectedDiabetesControl(temp.diabetes_control)
              setSelectedWhenOptions(temp.when_options)
              setSelectedInfectiousEndoType(temp.infectious_endocarditis_type)
              setSelectedChronicLungDisease(temp.chronic_lung_disease)
              setSelectedCVDType(temp.cvd_type)
              setSelectedPriorPCTAInterval(temp.prior_ptca_interval)
              setSelectedThrombolysisInterval(temp.thrombolysis_interval)
              setSelectedWhennOptions(temp.whenn_options)
              setSelectedShockType(temp.shock_type)
              setSelectedArrhythmiaType(temp.arrhythmia_type)
              setSelectedCssClassification(temp.ccs_classification)
              setSelectedNyhaClassification(temp.nyha_classification)
              setSelectedAorticInsufficiency(temp.aortic_insufficiency)
              setSelectedMitralInsufficiency(temp.mitral_insufficiency)
              setSelectedTricuspidInsufficiency(temp.tricuspid_insufficiency)
              setSelectedPulmonicInsufficiency(temp.pulmonic_insufficiency)
              setEjectionType(temp.ejection_type)
              setSelectedCurrentSMoker(temp.current_smoker)
              console.log(defaultValues1, "data");
              reset(defaultValues1);
              setValues(defaultValues1);
              localStorage.setItem(
                "formPreOperativeData_" + patientId,
                JSON.stringify(defaultValues1)
              );
            } else {
              var defaultValues1 = {
                txtWeight: "",
                txtHeight: "",
                txtSmoker: "",
                txtCurrentSmoker: "",
                txtFamilyHistoryCAD: "",
                txtDiabetes: "",
                txtDiabetesControl: "",
                txtHypercholesterolemia: "",
                txtLastCreatininePreop: "",
                txtRenalFailure: "",
                txtDialysis: "",
                txtHypertension: "",
                txtCerebrovascularAccident: "",
                txtWhenOptions: "",
                txtInfectiousEndocarditis: "",
                txtInfectiousEndocarditisType: "",
                txtChronicLungDisease: "",
                txtImmunosuppressiveTreatment: "",
                txtPeripheralVascularDisease: "",
                txtCerebrovascularDisease: "",
                txtCVDType: "",
                txtCvInterventions: "",
                txtPriorCardiacOperationsBypass: "",
                txtPriorCardiacOperationsWithoutBypass: "",
                txtPreviousSurgeryCABG: "",
                txtPreviousSurgeryValve: "",
                txtPreviousSurgeryOtherCardiac: "",
                txtPriorPTCA: "",
                txtPriorPTCAInterval: "",
                txtPreviousStentPlacement: "",
                txtPreviousStentPlacementInterval: "",
                txtThrombolysis: "",
                txtThrombolysisInterval: "",
                txtPreviousBalloonValvuloplasty: "",
                txtMyocardialInfarction: "",
                txtWhennOptions: "",
                txtConHeartFail: "",
                txtAngina: "",
                txtAnginaType: "",
                txtUnstableType: "",
                txtCardiogenicShock: "",
                txtShockType: "",
                txtResuscitation: "",
                txtArrhythmia: "",
                txtArrhythmiaType: "",
                txtCcsClassification: "",
                txtNyhaClassification: "",
                txtDigitalis: "",
                txtBetaBlockers: "",
                txtNitratesIV: "",
                txtAnticoagulants: "",
                txtDiuretics: "",
                txtInotropicAgents: "",
                txtSteroids: "",
                txtAspirin: "",
                txtAceInhibitors: "",
                txtOthAntiPlatelets: "",
                txtDiseasedCoronaryVessels: "",
                txtLeftMainDisease: "",
                txtEjectionType: "",
                txtEjectionFractionMethod: "",
                txtPulmonary: "",
                txtPulmonaryArteryMeanPressure: "",
                txtAorticStenosis: "",
                txtGradient: "",
                txtAorticInsufficiency: "",
                txtMitralInsufficiency: "",
                txtTricuspidInsufficiency: "",
                txtPulmonicInsufficiency: "",
                txtMitralStenosis: "",
                txtTricuspidStenosis: "",
                txtPulmonicStenosis: "",
              };
              setValues(defaultValues1);

              localStorage.setItem(
                "formPreOperativeData_" + patientId,
                JSON.stringify(defaultValues1)
              );
            }
          }
          setIsLoading(false);
        } else {
          setValues(JSON.parse(formPreOperativeData));
          setSelectedDiabetesControl(JSON.parse(formPreOperativeData).txtDiabetesControl)
          setSelectedWhenOptions(JSON.parse(formPreOperativeData).txtWhenOptions)
          setSelectedInfectiousEndoType(JSON.parse(formPreOperativeData).txtInfectiousEndocarditisType)
          setSelectedChronicLungDisease(JSON.parse(formPreOperativeData).txtChronicLungDisease)
          setSelectedCVDType(JSON.parse(formPreOperativeData).txtCVDType)
          setSelectedPriorPCTAInterval(JSON.parse(formPreOperativeData).txtPriorPTCAInterval)
          setSelectedThrombolysisInterval(JSON.parse(formPreOperativeData).txtThrombolysisInterval)
          setSelectedWhennOptions(JSON.parse(formPreOperativeData).txtWhennOptions)
          setSelectedShockType(JSON.parse(formPreOperativeData).txtShockType)
          setSelectedArrhythmiaType(JSON.parse(formPreOperativeData).txtArrhythmiaType)
          setSelectedCssClassification(JSON.parse(formPreOperativeData).txtCcsClassification)
          setSelectedNyhaClassification(JSON.parse(formPreOperativeData).txtNyhaClassification)
          setSelectedAorticInsufficiency(JSON.parse(formPreOperativeData).txtAorticInsufficiency)
          setSelectedMitralInsufficiency(JSON.parse(formPreOperativeData).txtMitralInsufficiency)
          setSelectedTricuspidInsufficiency(JSON.parse(formPreOperativeData).txtTricuspidInsufficiency)
          setSelectedPulmonicInsufficiency(JSON.parse(formPreOperativeData).txtPulmonicInsufficiency)
          setEjectionType(JSON.parse(formPreOperativeData).txtEjectionType)
          setSelectedCurrentSMoker(JSON.parse(formPreOperativeData).txtCurrentSmoker)

          reset(JSON.parse(formPreOperativeData));
        }
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
        console.error(error);
        return;
      }
    }
    fetch();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => {
      // Update form values
      const updatedValues = { ...prevValues, [name]: value };

      // Save the updated values to localStorage
      localStorage.setItem(
        "formPreOperativeData_" + patientId,
        JSON.stringify(updatedValues)
      );

      // Return the updated values to update state
      return updatedValues;
    });
  };

  const handleDropdownChange = (name, value) => {
    // const { name, value } = event.target;
    //debugger;
    setValues((prevValues) => {
      // Update form values
      const updatedValues = { ...prevValues, [name]: value };

      // Save the updated values to localStorage
      localStorage.setItem(
        "formPreOperativeData_" + patientId,
        JSON.stringify(updatedValues)
      );

      // Return the updated values to update state
      return updatedValues;
    });
  };

  const navigate = useNavigate();

  const handlePopupResponse = (response) => {
    setShowPopup(false);
    if (response === 'yes') {
      // Redirect to the 'Preoperative Data Form' page
      navigate(`/patients/postoperativeData/${patientId}`);
    } else {
      // Redirect to a different page
      navigate(`/patients/patients`);
    }
  };

  const [showPopup, setShowPopup] = useState(false);


  const onSubmit = async (data) => {
    try {
      setIsBtnLoading(true);
      // Retrieve and parse data from localStorage
      //const savedFormData = localStorage.getItem("formData");
      //const formData = savedFormData ? JSON.parse(savedFormData) : {};
      // Create a FormData object
      const formData = new FormData();

      // Populate FormData with array values
      for (const key in data) {
        formData.append(key, data[key]);
      }
      formData.append("intSPType", 1);
      //formData.append("intID", patientId);
      formData.intID = patientId;
      formData.txtSmoker = smoker;
      formData.txtDiabetes = diabetes;
      formData.txtDiabetesControl = selectedDiabetesControl;
      formData.txtWhenOptions = selectedWhenOptions;
      formData.txtInfectiousEndocarditisType = selectedInfectiousEndoType;
      formData.txtChronicLungDisease = selectedChronicLungDisease;
      formData.txtCVDType = selectedCVDType;
      formData.txtPreviousStentPlacementInterval = selectedPreStentPlaceInterval;
      formData.txtPriorPTCAInterval = selectedPriorPTCAInterval;
      formData.txtThrombolysisInterval = selectedThrombolysisInterval;
      formData.txtWhennOptions = selectedWhennOptions;
      formData.txtShockType = selectedShockType;
      formData.txtArrhythmiaType = selectedArrhythmiaType;
      formData.txtCcsClassification = selectedCcsClassification;
      formData.txtNyhaClassification = selectedNyhaClassification;
      formData.txtAorticInsufficiency = selectedAorticInsufficiency;
      formData.txtMitralInsufficiency = selectedMitralInsufficiency;
      formData.txtTricuspidInsufficiency = selectedTricuspidInsufficiency;
      formData.txtPulmonicInsufficiency = selectedPulmonicInsufficiency;
      formData.txtRenalFailure = renalFailure;
      formData.txtCerebrovascularAccident = cerebrovascularAccident;
      formData.txtInfectiousEndocarditis = infectiousEndocarditis;
      formData.txtCerebrovascularDisease = cerebrovascularDisease;
      formData.txtCvInterventions = cvInterventions;
      formData.txtPriorPTCA = priorPTCA;
      formData.txtPreviousStentPlacement = previousStentPlacement;
      formData.txtThrombolysis = thrombolysis;
      formData.txtMyocardialInfarction = myocardialInfarction;
      formData.txtAngina = angina;
      formData.txtAnginaType = anginaType;
      formData.txtCardiogenicShock = cardiogenicShock;
      formData.txtPulmonary = pulmonary;
      formData.txtAorticStenosis = aorticStenosis;
      formData.txtEjectionType = ejectionType;
      formData.txtArrhythmia = arrhythmia;
      formData.txtCurrentSmoker = setCurrentSmoker;

      // Send the formData to the server
      const saveResult = await APIService.post(API.PreoperativeDataForm, formData);
      console.log(saveResult, "saveResult");
      responseRef.current.responseFlag = true;
      responseRef.current.responseCode = saveResult.code;
      responseRef.current.responseMessage = saveResult.message;
      console.log(responseRef);
      // Check if the data is successfully saved to the database
      if (saveResult.code === 200 || saveResult.code === "200") {
        setShowPopup(true);
        // If successful, remove data from local storage
        localStorage.removeItem("formPreOperativeData_" + patientId);

        // Reset form values and loading state
        //setValues({});
        setIsBtnLoading(false);
        setIsLoading(false);
      }
      if (saveResult.code === 201 || saveResult.code === "201") {
        setIsBtnLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsBtnLoading(false);
      if (error?.code === 5001) setResponseError(error?.message);
      if (error?.code === 5002) setResponseListError(error?.errors);
    }
    //reset();
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box>
        <Card sx={{ padding: "40px" }}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            {/* D. Pre-Operative Risk Factors */}
            <Typography
              className="custom-heading"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              D. Pre-Operative Risk Factors
            </Typography>

            <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">

              {/* Weight */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="Weight (kg)"
                  name="txtWeight"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtWeight || ""}
                  error={errors?.txtWeight || responseListError?.txtWeight}
                />
              </MDBox>

              {/* Height */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="Height (cm)"
                  name="txtHeight"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtHeight || ""}
                  error={errors?.txtHeight || responseListError?.txtHeight}
                />
              </MDBox>

              {/* Smoker */}

              <MDBox mb={3} width="calc(33% - 10px)">
              <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setSmoker}
                  defaultValue={smoker}
                  id={"txtSmoker"}
                  handleDropdownChange={handleDropdownChange}
                  value={smoker}
                  error={
                    errors?.txtSmoker ||
                    responseListError?.txtSmoker
                  }
                  label={"Smoker"}
                  />
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={
                    YesNoOptions.find((i) => i.id === smoker)
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Smoker"}
                      placeholder={"Smoker"}
                      error={isError ? true : false}
                      value={values?.txtSmoker || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setSmoker(newValue.value);
                      handleDropdownChange("txtSmoker", newValue.value);
                    }
                  }}
                /> */}
              </MDBox>

              {/* Current Smoker */}
              {smoker === "yes" && (
                <>
                  <MDBox mb={3} width="calc(33% - 10px)">
                    <MDAutoComplete
                      options={YesNoOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={setCurrentSmoker}
                      handleDropdownChange={handleDropdownChange}
                      id={"txtCurrentSmoker"}
                      value={setCurrentSmoker}
                      error={errors?.txtCurrentSmoker || responseListError?.txtCurrentSmoker}
                      label={"Current Smoker"}
                    />
                  </MDBox>
                </>
              )}
              {/* Family History of CAD */}

              <MDBox mb={3} width="calc(33% - 10px)">
                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtFamilyHistoryCAD}
                  id={"txtFamilyHistoryCAD"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtFamilyHistoryCAD || ""}
                  error={errors?.txtFamilyHistoryCAD || responseListError?.txtFamilyHistoryCAD}
                  label={"Family History of CAD"}
                />
              </MDBox>

              {/* Diabetes */}

              <MDBox mb={3} width="calc(33% - 10px)">
              <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setDiabetes}
                  defaultValue={diabetes}
                  id={"txtDiabetes"}
                  handleDropdownChange={handleDropdownChange}
                  value={diabetes}
                  error={
                    errors?.txtDiabetes ||
                    responseListError?.txtDiabetes
                  }
                  label={"Diabetes"}
                  />
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={
                    YesNoOptions.find((i) => i.id === diabetes)
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Diabetes"}
                      placeholder={"Diabetes"}
                      error={isError ? true : false}
                      value={values?.txtDiabetes || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setDiabetes(newValue.value);
                      handleDropdownChange("txtDiabetes", newValue.value);
                    }
                  }}
                /> */}
              </MDBox>

              {/* Diabetes Control */}
              {diabetes === "yes" && (<>
                <MDBox mb={3} width="calc(33% - 10px)">
                  <FormControl component="fieldset">
                    <FormLabel
                      id="gender-radio-buttons-group-label"
                      sx={{ fontSize: "14px" }}
                    >
                      Diabetes Control
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="diabetes-control-radio-buttons-group-label"
                      defaultValue={selectedDiabetesControl}
                      value={selectedDiabetesControl}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        // setValue("txtDiabetesControl", selectedValue);
                        setSelectedDiabetesControl(selectedValue);

                        handleDropdownChange("txtDiabetesControl", selectedValue);
                        // // Update the form values stored in local storage
                        // setValues((prevValues) => {
                        //   const updatedValues = {
                        //     ...prevValues,
                        //     txtDiabetesControl: selectedValue,
                        //   };

                        //   // Save the updated values to localStorage
                        //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                        //   // Return the updated values to update state
                        //   return updatedValues;
                        // });
                      }}
                      row // Set row to display radio buttons horizontally
                    >
                      {diabetesControlOptions.map((option) => (
                        <FormControlLabel
                          key={option.id}
                          value={option.id}
                          control={<Radio />}
                          label={option.label}
                        />
                      ))}
                    </RadioGroup>

                  </FormControl>
                </MDBox>
              </>
              )}

              {/* Hypercholesterolemia */}

              <MDBox mb={3} width="calc(33% - 10px)">
                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtHypercholesterolemia}
                  id={"txtHypercholesterolemia"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtHypercholesterolemia || ""}
                  error={errors?.txtHypercholesterolemia || responseListError?.txtHypercholesterolemia}
                  label={"Hypercholesterolemia"}
                />
              </MDBox>

              {/* Last Creatinine Preop */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="Last Creatinine Preop"
                  name="txtLastCreatininePreop"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtLastCreatininePreop || ""}
                  error={errors?.txtLastCreatininePreop || responseListError?.txtLastCreatininePreop}
                />
              </MDBox>

              {/* Renal Failure */}

              <MDBox mb={3} width="calc(33% - 10px)">
              <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setRenalFailure}
                  defaultValue={renalFailure}
                  id={"txtRenalFailure"}
                  handleDropdownChange={handleDropdownChange}
                  value={renalFailure}
                  error={
                    errors?.txtRenalFailure ||
                    responseListError?.txtRenalFailure
                  }
                  label={"Renal Failure"}
                  />
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={YesNoOptions.find((i) => i.id === renalFailure)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Renal Failure"}
                      placeholder={"Renal Failure"}
                      error={isError ? true : false}
                      value={values?.txtRenalFailure || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      // fetchData(newValue.value);
                      setRenalFailure(newValue.value);
                      handleDropdownChange("txtRenalFailure", newValue.value);
                    }
                  }}
                /> */}

              </MDBox>

              {/* Dialysis */}
              {renalFailure === "yes" && (<>
                <MDBox mb={3} width="calc(33% - 10px)">
                  <MDAutoComplete
                    options={YesNoOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtDialysis}
                    id={"txtDialysis"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtDialysis || ""}
                    error={errors?.txtDialysis || responseListError?.txtDialysis}
                    label={"Dialysis"}
                  />

                </MDBox>
              </>
              )}
              {/* Hypertension */}

              <MDBox mb={3} width="calc(33% - 10px)">
                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtHypertension}
                  id={"txtHypertension"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtHypertension || ""}
                  error={errors?.txtHypertension || responseListError?.txtHypertension}
                  label={"Hypertension"}
                />
              </MDBox>

              {/* Cerebrovascular Accident */}

              <MDBox mb={3} width="calc(33% - 10px)">
              <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setCerebrovascularAccident}
                  defaultValue={cerebrovascularAccident}
                  id={"txtCerebrovascularAccident"}
                  handleDropdownChange={handleDropdownChange}
                  value={cerebrovascularAccident}
                  error={
                    errors?.txtCerebrovascularAccident ||
                    responseListError?.txtCerebrovascularAccident
                  }
                  label={"Cerebrovascular Accident"}
                  />
                  
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={YesNoOptions.find((i) => i.id === cerebrovascularAccident)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Cerebrovascular Accident"}
                      placeholder={"Cerebrovascular Accident"}
                      error={isError ? true : false}
                      value={values?.txtCerebrovascularAccident || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setCerebrovascularAccident(newValue.value);
                      handleDropdownChange("txtCerebrovascularAccident", newValue.value);
                    }
                  }}
                /> */}
              </MDBox>

              {/* When */}
              {cerebrovascularAccident === "yes" && (
                <>
                  <MDBox mb={3} width="calc(33% - 10px)">
                    <FormControl>
                      <FormLabel id="when-radio-buttons-group-label" sx={{ fontSize: '14px' }}>
                        When:
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="when-radio-buttons-group-label"
                        defaultValue={selectedWhenOptions}
                        value={selectedWhenOptions}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          // setValue("txtWhenOptions", selectedValue);
                          setSelectedWhenOptions(selectedValue);

                          handleDropdownChange("txtWhenOptions", selectedValue);
                          // // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtWhenOptions: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }}
                        row // Set row to display radio buttons horizontally
                      >
                        {whenOptions.map((option) => (
                          <FormControlLabel
                            key={option.id}
                            value={option.id}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>

                    </FormControl>
                  </MDBox>

                </>
              )}

              {/* Infectious Endocarditis */}

              <MDBox mb={3} width="calc(33% - 10px)">
              <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setInfectiousEndocarditis}
                  defaultValue={infectiousEndocarditis}
                  id={"txtInfectiousEndocarditis"}
                  handleDropdownChange={handleDropdownChange}
                  value={infectiousEndocarditis}
                  error={
                    errors?.txtInfectiousEndocarditis ||
                    responseListError?.txtInfectiousEndocarditis
                  }
                  label={"Infectious Endocarditis"}
                  />
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={YesNoOptions.find((i) => i.id === infectiousEndocarditis)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Infectious Endocarditis"}
                      placeholder={"Infectious Endocarditis"}
                      error={isError ? true : false}
                      value={values?.txtInfectiousEndocarditis || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setInfectiousEndocarditis(newValue.value);
                      handleDropdownChange("txtInfectiousEndocarditis", newValue.value);
                    }
                  }}
                /> */}
              </MDBox>


              {/* Infectious Endocarditis Type */}
              {infectiousEndocarditis === "yes" && (
                <>
                  <MDBox mb={3} width="calc(33% - 10px)">

                    <FormControl>
                      <FormLabel id="endocarditis-type-radio-buttons-group-label" sx={{ fontSize: '14px' }}>
                        Infectious Endocarditis Type:
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="endocarditis-type-radio-buttons-group-label"
                        defaultValue={selectedInfectiousEndoType}
                        value={selectedInfectiousEndoType || ''}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          // setValue("txtInfectiousEndocarditisType", selectedValue);
                          setSelectedInfectiousEndoType(selectedValue);

                          handleDropdownChange("txtInfectiousEndocarditisType", selectedValue);
                          // // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtInfectiousEndocarditisType: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }}
                        row // Set row to display radio buttons horizontally
                      >
                        {EndocarditisTypeOptions.map((option) => (
                          <FormControlLabel
                            key={option.id}
                            value={option.id}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </MDBox>
                </>
              )}

              {/* Chronic Lung Disease */}

              <MDBox mb={3} width="100%">
                <FormControl component="fieldset">
                  <FormLabel component="legend" sx={{ fontSize: '14px' }}>
                    Chronic Lung Disease
                  </FormLabel>
                  <RadioGroup
                    aria-label="chronic-lung-disease"
                    name="chronicLungDisease"
                    defaultValue={selectedChronicLungDisease}
                    value={selectedChronicLungDisease}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      // setValue("txtChronicLungDisease", selectedValue);
                      setSelectedChronicLungDisease(selectedValue);

                      handleDropdownChange("txtChronicLungDisease", selectedValue);
                      // // Update the form values stored in local storage
                      // setValues((prevValues) => {
                      //   const updatedValues = {
                      //     ...prevValues,
                      //     txtChronicLungDisease: selectedValue,
                      //   };

                      //   // Save the updated values to localStorage
                      //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                      //   // Return the updated values to update state
                      //   return updatedValues;
                      // });
                    }}
                    row // Set row to display radio buttons horizontally
                  //style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    {chronicLungDiseaseOptions.map((option) => (
                      <FormControlLabel
                        key={option.id}
                        value={option.id}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </MDBox>

              {/* Immunosuppressive Treatment */}

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtImmunosuppressiveTreatment}
                  id={"txtImmunosuppressiveTreatment"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtImmunosuppressiveTreatment || ""}
                  error={errors?.txtImmunosuppressiveTreatment || responseListError?.txtImmunosuppressiveTreatment}
                  label={"Immunosuppressive Treatment"}
                />
              </MDBox>

              {/* Peripheral Vascular Disease */}

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtPeripheralVascularDisease}
                  id={"txtPeripheralVascularDisease"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtPeripheralVascularDisease || ""}
                  error={errors?.txtPeripheralVascularDisease || responseListError?.txtPeripheralVascularDisease}
                  label={"Peripheral Vascular Disease"}
                />
              </MDBox>

              {/* Cerebrovascular Disease */}

              <MDBox mb={3} width="calc(33% - 10px)">
              <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setCerebrovascularDisease}
                  defaultValue={cerebrovascularDisease}
                  id={"txtCerebrovascularDisease"}
                  handleDropdownChange={handleDropdownChange}
                  value={cerebrovascularDisease}
                  error={
                    errors?.txtCerebrovascularDisease ||
                    responseListError?.txtCerebrovascularDisease
                  }
                  label={"Cerebrovascular Disease"}
                  />
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={YesNoOptions.find((i) => i.id === cerebrovascularDisease)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Cerebrovascular Disease"}
                      placeholder={"Cerebrovascular Disease"}
                      error={isError ? true : false}
                      value={values?.txtCerebrovascularDisease || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setCerebrovascularDisease(newValue.value);
                      handleDropdownChange("txtCerebrovascularDisease", newValue.value);
                    }
                  }}
                /> */}
              </MDBox>

              {/* CVD Type */}
              {cerebrovascularDisease === "yes" && (
                <>
                  <MDBox mb={3} width="100%">
                    <FormControl component="fieldset" >
                      <FormLabel component="legend" sx={{ fontSize: '14px' }}>CVD Type</FormLabel>
                      <RadioGroup
                        aria-label="cvdtype"
                        name="txtCVDType"
                        defaultValue={selectedCVDType}
                        value={selectedCVDType}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          // setValue("txtCVDType", selectedValue);
                          setSelectedCVDType(selectedValue);

                          handleDropdownChange("txtCVDType", selectedValue);
                          // // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtCVDType: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }}
                        row
                      //style={{ display: 'flex', flexDirection: 'row' }}
                      >
                        {cvdTypeOptions.map((option) => (
                          <FormControlLabel
                            key={option.id}
                            value={option.id}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </MDBox>
                </>
              )}
            </MDBox>

            {/* E. Previous Interventions */}
            <Typography
              className="custom-heading"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              E. Previous Interventions
            </Typography>

            <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">

              <MDBox mb={3} width="calc(33% - 10px)">
              <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setCVInterventions}
                  defaultValue={cvInterventions}
                  id={"txtCvInterventions"}
                  handleDropdownChange={handleDropdownChange}
                  value={cvInterventions}
                  error={
                    errors?.txtCvInterventions ||
                    responseListError?.txtCvInterventions
                  }
                  label={"Previous CV Interventions"}
                  />
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={YesNoOptions.find((i) => i.id === cvInterventions)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Previous CV Interventions"}
                      placeholder={"Previous CV Interventions"}
                      error={isError ? true : false}
                      value={values?.txtCvInterventions || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setCVInterventions(newValue.value);
                      handleDropdownChange("txtCvInterventions", newValue.value);
                    }
                  }}
                /> */}
              </MDBox>

              {/* # of Prior Cardiac Operations Requiring Cardiopulmonary Bypass */}
              {cvInterventions === "yes" && (
                <>

                  <MDBox mb={3} width="calc(33% - 10px)">
                    <MDInput
                      type="text"
                      fullWidth
                      label={"# of Prior Cardiac Operations Requiring Cardiopulmonary Bypass"}
                      name="txtPriorCardiacOperationsBypass"
                      register={register}
                      onChange={handleChange}
                      value={values?.txtPriorCardiacOperationsBypass || ""}
                      error={errors?.txtPriorCardiacOperationsBypass || responseListError?.txtPriorCardiacOperationsBypass}
                    />
                  </MDBox>

                  {/* # of Prior Cardiac Operations Without Cardiopulmonary Bypass */}
                  <MDBox mb={3} width="calc(33% - 10px)">

                    <MDInput
                      type="text"
                      fullWidth
                      label="# of Prior Cardiac Operations Without Cardiopulmonary Bypass"
                      name="txtPriorCardiacOperationsWithoutBypass"
                      register={register}
                      onChange={handleChange}
                      value={values?.txtPriorCardiacOperationsWithoutBypass || ""}
                      error={errors?.txtPriorCardiacOperationsWithoutBypass || responseListError?.txtPriorCardiacOperationsWithoutBypass}
                    />
                  </MDBox>

                  {/* Previous Surgery Coronary Artery Bypass */}

                  <MDBox mb={3} width="calc(33% - 10px)">

                    <MDAutoComplete
                      options={YesNoOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      name="txtPreviousSurgeryCABG"
                      defaultValue={values.txtPreviousSurgeryCABG}
                      id={"txtPreviousSurgeryCABG"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtPreviousSurgeryCABG || ""}
                      error={errors?.txtPreviousSurgeryCABG || responseListError?.txtPreviousSurgeryCABG}
                      label="Previous Surgery Coronary Artery Bypass"
                    />
                  </MDBox>

                  {/* Previous Surgery Valve */}

                  <MDBox mb={3} width="calc(33% - 10px)">

                    <MDAutoComplete
                      options={YesNoOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      name="txtPreviousSurgeryValve"
                      defaultValue={values?.txtPreviousSurgeryValve}
                      id={"txtPreviousSurgeryValve"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtPreviousSurgeryValve || ""}
                      error={errors?.txtPreviousSurgeryValve || responseListError?.txtPreviousSurgeryValve}
                      label="Previous Surgery Valve"
                    />
                  </MDBox>

                  {/* Previous Surgery Other Cardiac */}

                  <MDBox mb={3} width="calc(33% - 10px)">

                    <MDAutoComplete
                      options={YesNoOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      name="txtPreviousSurgeryOtherCardiac"
                      defaultValue={values?.txtPreviousSurgeryOtherCardiac}
                      id={"txtPreviousSurgeryOtherCardiac"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtPreviousSurgeryOtherCardiac || ""}
                      error={errors?.txtPreviousSurgeryOtherCardiac || responseListError?.txtPreviousSurgeryOtherCardiac}
                      label="Previous Surgery Other Cardiac"
                    />
                  </MDBox>

                  {/* Prior PTCA including Balloon and/or Atherectomy */}

                  <MDBox mb={3} width="calc(33% - 10px)">
                  <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setPriorPTCA}
                  defaultValue={priorPTCA}
                  id={"txtPriorPTCA"}
                  handleDropdownChange={handleDropdownChange}
                  value={priorPTCA}
                  error={
                    errors?.txtPriorPTCA ||
                    responseListError?.txtPriorPTCA
                  }
                  label={"Prior PTCA including Balloon and/or Atherectomy"}
                  />
                    {/* <Autocomplete
                      id="tags-outlined"
                      className="drop-down"
                      options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      defaultValue={YesNoOptions.find((i) => i.id === priorPTCA)}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Prior PTCA including Balloon and/or Atherectomy"}
                          placeholder={"Prior PTCA including Balloon and/or Atherectomy"}
                          error={isError ? true : false}
                          value={values?.txtPriorPTCA || ""}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          setPriorPTCA(newValue.value);
                          handleDropdownChange("txtPriorPTCA", newValue.value);
                        }
                      }}
                    /> */}
                  </MDBox>

                  {/* Interval for Prior PTCA */}
                  {priorPTCA === "yes" && (
                    <>
                      <MDBox mb={3} width="calc(33% - 10px)">

                        <FormControl>
                          <FormLabel id="interval-radio-buttons-group-label" sx={{ fontSize: '14px' }}>Interval</FormLabel>
                          <RadioGroup
                            aria-labelledby="interval-radio-buttons-group-label"
                            defaultValue={selectedPriorPTCAInterval}
                            value={selectedPriorPTCAInterval}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              // setValue("txtPriorPTCAInterval", selectedValue);
                              setSelectedPriorPCTAInterval(selectedValue);

                              handleDropdownChange("txtPriorPTCAInterval", selectedValue);
                              // // Update the form values stored in local storage
                              // setValues((prevValues) => {
                              //   const updatedValues = {
                              //     ...prevValues,
                              //     txtPriorPTCAInterval: selectedValue,
                              //   };

                              //   // Save the updated values to localStorage
                              //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                              //   // Return the updated values to update state
                              //   return updatedValues;
                              // });
                            }}
                            row // Set row to display radio buttons horizontally
                          >
                            {PriorPCTAIntervalOptions.map((option) => (
                              <FormControlLabel
                                key={option.id}
                                value={option.id}
                                control={<Radio />}
                                label={option.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </MDBox>
                    </>
                  )}

                  {/* Previous non-surgical Stent Placement */}
                  <MDBox mb={3} width="calc(33% - 10px)">
                  <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setPreviousStentPlacement}
                  defaultValue={previousStentPlacement}
                  id={"txtPreviousStentPlacement"}
                  handleDropdownChange={handleDropdownChange}
                  value={previousStentPlacement}
                  error={
                    errors?.txtPreviousStentPlacement ||
                    responseListError?.txtPreviousStentPlacement
                  }
                  label={"Previous non-surgical Stent Placement"}
                  />
                    {/* <Autocomplete
                      id="tags-outlined"
                      className="drop-down"
                      options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      defaultValue={YesNoOptions.find((i) => i.id === previousStentPlacement)}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Previous non-surgical Stent Placement"}
                          placeholder={"Previous non-surgical Stent Placement"}
                          error={isError ? true : false}
                          value={values?.txtPreviousStentPlacement || ""}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          setPreviousStentPlacement(newValue.value);
                          handleDropdownChange("txtPreviousStentPlacement", newValue.value);
                        }
                      }}
                    /> */}
                  </MDBox>

                  {/* Interval for Previous non-surgical Stent Placement */}
                  {previousStentPlacement === "yes" && (
                    <>
                      <MDBox mb={3} width="calc(33% - 10px)">

                        <FormControl>
                          <FormLabel id="interval-radio-buttons-group-label" sx={{ fontSize: '14px' }}>Interval</FormLabel>
                          <RadioGroup
                            aria-labelledby="interval-radio-buttons-group-label"
                            defaultValue={selectedPreStentPlaceInterval}
                            value={selectedPreStentPlaceInterval}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              // setValue("txtPreviousStentPlacementInterval", selectedValue);
                              setSelectedPreStentPlaceInterval(selectedValue);

                              handleDropdownChange("txtPreviousStentPlacementInterval", selectedValue);
                              // // Update the form values stored in local storage
                              // setValues((prevValues) => {
                              //   const updatedValues = {
                              //     ...prevValues,
                              //     txtPreviousStentPlacementInterval: selectedValue,
                              //   };

                              //   // Save the updated values to localStorage
                              //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                              //   // Return the updated values to update state
                              //   return updatedValues;
                              // });
                            }}
                            row // Set row to display radio buttons horizontally
                          >
                            {StentPlaceIntervalOptions.map((option) => (
                              <FormControlLabel
                                key={option.id}
                                value={option.id}
                                control={<Radio />}
                                label={option.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </MDBox>
                    </>
                  )}

                  {/* Thrombolysis */}

                  <MDBox mb={3} width="calc(33% - 10px)">
                  <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setThrombolysis}
                  defaultValue={thrombolysis}
                  id={"txtThrombolysis"}
                  handleDropdownChange={handleDropdownChange}
                  value={thrombolysis}
                  error={
                    errors?.txtThrombolysis ||
                    responseListError?.txtThrombolysis
                  }
                  label={"Thrombolysis"}
                  />
                    {/* <Autocomplete
                      id="tags-outlined"
                      className="drop-down"
                      options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      defaultValue={YesNoOptions.find((i) => i.id === thrombolysis)}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Thrombolysis"}
                          placeholder={"Thrombolysis"}
                          error={isError ? true : false}
                          value={values?.txtThrombolysis || ""}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          setThrombolysis(newValue.value);
                          handleDropdownChange("txtThrombolysis", newValue.value);
                        }
                      }}
                    /> */}
                  </MDBox>

                  {/* Interval for Thrombolysis */}
                  {thrombolysis === "yes" && (
                    <>
                      <MDBox mb={3} width="calc(33% - 10px)">

                        <FormControl>
                          <FormLabel id="interval-radio-buttons-group-label" sx={{ fontSize: '14px' }}>Interval</FormLabel>
                          <RadioGroup
                            aria-labelledby="interval-radio-buttons-group-label"
                            defaultValue={selectedThrombolysisInterval}
                            value={selectedThrombolysisInterval}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              // setValue("txtThrombolysisInterval", selectedValue);
                              setSelectedThrombolysisInterval(selectedValue);

                              handleDropdownChange("txtThrombolysisInterval", selectedValue);
                              // // Update the form values stored in local storage
                              // setValues((prevValues) => {
                              //   const updatedValues = {
                              //     ...prevValues,
                              //     txtThrombolysisInterval: selectedValue,
                              //   };

                              //   // Save the updated values to localStorage
                              //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                              //   // Return the updated values to update state
                              //   return updatedValues;
                              // });
                            }}
                            row // Set row to display radio buttons horizontally
                          >
                            {ThrombolysisIntervalOptions.map((option) => (
                              <FormControlLabel
                                key={option.id}
                                value={option.id}
                                control={<Radio />}
                                label={option.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </MDBox>
                    </>
                  )}


                  {/* Previous non-surgical Balloon Valvuloplasty */}

                  <MDBox mb={3} width="calc(33% - 10px)">

                    <MDAutoComplete
                      options={YesNoOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={values?.txtPreviousBalloonValvuloplasty}
                      id={"txtPreviousBalloonValvuloplasty"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtPreviousBalloonValvuloplasty || ""}
                      error={errors?.txtPreviousBalloonValvuloplasty || responseListError?.txtPreviousBalloonValvuloplasty}
                      label={"Previous non-surgical Balloon Valvuloplasty"}
                    />
                  </MDBox>
                </>
              )}
            </MDBox>

            {/* F. Pre Operative Cardiac Status */}
            <Typography
              className="custom-heading"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              F. Pre Operative Cardiac Status
            </Typography>

            <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">

              <MDBox mb={3} width="calc(33% - 10px)">
              <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setMyocardialInfarction}
                  defaultValue={myocardialInfarction}
                  id={"txtMyocardialInfarction"}
                  handleDropdownChange={handleDropdownChange}
                  value={myocardialInfarction}
                  error={
                    errors?.txtMyocardialInfarction ||
                    responseListError?.txtMyocardialInfarction
                  }
                  label={"Myocardial Infarction"}
                  />
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={YesNoOptions.find((i) => i.id === myocardialInfarction)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Myocardial Infarction"}
                      placeholder={"Myocardial Infarction"}
                      error={isError ? true : false}
                      value={values?.txtMyocardialInfarction || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setMyocardialInfarction(newValue.value);
                      handleDropdownChange("txtMyocardialInfarction", newValue.value);
                    }
                  }}
                /> */}
              </MDBox>

              {myocardialInfarction === "yes" && (
                <>
                  <MDBox mb={3} width="100%">
                    <FormControl component="fieldset">
                      <FormLabel component="legend" sx={{ fontSize: '14px' }}>When</FormLabel>
                      <RadioGroup
                        aria-label="when"
                        name="txtWhennOptions"
                        defaultValue={selectedWhennOptions}
                        value={selectedWhennOptions || ''}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          // setValue("txtWhennOptions", selectedValue);
                          setSelectedWhennOptions(selectedValue);

                          handleDropdownChange("txtWhennOptions", selectedValue);
                          // // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtWhennOptions: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }}

                        style={{ display: 'flex', flexDirection: 'row' }}

                      >
                        {WhenOptions.map((option) => (
                          <FormControlLabel
                            key={option.id}
                            value={option.id}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </MDBox>
                </>
              )}

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtConHeartFail}
                  id={"txtConHeartFail"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtConHeartFail || ""}
                  error={errors?.txtConHeartFail || responseListError?.txtConHeartFail}
                  label={"Congestive Heart Failure"}
                />
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)">
              <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setAngina}
                  defaultValue={angina}
                  id={"txtAngina"}
                  handleDropdownChange={handleDropdownChange}
                  value={angina}
                  error={
                    errors?.txtAngina ||
                    responseListError?.txtAngina
                  }
                  label={"Angina"}
                  />
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={YesNoOptions.find((i) => i.id === angina)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Angina"}
                      placeholder={"Angina"}
                      error={isError ? true : false}
                      value={values?.txtAngina || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setAngina(newValue.value);
                      handleDropdownChange("txtAngina", newValue.value);
                    }
                  }}
                /> */}
              </MDBox>

              {angina === "yes" && (
                <>
                  <MDBox mb={3} width="calc(33% - 10px)">
                  <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setAnginaType}
                  defaultValue={anginaType}
                  id={"txtAnginaType"}
                  handleDropdownChange={handleDropdownChange}
                  value={anginaType}
                  error={
                    errors?.txtAnginaType ||
                    responseListError?.txtAnginaType
                  }
                  label={"Type"}
                  />
                    {/* <Autocomplete
                      id="tags-outlined"
                      className="drop-down"
                      options={AginaTypeOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      defaultValue={AginaTypeOptions.find((i) => i.id === anginaType)}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Type"}
                          placeholder={"Type"}
                          error={isError ? true : false}
                          value={values?.txtAnginaType || ""}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          setAnginaType(newValue.value);
                          handleDropdownChange("txtAnginaType", newValue.value);

                        }
                      }}
                    /> */}
                  </MDBox>
                </>
              )}

              {anginaType === "Unstable" && (
                <>
                  <MDBox mb={3} width="calc(33% - 10px)">

                    <MDAutoComplete
                      options={UnstableTypeOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={values?.txtUnstableType}
                      id={"txtUnstableType"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtUnstableType || ""}
                      error={errors?.txtUnstableType || responseListError?.txtUnstableType}
                      label={"Unstable Type"}
                    />
                  </MDBox>
                </>
              )}

              <MDBox mb={3} width="calc(33% - 10px)">
              <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setCardiogenicShock}
                  defaultValue={cardiogenicShock}
                  id={"txtCardiogenicShock"}
                  handleDropdownChange={handleDropdownChange}
                  value={cardiogenicShock}
                  error={
                    errors?.txtCardiogenicShock ||
                    responseListError?.txtCardiogenicShock
                  }
                  label={"Cardiogenic Shock"}
                  />
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={YesNoOptions.find((i) => i.id === cardiogenicShock)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Cardiogenic Shock"}
                      placeholder={"Cardiogenic Shock"}
                      error={isError ? true : false}
                      value={values?.txtCardiogenicShock || ""}

                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setCardiogenicShock(newValue.value);
                      handleDropdownChange("txtCardiogenicShock", newValue.value);
                    }
                  }}
                /> */}
              </MDBox>
              {cardiogenicShock === "yes" && (
                <>
                  <MDBox mb={3} width="50%">
                    <FormControl>
                      <FormLabel id="shock-type-radio-buttons-group-label" sx={{ fontSize: '14px' }}>Shock Type</FormLabel>
                      <RadioGroup
                        aria-labelledby="shock-type-radio-buttons-group-label"
                        defaultValue={selectedShockType}
                        value={selectedShockType}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          // setValue("txtGender", selectedValue);
                          setSelectedShockType(selectedValue);

                          handleDropdownChange("txtShockType", selectedValue);

                          // // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtGender: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }}
                        row // Set row to display radio buttons horizontally
                      >
                        {ShockTypeOptions.map((option) => (
                          <FormControlLabel
                            key={option.id}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </MDBox>

                </>
              )}
              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtResuscitation}
                  id={"txtResuscitation"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtResuscitation || ""}
                  error={errors?.txtResuscitation || responseListError?.txtResuscitation}
                  label={"Resuscitation"}
                />
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)">
              <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setArrhythmia}
                  defaultValue={arrhythmia}
                  id={"txtArrhythmia"}
                  handleDropdownChange={handleDropdownChange}
                  value={arrhythmia}
                  error={
                    errors?.txtArrhythmia ||
                    responseListError?.txtArrhythmia
                  }
                  label={"Arrhythmia"}
                  />
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={YesNoOptions.find((i) => i.id === arrhythmia)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Arrhythmia"}
                      placeholder={"Arrhythmia"}
                      error={isError ? true : false}
                      value={values?.txtArrhythmia || ""}

                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setArrhythmia(newValue.value);
                      handleDropdownChange("txtArrhythmia", newValue.value);
                    }
                  }}
                /> */}
              </MDBox>

              {arrhythmia === "yes" && (
                <>
                  <MDBox mb={3} width="100%">
                    <FormControl>
                      <FormLabel id="arrhythmia-type-radio-buttons-group-label" sx={{ fontSize: '14px' }}>Arrhythmia Type</FormLabel>
                      <RadioGroup
                        aria-labelledby="arrhythmia-type-radio-buttons-group-label"
                        defaultValue={selectedArrhythmiaType}
                        value={selectedArrhythmiaType}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          // setValue("txtArrhythmiaType", selectedValue);
                          setSelectedArrhythmiaType(selectedValue);

                          handleDropdownChange("txtArrhythmiaType", selectedValue);

                          // // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtArrhythmiaType: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }}
                        row // Set row to display radio buttons horizontally
                      >
                        {ArrhythmiaTypeOptions.map((option) => (
                          <FormControlLabel
                            key={option.id}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </MDBox>
                </>
              )}

              <MDBox mb={3} width="calc(33% - 10px)">

                <FormControl>
                  <FormLabel id="interval-radio-buttons-group-label" sx={{ fontSize: '14px' }}>CCS Classification</FormLabel>
                  <RadioGroup
                    aria-labelledby="interval-radio-buttons-group-label"
                    defaultValue={selectedCcsClassification}
                    value={selectedCcsClassification}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      //setValue("txtCcsClassification", selectedValue);
                      setSelectedCssClassification(selectedValue);

                      handleDropdownChange("txtCcsClassification", selectedValue);
                      // Update the form values stored in local storage
                      // setValues((prevValues) => {
                      //   const updatedValues = {
                      //     ...prevValues,
                      //     txtCcsClassification: selectedValue,
                      //   };

                      //   // Save the updated values to localStorage
                      //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                      //   // Return the updated values to update state
                      //   return updatedValues;
                      // });
                    }}
                    row // Set row to display radio buttons horizontally

                  >
                    <FormGroup row>
                      <FormControlLabel value="0" control={<Radio />} label="0" />
                      <FormControlLabel value="I" control={<Radio />} label="I" />
                      <FormControlLabel value="II" control={<Radio />} label="II" />
                      <FormControlLabel value="III" control={<Radio />} label="III" />
                      <FormControlLabel value="IV" control={<Radio />} label="IV" />
                    </FormGroup>
                  </RadioGroup>
                </FormControl>
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)">

                <FormControl>
                  <FormLabel id="interval-radio-buttons-group-label" sx={{ fontSize: '14px' }}>NYHA Classification</FormLabel>
                  <RadioGroup
                    aria-labelledby="interval-radio-buttons-group-label"
                    defaultValue={selectedNyhaClassification}
                    value={selectedNyhaClassification}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      // setValue("txtNyhaClassification", selectedValue);
                      setSelectedNyhaClassification(selectedValue);

                      handleDropdownChange("txtNyhaClassification", selectedValue);

                      // // Update the form values stored in local storage
                      // setValues((prevValues) => {
                      //   const updatedValues = {
                      //     ...prevValues,
                      //     txtNyhaClassification: selectedValue,
                      //   };

                      //   // Save the updated values to localStorage
                      //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                      //   // Return the updated values to update state
                      //   return updatedValues;
                      // });
                    }}
                    row // Set row to display radio buttons horizontally
                  >
                    <FormGroup row>
                      <FormControlLabel value="I" control={<Radio />} label="I" />
                      <FormControlLabel value="II" control={<Radio />} label="II" />
                      <FormControlLabel value="III" control={<Radio />} label="III" />
                      <FormControlLabel value="IV" control={<Radio />} label="IV" />
                    </FormGroup>
                  </RadioGroup>
                </FormControl>
              </MDBox>

            </MDBox>

            {/* G. Pre Operative Medications */}
            <Typography
              className="custom-heading"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              G. Pre Operative Medications
            </Typography>

            <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtDigitalis}
                  id={"txtDigitalis"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtDigitalis || ""}
                  error={errors?.txtDigitalis || responseListError?.txtDigitalis}
                  label={"Digitalis"}
                />
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtBetaBlockers}
                  id={"txtBetaBlockers"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtBetaBlockers || ""}
                  error={errors?.txtBetaBlockers || responseListError?.txtBetaBlockers}
                  label={"Beta Blockers"}
                />
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtNitratesIV}
                  id={"txtNitratesIV"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtNitratesIV || ""}
                  error={errors?.txtNitratesIV || responseListError?.txtNitratesIV}
                  label={"Nitrates - I.V."}
                />
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtAnticoagulants}
                  id={"txtAnticoagulants"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtAnticoagulants || ""}
                  error={errors?.txtAnticoagulants || responseListError?.txtAnticoagulants}
                  label={"Anticoagulants"}
                />
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtDiuretics}
                  id={"txtDiuretics"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtDiuretics || ""}
                  error={errors?.txtDiuretics || responseListError?.txtDiuretics}
                  label={"Diuretics"}
                />
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtInotropicAgents}
                  id={"txtInotropicAgents"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtInotropicAgents || ""}
                  error={errors?.txtInotropicAgents || responseListError?.txtInotropicAgents}
                  label={"Inotropic Agents"}
                />
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtSteroids}
                  id={"txtSteroids"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtSteroids || ""}
                  error={errors?.txtSteroids || responseListError?.txtSteroids}
                  label={"Steroids"}
                />
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtAspirin}
                  id={"txtAspirin"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtAspirin || ""}
                  error={errors?.txtAspirin || responseListError?.txtAspirin}
                  label={"Aspirin"}
                />
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtAceInhibitors}
                  id={"txtAceInhibitors"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtAceInhibitors || ""}
                  error={errors?.txtAceInhibitors || responseListError?.txtAceInhibitors}
                  label={"Ace Inhibitors"}
                />
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtOthAntiPlatelets}
                  id={"txtOthAntiPlatelets"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtOthAntiPlatelets || ""}
                  error={errors?.txtOthAntiPlatelets || responseListError?.txtOthAntiPlatelets}
                  label={"Other Anti-Platelets"}
                />
              </MDBox>

            </MDBox>
            {/* H. Pre Operative Hemodynamics and Cath */}
            <Typography
              className="custom-heading"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              H. Pre Operative Hemodynamics and Cath
            </Typography>

            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>

              {/* Number of Diseased Coronary Vessels */}

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={DiseasedCoronaryVesselsOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtDiseasedCoronaryVessels}
                  id={"txtDiseasedCoronaryVessels"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtDiseasedCoronaryVessels || ""}
                  error={errors?.txtDiseasedCoronaryVessels || responseListError?.txtDiseasedCoronaryVessels}
                  label={"Number of Diseased Coronary Vessels"}
                />
              </MDBox>

              {/* Left Main Disease > 50% */}

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtLeftMainDisease}
                  id={"txtLeftMainDisease"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtLeftMainDisease || ""}
                  error={errors?.txtLeftMainDisease || responseListError?.txtLeftMainDisease}
                  label={"Left Main Disease > 50%"}
                />
              </MDBox>

              {/* Ejection Fraction Done? */}

              <MDBox mb={3} width="calc(33% - 10px)">
              <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setEjectionType}
                  defaultValue={ejectionType}
                  id={"txtEjectionType"}
                  handleDropdownChange={handleDropdownChange}
                  value={ejectionType}
                  error={
                    errors?.txtEjectionType ||
                    responseListError?.txtEjectionType
                  }
                  label={"Ejection Fraction Done?"}
                  />
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={
                    YesNoOptions.find((i) => i.id === ejectionType)
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Ejection Fraction Done?"}
                      placeholder={"Ejection Fraction Done?"}
                      error={isError ? true : false}
                      value={ejectionType}

                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      //fetchData(newValue.value);
                      setEjectionType(newValue.value);
                      handleDropdownChange("txtEjectionType", newValue.value);
                    }
                  }}
                /> */}
              </MDBox>

              {/* If Ejection Fraction Done, show Ejection Fraction and Method */}
              {ejectionType === "yes" && (
                <>
                  <MDBox mb={3} width="calc(33% - 10px)">

                    <MDAutoComplete
                      options={EjectionFractionMethodOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={values?.txtEjectionFractionMethod}
                      id={"txtEjectionFractionMethod"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtEjectionFractionMethod || ""}
                      error={errors?.txtEjectionFractionMethod || responseListError?.txtEjectionFractionMethod}
                      label={"Ejection Fraction Method"}
                    />
                  </MDBox>
                </>
              )}

              {/* Pulmonary Artery Mean Pressure Done? */}

              <MDBox mb={3} width="calc(33% - 10px)">
              <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setPulmonary}
                  defaultValue={pulmonary}
                  id={"txtPulmonary"}
                  handleDropdownChange={handleDropdownChange}
                  value={pulmonary}
                  error={
                    errors?.txtPulmonary ||
                    responseListError?.txtPulmonary
                  }
                  label={"Pulmonary Artery Mean Pressure Done?"}
                  />
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={
                    YesNoOptions.find((i) => i.id === pulmonary)
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Pulmonary Artery Mean Pressure Done?"}
                      placeholder={"Pulmonary Artery Mean Pressure Done?"}
                      error={isError ? true : false}
                      value={values?.txtPulmonary || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      //fetchData(newValue.value);
                      setPulmonary(newValue.value);
                      handleDropdownChange("txtPulmonary", newValue.value);

                    }
                  }}
                /> */}
              </MDBox>

              {/* If Pulmonary Artery Mean Pressure Done, show Pulmonary Artery Mean Pressure */}

              {pulmonary === "yes" && (
                <>
                  <MDBox mb={3} width="calc(33% - 10px)">

                    <MDInput
                      type="text"
                      fullWidth
                      label="Pulmonary Artery Mean Pressure"
                      name="txtPulmonaryArteryMeanPressure"
                      register={register}
                      onChange={handleChange}
                      value={values?.txtPulmonaryArteryMeanPressure || ""}
                      error={errors?.txtPulmonaryArteryMeanPressure || responseListError?.txtPulmonaryArteryMeanPressure}
                    />
                  </MDBox>
                </>
              )}

              {/* Aortic Stenosis */}

              <MDBox mb={3} width="calc(33% - 10px)">
              <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setAorticStenosis}
                  defaultValue={aorticStenosis}
                  id={"txtAorticStenosis"}
                  handleDropdownChange={handleDropdownChange}
                  value={aorticStenosis}
                  error={
                    errors?.txtAorticStenosis ||
                    responseListError?.txtAorticStenosis
                  }
                  label={"Aortic Stenosis"}
                  />
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={
                    YesNoOptions.find((i) => i.id === aorticStenosis)
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Aortic Stenosis"}
                      placeholder={"Aortic Stenosis"}
                      error={isError ? true : false}
                      value={values?.txtAorticStenosis || ""}

                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      //fetchData(newValue.value);
                      setAorticStenosis(newValue.value);
                      handleDropdownChange("txtAorticStenosis", newValue.value);

                    }
                  }}
                /> */}
              </MDBox>

              {/* If Aortic Stenosis is present, show the relevant fields */}
              {aorticStenosis === "yes" && (
                <>
                  <MDBox mb={3} width="calc(33% - 10px)">

                    <MDInput
                      type="text"
                      fullWidth
                      label="Gradient"
                      name="txtGradient"
                      register={register}
                      onChange={handleChange}
                      value={values?.txtGradient || ""}
                      error={errors?.txtGradient || responseListError?.txtGradient}
                    />
                  </MDBox>

                  {/* Aortic Insufficiency */}
                  <MDBox mb={3} width="100%">
                    <FormControl component="fieldset">
                      <FormLabel component="legend" sx={{ fontSize: '14px' }}
                      >Aortic Insufficiency</FormLabel>
                      <RadioGroup
                        aria-label="aorticInsufficiency"
                        name="op"
                        defaultValue={selectedAorticInsufficiency}
                        value={selectedAorticInsufficiency}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          // setValue("txtAorticInsufficiency", selectedValue);
                          setSelectedAorticInsufficiency(selectedValue);

                          handleDropdownChange("txtAorticInsufficiency", selectedValue);
                          // // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtAorticInsufficiency: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }} row // Set row to display radio buttons horizontally
                      >
                        <FormControlLabel value="0" control={<Radio />} label="None" />
                        <FormControlLabel value="1" control={<Radio />} label="Trivial" />
                        <FormControlLabel value="2" control={<Radio />} label="Mild" />
                        <FormControlLabel value="3" control={<Radio />} label="Moderate" />
                        <FormControlLabel value="4" control={<Radio />} label="Severe" />
                      </RadioGroup>
                    </FormControl>
                  </MDBox>

                  {/* Mitral Insufficiency */}

                  <MDBox mb={3} width="100%">
                    <FormControl component="fieldset">
                      <FormLabel component="legend" sx={{ fontSize: '14px' }}
                      >Mitral  Insufficiency</FormLabel>
                      <RadioGroup
                        aria-label="txtMitralInsufficiency"
                        name="txtMitralInsufficiency"
                        defaultValue={selectedMitralInsufficiency}
                        value={selectedMitralInsufficiency}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          //setValue("txtMitralInsufficiency", selectedValue);
                          setSelectedMitralInsufficiency(selectedValue);

                          handleDropdownChange("txtMitralInsufficiency", selectedValue);
                          // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtMitralInsufficiency: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }} row // Set row to display radio buttons horizontally
                      >
                        <FormControlLabel value="0" control={<Radio />} label="None" />
                        <FormControlLabel value="1" control={<Radio />} label="Trivial" />
                        <FormControlLabel value="2" control={<Radio />} label="Mild" />
                        <FormControlLabel value="3" control={<Radio />} label="Moderate" />
                        <FormControlLabel value="4" control={<Radio />} label="Severe" />
                      </RadioGroup>
                    </FormControl>
                  </MDBox>

                  {/* Tricuspid Insufficiency */}

                  <MDBox mb={3} width="100%">
                    <FormControl component="fieldset">
                      <FormLabel component="legend" sx={{ fontSize: '14px' }}
                      >Tricuspid  Insufficiency</FormLabel>
                      <RadioGroup
                        aria-label="txtTricuspidInsufficiency"
                        name="txtTricuspidInsufficiency"
                        defaultValue={selectedTricuspidInsufficiency}
                        value={selectedTricuspidInsufficiency}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          // setValue("txtTricuspidInsufficiency", selectedValue);
                          setSelectedTricuspidInsufficiency(selectedValue);

                          handleDropdownChange("txtTricuspidInsufficiency", selectedValue);

                          // // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtTricuspidInsufficiency: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }}
                        row // Set row to display radio buttons horizontally
                      >
                        <FormControlLabel value="0" control={<Radio />} label="None" />
                        <FormControlLabel value="1" control={<Radio />} label="Trivial" />
                        <FormControlLabel value="2" control={<Radio />} label="Mild" />
                        <FormControlLabel value="3" control={<Radio />} label="Moderate" />
                        <FormControlLabel value="4" control={<Radio />} label="Severe" />
                      </RadioGroup>
                    </FormControl>
                  </MDBox>

                  {/* Pulmonic Insufficiency */}

                  <MDBox mb={3} width="100%">
                    <FormControl component="fieldset">
                      <FormLabel component="legend" sx={{ fontSize: '14px' }}
                      >Pulmonic Insufficiency</FormLabel>
                      <RadioGroup
                        aria-label="txtPulmonicInsufficiency"
                        name="txtPulmonicInsufficiency"
                        defaultValue={selectedPulmonicInsufficiency}
                        value={selectedPulmonicInsufficiency}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          // setValue("txtPulmonicInsufficiency", selectedValue);
                          setSelectedPulmonicInsufficiency(selectedValue);

                          handleDropdownChange("txtPulmonicInsufficiency", selectedValue);

                          // // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtPulmonicInsufficiency: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem("formData", JSON.stringify(updatedValues));

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }}
                        row // Set row to display radio buttons horizontally
                      >
                        <FormControlLabel value="0" control={<Radio />} label="None" />
                        <FormControlLabel value="1" control={<Radio />} label="Trivial" />
                        <FormControlLabel value="2" control={<Radio />} label="Mild" />
                        <FormControlLabel value="3" control={<Radio />} label="Moderate" />
                        <FormControlLabel value="4" control={<Radio />} label="Severe" />
                      </RadioGroup>
                    </FormControl>
                  </MDBox>

                </>
              )}

              {/* Mitral Stenosis */}

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtMitralStenosis}
                  id={"txtMitralStenosis"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtMitralStenosis || ""}
                  error={errors?.txtMitralStenosis || responseListError?.txtMitralStenosis}
                  label={"Mitral Stenosis"}
                />
              </MDBox>

              {/* Tricuspid Stenosis */}

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtTricuspidStenosis}
                  id={"txtTricuspidStenosis"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtTricuspidStenosis || ""}
                  error={errors?.txtTricuspidStenosis || responseListError?.txtTricuspidStenosis}
                  label={"Tricuspid Stenosis"}
                />
              </MDBox>

              {/* Pulmonic Stenosis */}

              <MDBox mb={3} width="calc(33% - 10px)">

                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtPulmonicStenosis}
                  id={"txtPulmonicStenosis"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtPulmonicStenosis || ""}
                  error={errors?.txtPulmonicStenosis || responseListError?.txtPulmonicStenosis}
                  label={"Pulmonic Stenosis"}
                />
              </MDBox>

            </div>

            <Typography className="invalid-feedback">
              {responseError}
            </Typography>

            {/* Popup component */}
            {showPopup && (
              <Popup
                message="Do you want to fill 'Postoperative Data Form'?"
                onResponse={handlePopupResponse}
              />
            )}
            <MDBox display="flex" flexDirection="column" mb={3}>
              {!isBtnLoading ? (
                <MDBox mt={4} display="flex" justifyContent="end">
                  <MDButton variant="gradient" color="info" type="submit">
                    submit
                  </MDButton>
                </MDBox>
              ) : (
                <MDBox mt={4} display="flex" justifyContent="end">
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                </MDBox>
              )}
            </MDBox>
          </form>
          {/* {isBtnLoading && <p>Data Updated Successfully...</p>} */}

        </Card>
      </Box>
      <Footer responseRef={responseRef} />
    </DashboardLayout>
  );
};

export default PreOperativeForm;
