
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components

import Fade from "@mui/material/Fade";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Tab, Tabs } from "@mui/material";

function MDTabs({ value, handleChange }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <MDBox sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs value={value} onChange={handleChange}>
        <Tab className={`tab-button`} key={1} label="List of Patients" {...a11yProps(0)} />
        <Tab
          className={`tab-button`}
          key={2}
          label="Add New Patient"
          {...a11yProps(1)}
        />
      </Tabs>
    </MDBox>
  );
}

// Setting default values for the props of MDTabs
MDTabs.defaultProps = {
  bgWhite: false,
  color: "info",
};

// Typechecking props for MDTabs
MDTabs.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),

  bgWhite: PropTypes.bool,
};

export default MDTabs;
