import { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Material Dashboard 2 React components
import Popup from "PopUp/popup";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { TextField, Autocomplete } from "@mui/material";
import MDTabs from "components/MDTabs/MDTabs";
import { MDAutoComplete } from "components/MDAutoComplete";
import { Button, Card, CircularProgress, Grid } from "@mui/material";

// Yup Validation imports
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import validationParams from "validationParams";

//Radio group
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Datepicker import
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

// API Import
import API from "APIUrls";
import APIService from "services/api-service";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function CustomAccordionPanel(props) {
  const { children, summary, expanded, onChange } = props;

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

CustomAccordionPanel.propTypes = {
  children: PropTypes.node,
  summary: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const PostoperativeDataForm = () => {

  const { patientId } = useParams();

  const [startTime, setStartTime] = useState(dayjs());
  const [stopTime, setStopTime] = useState(dayjs());
  const [clampTime, setClampTime] = useState(dayjs());
  const [perfusionTime, setPerfusionTime] = useState(dayjs());

  const [values, setValues] = useState(defaultValues);
  const [responseError, setResponseError] = useState(null);
  const [responseListError, setResponseListError] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [arteryBypass, setArteryBypass] = useState("");
  const [otherCardiacProcedure, setOtherCardiacProcedure] = useState("");
  const [otherNonCardiacProcedure, setOtherNonCardiacProcedure] = useState("");
  const [cardiopulmonaryBypassUsed, setCardioPulmonaryUsed] = useState("");
  const [coversionToStndIncision, setCoversionToStndIncision] = useState("");
  const [iabp, setIabp] = useState("");
  const [reintubated, setReintubated] = useState("");
  const [complications, setComplications] = useState("");
  const [selectedIMA, setSelectedIMA] = useState("leftIMA");
  const [selectedRadialArteryUsed, setSelectedRadialArteryUsed] = useState("noRadial");
  const [selectedMinimallyInvasive, setSelectedMinimallyInvasive] = useState("surgPatChoice");
  const [selectedIndication, setSelectedIndication] = useState("exposure");
  const [selectedAorticOcclusionM, setSelectedAorticOcclusion] = useState("none");
  const [selectedSutureTechnique, setSelectedSutureTechnique] = useState("running");
  const [selectedVesselStabTech, setSelectedVesselStabTech] = useState("none");
  const [selectedIMAHarvestTechn, setSelectedIMAHarvestTechn] = useState("none");
  const [selectedFlowPatency, setSelectedFlowPatency] = useState("none");
  const [selectedWhenInserted, setSelectedWhenInserted] = useState("preop");
  const [selectedCannulation, setSelectedCannulation] = useState("aortaFemJugVein");
  const [selectedIabpIndication, setSelectedIabpIndication] = useState("hemodynamicInstab");

  const responseRef = useRef({
    responseFlag: false,
    responseCode: 200,
    responseMessage: "",
  });

  const YesNoOptions = [
    { id: "yes", value: "yes", label: "Yes", firstLetter: "Y" },
    { id: "no", value: "no", label: "No", firstLetter: "N" },
  ];

  const reasonsOptions = [
    {
      id: "shockCircSupp",
      value: "Shock Circ Supp",
      label: "Shock Circ Supp",
      firstLetter: "S",
    },
    {
      id: "shockNoCircSupp",
      value: "Shock No Circ Supp",
      label: "Shock No Circ Supp",
      firstLetter: "S",
    },
    {
      id: "pulmEdema",
      value: "Pulm Edema",
      label: "Pulm Edema",
      firstLetter: "P",
    },
    { id: "aemi", value: "AEMI", label: "AEMI", firstLetter: "A" },
    {
      id: "ongoingIschemia",
      value: "Ongoing Ischemia",
      label: "Ongoing Ischemia",
      firstLetter: "O",
    },
    {
      id: "valveDysfnctn",
      value: "Valve Dysfnctn",
      label: "Valve Dysfnctn",
      firstLetter: "V",
    },
    {
      id: "aorticDissection",
      value: "Aortic Dissection",
      label: "Aortic Dissection",
      firstLetter: "A",
    },
  ];

  const electiveReasonsOptions = [
    { id: "ami", value: "AMI", label: "AMI", firstLetter: "A" },
    { id: "iabp", value: "IABP", label: "IABP", firstLetter: "I" },
    {
      id: "worseningCP",
      value: "Worsening CP",
      label: "Worsening CP",
      firstLetter: "W",
    },
    { id: "chf", value: "CHF", label: "CHF", firstLetter: "C" },
    { id: "anatomy", value: "Anatomy", label: "Anatomy", firstLetter: "A" },
    { id: "usa", value: "USA", label: "USA", firstLetter: "U" },
    {
      id: "restAngina",
      value: "Rest Angina",
      label: "Rest Angina",
      firstLetter: "R",
    },
    {
      id: "valveDysfunction",
      value: "Valve Dysfunction",
      label: "Valve Dysfunction",
      firstLetter: "V",
    },
    {
      id: "aorticDissection",
      value: "Aortic Dissection",
      label: "Aortic Dissection",
      firstLetter: "A",
    },
  ];

  const aorticOptions = [
    { id: "No", value: "No", label: "No", firstLetter: "N" },
    {
      id: "Replacement",
      value: "Replacement",
      label: "Replacement",
      firstLetter: "R",
    },
    {
      id: "RepairReconstruction",
      value: "Repair/Reconstruction",
      label: "Repair/Reconstruction",
      firstLetter: "R",
    },
    {
      id: "RootReconstruction",
      value: "Root Reconstruction",
      label: "Root Reconstruction",
      firstLetter: "R",
    },
    {
      id: "ReconstructionSparing",
      value: "Reconstruction w/ Valve Sparing",
      label: "Reconstruction w/ Valve Sparing",
      firstLetter: "R",
    },
    {
      id: "Resuspension",
      value: "Resuspension",
      label: "Resuspension",
      firstLetter: "R",
    },
    {
      id: "ResectionSubAorticStenosis",
      value: "Resection Sub-Aortic Stenosis",
      label: "Resection Sub-Aortic Stenosis",
      firstLetter: "R",
    },
  ];

  const mitralOptions = [
    { id: "No", value: "No", label: "No", firstLetter: "N" },
    {
      id: "AnnuloplastyOnly",
      value: "Annuloplasty only",
      label: "Annuloplasty only",
      firstLetter: "A",
    },
    {
      id: "Replacement",
      value: "Replacement",
      label: "Replacement",
      firstLetter: "R",
    },
    {
      id: "ReconstructionWithAnnuloplasty",
      value: "Reconstruction w/ Annuloplasty",
      label: "Reconstruction w/ Annuloplasty",
      firstLetter: "R",
    },
    {
      id: "ReconstructionWithoutAnnuloplasty",
      value: "Reconstruction w/out Annuloplasty",
      label: "Reconstruction w/out Annuloplasty",
      firstLetter: "R",
    },
  ];

  const tricuspidOptions = [
    { id: "No", value: "No", label: "No", firstLetter: "N" },
    {
      id: "AnnuloplastyOnly",
      value: "Annuloplasty only",
      label: "Annuloplasty only",
      firstLetter: "A",
    },
    {
      id: "Replacement",
      value: "Replacement",
      label: "Replacement",
      firstLetter: "R",
    },
    {
      id: "ReconstructionWithAnnuloplasty",
      value: "Reconstruction w/ Annuloplasty",
      label: "Reconstruction w/ Annuloplasty",
      firstLetter: "R",
    },
    {
      id: "ReconstructionWithoutAnnuloplasty",
      value: "Reconstruction w/out Annuloplasty",
      label: "Reconstruction w/out Annuloplasty",
      firstLetter: "R",
    },
    {
      id: "Valvectomy",
      value: "Valvectomy",
      label: "Valvectomy",
      firstLetter: "V",
    },
  ];

  const pulmonicOptions = [
    { id: "No", value: "No", label: "No", firstLetter: "N" },
    {
      id: "Replacement",
      value: "Replacement",
      label: "Replacement",
      firstLetter: "R",
    },
    {
      id: "Reconstruction",
      value: "Reconstruction",
      label: "Reconstruction",
      firstLetter: "R",
    },
  ];

  const imaOptions = [
    { id: "leftIMA", value: "Left IMA", label: "Left IMA", firstLetter: "L" },
    {
      id: "rightIMA",
      value: "Right IMA",
      label: "Right IMA",
      firstLetter: "R",
    },
    {
      id: "bothIMAs",
      value: "Both IMAs",
      label: "Both IMAs",
      firstLetter: "B",
    },
    { id: "noIMA", value: "No IMA", label: "No IMA", firstLetter: "N" },
  ];

  const radialArteryOptions = [
    {
      id: "noRadial",
      value: "No Radial",
      label: "No Radial",
      firstLetter: "N",
    },
    {
      id: "leftRadial",
      value: "Left Radial",
      label: "Left Radial",
      firstLetter: "L",
    },
    {
      id: "rightRadial",
      value: "Right Radial",
      label: "Right Radial",
      firstLetter: "R",
    },
    {
      id: "bothRadials",
      value: "Both Radials",
      label: "Both Radials",
      firstLetter: "B",
    },
  ];

  const valveOptions = [
    { id: "none", value: "None", label: "None", firstLetter: "N" },
    { id: "mechanical", value: "M", label: "Mechanical", firstLetter: "M" },
    {
      id: "bioprosthesis",
      value: "B",
      label: "Bioprosthesis",
      firstLetter: "B",
    },
    { id: "homograft", value: "H", label: "Homograft", firstLetter: "H" },
    { id: "autograft", value: "A", label: "Autograft", firstLetter: "A" },
    { id: "ring", value: "R", label: "Ring", firstLetter: "R" },
  ];

  const minimallyInvasiveOptions = [
    {
      id: "surgPatChoice",
      value: "Surgical/Patient Choice",
      label: "Surgical/Patient Choice",
      firstLetter: "S",
    },
    {
      id: "contraindicatedStdApproach",
      value: "Contraindicated Standard Approach",
      label: "Contraindicated Standard Approach",
      firstLetter: "C",
    },
    {
      id: "combCathIntervention",
      value: "Combined Catheter Intervention",
      label: "Combined Catheter Intervention",
      firstLetter: "C",
    },
  ];

  const primaryIncisionOptions = [
    {
      id: "fullSternotomy",
      value: "Full Sternotomy",
      label: "Full Sternotomy",
      firstLetter: "F",
    },
    {
      id: "partialSternotomy",
      value: "Partial Sternotomy",
      label: "Partial Sternotomy",
      firstLetter: "P",
    },
    {
      id: "transverseSternotomy",
      value: "Transverse Sternotomy",
      label: "Transverse Sternotomy",
      firstLetter: "T",
    },
    {
      id: "rightVerticalParasternal",
      value: "Right Vertical Parasternal",
      label: "Right Vertical Parasternal",
      firstLetter: "R",
    },
    {
      id: "leftVerticalParasternal",
      value: "Left Vertical Parasternal",
      label: "Left Vertical Parasternal",
      firstLetter: "L",
    },
    {
      id: "rightAnteriorThoracotomy",
      value: "Right Anterior Thoracotomy",
      label: "Right Anterior Thoracotomy",
      firstLetter: "R",
    },
    {
      id: "leftAnteriorThoracotomy",
      value: "Left Anterior Thoracotomy",
      label: "Left Anterior Thoracotomy",
      firstLetter: "L",
    },
    {
      id: "posterolateralThoracotomy",
      value: "Posterolateral Thoracotomy",
      label: "Posterolateral Thoracotomy",
      firstLetter: "P",
    },
    {
      id: "xiphoidEpigastric",
      value: "Xiphoid Epigastric",
      label: "Xiphoid Epigastric",
      firstLetter: "X",
    },
    {
      id: "subcostal",
      value: "Subcostal",
      label: "Subcostal",
      firstLetter: "S",
    },
  ];

  const indicationOptions = [
    { id: "exposure", value: "Exposure", label: "Exposure", firstLetter: "E" },
    { id: "bleeding", value: "Bleeding", label: "Bleeding", firstLetter: "B" },
    { id: "rhythm", value: "Rhythm", label: "Rhythm", firstLetter: "R" },
    {
      id: "hypotension",
      value: "Hypotension",
      label: "Hypotension",
      firstLetter: "H",
    },
    { id: "conduit", value: "Conduit", label: "Conduit", firstLetter: "C" },
  ];

  const cannulationMethodOptions = [
    {
      id: "aortaFemJugVein",
      value: "Aorta and Fem/Jug Vein",
      label: "Aorta and Fem/Jug Vein",
      firstLetter: "A",
    },
    {
      id: "femArtFemJugVein",
      value: "Fem Art and Fem/Jug Vein",
      label: "Fem Art and Fem/Jug Vein",
      firstLetter: "F",
    },
    {
      id: "aortaAtrialCaval",
      value: "Aorta and Atrial/Caval",
      label: "Aorta and Atrial/Caval",
      firstLetter: "A",
    },
    {
      id: "femArtAtrialCaval",
      value: "Fem Art and Atrial/Caval",
      label: "Fem Art and Atrial/Caval",
      firstLetter: "F",
    },
    {
      id: "otherCannulation",
      value: "Other",
      label: "Other",
      firstLetter: "O",
    },
  ];

  const aorticOcclusionMethodOptions = [
    { id: "none", value: "None", label: "None", firstLetter: "N" },
    {
      id: "crossClamp",
      value: "Cross-clamp",
      label: "Cross-clamp",
      firstLetter: "C",
    },
    {
      id: "balloonOcclusion",
      value: "Balloon Occlusion",
      label: "Balloon Occlusion",
      firstLetter: "B",
    },
  ];

  const sutureTechniqueOptions = [
    { id: "running", value: "Running", label: "Running", firstLetter: "R" },
    {
      id: "interrupted",
      value: "Interrupted",
      label: "Interrupted",
      firstLetter: "I",
    },
    { id: "stapler", value: "Stapler", label: "Stapler", firstLetter: "S" },
    {
      id: "combination",
      value: "Combination",
      label: "Combination",
      firstLetter: "C",
    },
  ];

  const vesselStabilizationTechniqueOptions = [
    { id: "none", value: "None", label: "None", firstLetter: "N" },
    {
      id: "sutureSnare",
      value: "Suture Snare",
      label: "Suture Snare",
      firstLetter: "S",
    },
    {
      id: "suctionDevice",
      value: "Suction Device",
      label: "Suction Device",
      firstLetter: "D",
    },
    {
      id: "compression",
      value: "Compression",
      label: "Compression",
      firstLetter: "C",
    },
    {
      id: "otherVesselStabilization",
      value: "Other",
      label: "Other",
      firstLetter: "O",
    },
  ];

  const imaHarvestTechniqueOptions = [
    { id: "none", value: "None", label: "None", firstLetter: "N" },
    {
      id: "directVision",
      value: "Direct Vision",
      label: "Direct Vision",
      firstLetter: "D",
    },
    {
      id: "thoracoscopy",
      value: "Thoracoscopy",
      label: "Thoracoscopy",
      firstLetter: "T",
    },
    {
      id: "combinationIMA",
      value: "Combination",
      label: "Combination",
      firstLetter: "C",
    },
  ];

  const acuteFlowPatencyOptions = [
    { id: "none", value: "None", label: "None", firstLetter: "N" },
    {
      id: "intraOpDoppler",
      value: "IntraOp Doppler",
      label: "IntraOp Doppler",
      firstLetter: "I",
    },
    {
      id: "intraOpAngio",
      value: "IntraOp Angio",
      label: "IntraOp Angio",
      firstLetter: "A",
    },
    {
      id: "postOpAngio",
      value: "PostOp Angio",
      label: "PostOp Angio",
      firstLetter: "P",
    },
    {
      id: "postOpDoppler",
      value: "PostOp Doppler",
      label: "PostOp Doppler",
      firstLetter: "D",
    },
  ];

  const otherCardiacProceduresOptions = [
    { id: "noOtherProcedure", value: "No", label: "No", firstLetter: "N" },
    { id: "yesOtherProcedure", value: "Yes", label: "Yes", firstLetter: "Y" },
    {
      id: "leftVentricularAneurysmRepair",
      value: "Left Ventricular Aneurysm Repair",
      label: "Left Ventricular Aneurysm Repair",
      firstLetter: "L",
    },
    {
      id: "ventSeptalDefectRepair",
      value: "Vent Septal Defect Repair",
      label: "Vent Septal Defect Repair",
      firstLetter: "V",
    },
    {
      id: "atrialSeptalDefectRepair",
      value: "Atrial Septal Defect Repair",
      label: "Atrial Septal Defect Repair",
      firstLetter: "A",
    },
    { id: "batista", value: "Batista", label: "Batista", firstLetter: "B" },
    { id: "svr", value: "SVR", label: "SVR", firstLetter: "S" },
    {
      id: "congenitalDefectRepair",
      value: "Congenital Defect Repair",
      label: "Congenital Defect Repair",
      firstLetter: "C",
    },
    {
      id: "transmyocardLaserRevasc",
      value: "Transmyocard Laser Revasc",
      label: "Transmyocard Laser Revasc",
      firstLetter: "T",
    },
    {
      id: "cardiacTrauma",
      value: "Cardiac Trauma",
      label: "Cardiac Trauma",
      firstLetter: "C",
    },
    {
      id: "cardiacTransplant",
      value: "Cardiac Transplant",
      label: "Cardiac Transplant",
      firstLetter: "C",
    },
    {
      id: "permanentPacemaker",
      value: "Permanent Pacemaker",
      label: "Permanent Pacemaker",
      firstLetter: "P",
    },
    { id: "aicd", value: "AICD", label: "AICD", firstLetter: "A" },
    {
      id: "otherCardiacProcedure",
      value: "Other",
      label: "Other",
      firstLetter: "O",
    },
  ];

  const otherNonCardiacProceduresOptions = [
    {
      id: "noOtherNonCardiacProcedure",
      value: "No",
      label: "No",
      firstLetter: "N",
    },
    {
      id: "yesOtherNonCardiacProcedure",
      value: "Yes",
      label: "Yes",
      firstLetter: "Y",
    },
    {
      id: "aorticAneurysm",
      value: "Aortic Aneurysm",
      label: "Aortic Aneurysm",
      firstLetter: "A",
    },
    {
      id: "carotidEndarterectomy",
      value: "Carotid Endarterectomy",
      label: "Carotid Endarterectomy",
      firstLetter: "C",
    },
    {
      id: "otherVascular",
      value: "Other Vascular",
      label: "Other Vascular",
      firstLetter: "O",
    },
    {
      id: "otherThoracic",
      value: "Other Thoracic",
      label: "Other Thoracic",
      firstLetter: "O",
    },
  ];

  const whenInsertedOptions = [
    { id: "preop", value: "Preop", label: "Preoperative", firstLetter: "P" },
    {
      id: "intraop",
      value: "Intraop",
      label: "Intraoperative",
      firstLetter: "I",
    },
    { id: "postop", value: "Postop", label: "Postoperative", firstLetter: "P" },
  ];

  const iabpIndicationOptions = [
    {
      id: "hemodynamicInstab",
      value: "HemodynamicInstab",
      label: "Hemodynamic Instability",
      firstLetter: "H",
    },
    {
      id: "ptcaSupport",
      value: "PTCASupport",
      label: "PTCA Support",
      firstLetter: "P",
    },
    {
      id: "unstAngina",
      value: "UnstAngina",
      label: "Unstable Angina",
      firstLetter: "U",
    },
    { id: "cpbWean", value: "CPBWean", label: "CPB Weaning", firstLetter: "C" },
    {
      id: "prophylactic",
      value: "Prophylactic",
      label: "Prophylactic",
      firstLetter: "P",
    },
  ];

  const OperativeOptions = [
    {
      id: "ReOpBleedingTamponade",
      value: "ReOpBleedingTamponade",
      label: "ReOp for Bleeding/Tamponade",
      firstLetter: "R",
    },
    {
      id: "InfectionSternumDeep",
      value: "InfectionSternumDeep",
      label: "Infection - Sternum Deep",
      firstLetter: "I",
    },
    {
      id: "ReOpValvularDysfunction",
      value: "ReOpValvularDysfunction",
      label: "ReOp for Valvular Dysfunction",
      firstLetter: "R",
    },
    {
      id: "ReOpGraftOcclusion",
      value: "ReOpGraftOcclusion",
      label: "ReOp for Graft Occlusion",
      firstLetter: "R",
    },
    {
      id: "ReOpOtherCardiacProblem",
      value: "ReOpOtherCardiacProblem",
      label: "ReOp for Other Cardiac Problem",
      firstLetter: "R",
    },
    {
      id: "ReOpOtherNonCardiacProblem",
      value: "ReOpOtherNonCardiacProblem",
      label: "ReOp for Other Non Cardiac Problem",
      firstLetter: "R",
    },
    {
      id: "PerioperativeMyocardialInfarction",
      value: "PerioperativeMyocardialInfarction",
      label: "Perioperative Myocardial Infarction",
      firstLetter: "P",
    },
    { id: "yes", value: "yes", label: "Yes", firstLetter: "Y" },
    { id: "no", value: "no", label: "No", firstLetter: "N" },
  ];

  const InfectionOptions = [
    {
      id: "SternumDeep",
      value: "SternumDeep",
      label: "Sternum Deep",
      firstLetter: "S",
    },
    {
      id: "Thoracotomy",
      value: "Thoracotomy",
      label: "Thoracotomy",
      firstLetter: "T",
    },
    { id: "Leg", value: "Leg", label: "Leg", firstLetter: "L" },
    {
      id: "Septicemia",
      value: "Septicemia",
      label: "Septicemia",
      firstLetter: "S",
    },
    {
      id: "UrinaryTractInfection",
      value: "UrinaryTractInfection",
      label: "Urinary Tract Infection",
      firstLetter: "U",
    },
    { id: "yes", value: "yes", label: "Yes", firstLetter: "Y" },
    { id: "no", value: "no", label: "No", firstLetter: "N" },
  ];

  const neurologicOptions = [
    { id: "Stroke", value: "Stroke", label: "Stroke", firstLetter: "S" },
    {
      id: "Transient",
      value: "Transient",
      label: "Transient",
      firstLetter: "T",
    },
    {
      id: "ContinuousComa",
      value: "ContinuousComa",
      label: "Continuous Coma >=24Hrs",
      firstLetter: "C",
    },
    { id: "yes", value: "yes", label: "Yes", firstLetter: "Y" },
    { id: "no", value: "no", label: "No", firstLetter: "N" },
  ];

  const pulmonaryOptions = [
    {
      id: "ProlongedVentilation",
      value: "ProlongedVentilation",
      label: "Prolonged Ventilation",
      firstLetter: "P",
    },
    {
      id: "PulmonaryEmbolism",
      value: "PulmonaryEmbolism",
      label: "Pulmonary Embolism",
      firstLetter: "P",
    },
    {
      id: "Pneumonia",
      value: "Pneumonia",
      label: "Pneumonia",
      firstLetter: "P",
    },
    { id: "yes", value: "yes", label: "Yes", firstLetter: "Y" },
    { id: "no", value: "no", label: "No", firstLetter: "N" },
  ];

  const vascularOptions = [
    {
      id: "VascularAorticDissection",
      value: "VascularAorticDissection",
      label: "Vascular - Aortic Dissection",
      firstLetter: "V",
    },
    {
      id: "IlliacFemoral",
      value: "IlliacFemoral",
      label: "Illiac/Femoral",
      firstLetter: "I",
    },
    {
      id: "AcuteLimbIschemia",
      value: "AcuteLimbIschemia",
      label: "Acute Limb Ischemia",
      firstLetter: "A",
    },
    { id: "yes", value: "yes", label: "Yes", firstLetter: "Y" },
    { id: "no", value: "no", label: "No", firstLetter: "N" },
  ];

  const renalOptions = [
    {
      id: "RenalFailure",
      value: "RenalFailure",
      label: "Renal Failure",
      firstLetter: "R",
    },
    { id: "Dialysis", value: "Dialysis", label: "Dialysis", firstLetter: "D" },
    { id: "yes", value: "yes", label: "Yes", firstLetter: "Y" },
    { id: "no", value: "no", label: "No", firstLetter: "N" },
  ];

  const otherOptions = [
    {
      id: "HeartBlock",
      value: "HeartBlock",
      label: "Heart Block",
      firstLetter: "H",
    },
    {
      id: "CardiacArrest",
      value: "CardiacArrest",
      label: "Cardiac Arrest",
      firstLetter: "C",
    },
    {
      id: "GastroIntestinalComplication",
      value: "GastroIntestinalComplication",
      label: "Gastro-Intestinal Complication",
      firstLetter: "G",
    },
    {
      id: "AnticoagulantComplication",
      value: "AnticoagulantComplication",
      label: "Anticoagulant Complication",
      firstLetter: "A",
    },
    {
      id: "MultiSystemFailureComplication",
      value: "MultiSystemFailureComplication",
      label: "Multi-System Failure Complication",
      firstLetter: "M",
    },
    {
      id: "Tamponade",
      value: "Tamponade",
      label: "Tamponade",
      firstLetter: "T",
    },
    {
      id: "AtrialFibrillation",
      value: "AtrialFibrillation",
      label: "Atrial Fibrillation",
      firstLetter: "A",
    },
    { id: "yes", value: "yes", label: "Yes", firstLetter: "Y" },
    { id: "no", value: "no", label: "No", firstLetter: "N" },
  ];

  const validationSchema = Yup.object().shape({

  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm({ resolver: yupResolver(validationSchema) });


  useEffect(() => {
    const savedFormData = localStorage.getItem("formData");
    if (savedFormData) {
      // console.log(savedFormData);
      const parsedFormData = JSON.parse(savedFormData);
      // console.log(parsedFormData, "data");
      setValues(parsedFormData);
    }
  }, []);

  var defaultValues = {
    txtSurgeonName: "",
    txtSurgeonGroup: "",
    txtEmergentReason: "",
    txtUrgentElective: "",
    txtArteryBypass: "",
    txtAortic: "",
    txtMitral: "",
    txtTricuspid: "",
    txtPulmonic: "",
    txtOtherCardiacProc: "",
    txtNonOtherCardiacProc: "",
    txtUnplannedCABG: "",
    txtDistalAnastomosesArterial: "",
    txtDistalAnastomosesVein: "",
    txtIMAsUsed: "",
    txtRadialArteryUsed: "",
    txtIMADistalAnastomoses: "",
    txtRadialArteryDistalAnastomoses: "",
    txtGastroEpiploicArteryDistalAnastomoses: "",
    txtAorticImplantType: "",
    txtAorticImplant: "",
    txtAorticImplantSize: "",
    txtAorticExplantType: "",
    txtAorticExplant: "",
    txtAorticExplantSize: "",
    txtMitralImplantType: "",
    txtMitralImplant: "",
    txtMitralImplantSize: "",
    txtMitralExplantType: "",
    txtMitralExplant: "",
    txtMitralExplantSize: "",
    txtTricuspidImplantType: "",
    txtTricuspidImplant: "",
    txtTricuspidImplantSize: "",
    txtTricuspidExplantType: "",
    txtTricuspidExplant: "",
    txtTricuspidExplantSize: "",
    txtPulmonicImplantType: "",
    txtPulmonicImplant: "",
    txtPulmonicImplantSize: "",
    txtPulmonicExplantType: "",
    txtPulmonicExplant: "",
    txtPulmonicExplantSize: "",
    txtCardioPulmonaryUsed: "",
    txtConversionToCPB: "",
    txtMinimallyInvasive: "",
    txtPrimaryIncision: "",
    txtTotalNumberOfIncisions: "",
    txtCoversionToStndIncision: "",
    txtIndication: "",
    txtCannulationMethod: "",
    txtAorticOcclusionMethod: "",
    txtIntracoronaryShuntUsed: "",
    txtSutureTechnique: "",
    txtVesselStabTech: "",
    txtIMAHarvestTechn: "",
    txtFlowPatency: "",
    txtOtherCardiacProcedures: "",
    txtOtherNonCardiacProcedures: "",
    txtStartTime: "",
    txtStopTime: "",
    txtClampTime: "",
    txtPerfusionTime: "",
    txtCardioplegia: "",
    txtIABP: "",
    txtWhenInserted: "",
    txtIabpIndication: "",
    txtVentricularAssistDevice: "",
    txtBloodProdUsed: "",
    txtInitialHoursVentilatedPostop: "",
    txtReintubated: "",
    txtTotalHoursVentilatedPostop: "",
    txtComplications: "",
    txtOperative: "",
    txtInfection: "",
    txtNeuroLogic: "",
    txtPulmonary: "",
    txtVascular: "",
    txtRenal: "",
    txtOther: "",

  };

  useEffect(() => {
    async function fetch() {
      try {
        // debugger
        const formPostData = localStorage.getItem(
          "formPostData_" + patientId
        );
        if (formPostData === null) {
          const payload = {
            intSPType: 2,
            txtUUID: patientId,
          };
          const tempResponseData = await APIService.post(
            API.PostoperativeDataForm,
            payload
          );
          console.log(tempResponseData);

          if (
            tempResponseData?.code === 200 ||
            tempResponseData?.code === "200"
          ) {
            //   setTblList(tempResponseData.data ?? []);
            //   setRowCount(tempResponseData.count ?? 0);

            // console.log(tempResponseData.data[0].participant_id);
            if (tempResponseData.data[0]) {
              const temp = tempResponseData.data[0];
              const defaultValues1 = {
                intID: temp.id,
                txtSurgeonName: temp.surgeon_name,
                txtSurgeonGroup: temp.surgeon_group,
                txtEmergentReason: temp.emergent_reason,
                txtUrgentElective: temp.urgent_elective,
                txtArteryBypass: temp.artery_bypass,
                txtAortic: temp.aortic,
                txtMitral: temp.mitral,
                txtTricuspid: temp.tricuspid,
                txtPulmonic: temp.pulmonic,
                txtOtherCardiacProc: temp.other_cardiac_proc,
                txtNonOtherCardiacProc: temp.non_other_cardiac_proc,
                txtUnplannedCABG: temp.unplanned_cabg,
                txtDistalAnastomosesArterial: temp.distal_anastomoses_arterial,
                txtDistalAnastomosesVein: temp.distal_anastomoses_vein,
                txtIMAsUsed: temp.imas_used,
                txtRadialArteryUsed: temp.radial_artery_used,
                txtIMADistalAnastomoses: temp.ima_distal_anastomoses,
                txtRadialArteryDistalAnastomoses: temp.radial_artery_distal_anastomoses,
                txtGastroEpiploicArteryDistalAnastomoses: temp.gastro_epiploic_artery_distal_anastomoses,
                txtAorticImplantType: temp.aortic_implant_type,
                txtAorticImplant: temp.aortic_implant,
                txtAorticImplantSize: temp.aortic_implant_size,
                txtAorticExplantType: temp.aortic_explant_type,
                txtAorticExplant: temp.aortic_explant,
                txtAorticExplantSize: temp.aortic_explant_size,
                txtMitralImplantType: temp.mitral_implant_type,
                txtMitralImplant: temp.mitral_implant,
                txtMitralImplantSize: temp.mitral_implant_size,
                txtMitralExplantType: temp.mitral_explant_type,
                txtMitralExplant: temp.mitral_explant,
                txtMitralExplantSize: temp.mitral_explant_size,
                txtTricuspidImplantType: temp.tricuspid_implant_type,
                txtTricuspidImplant: temp.tricuspid_implant,
                txtTricuspidImplantSize: temp.tricuspid_implant_size,
                txtTricuspidExplantType: temp.tricuspid_explant_type,
                txtTricuspidExplant: temp.tricuspid_explant,
                txtTricuspidExplantSize: temp.tricuspid_explant_size,
                txtPulmonicImplantType: temp.pulmonic_implant_type,
                txtPulmonicImplant: temp.pulmonic_implant,
                txtPulmonicImplantSize: temp.pulmonic_implant_size,
                txtPulmonicExplantType: temp.pulmonic_explant_type,
                txtPulmonicExplant: temp.pulmonic_explant,
                txtPulmonicExplantSize: temp.pulmonic_explant_size,
                txtCardioPulmonaryUsed: temp.cardio_pulmonary_used,
                txtConversionToCPB: temp.conversion_to_cpb,
                txtMinimallyInvasive: temp.minimally_invasive,
                txtPrimaryIncision: temp.primary_incision,
                txtTotalNumberOfIncisions: temp.total_number_of_incisions,
                txtCoversionToStndIncision: temp.coversion_to_stnd_incision,
                txtIndication: temp.indication,
                txtCannulationMethod: temp.cannulation_method,
                txtAorticOcclusionMethod: temp.aortic_occlusion_method,
                txtIntracoronaryShuntUsed: temp.intracoronary_shunt_used,
                txtSutureTechnique: temp.suture_technique,
                txtVesselStabTech: temp.vessel_stab_tech,
                txtIMAHarvestTechn: temp.ima_harvest_techn,
                txtFlowPatency: temp.flow_patency,
                txtOtherCardiacProcedures: temp.other_cardiac_procedures,
                txtOtherNonCardiacProcedures: temp.other_non_cardiac_procedures,
                txtStartTime: temp.start_time,
                txtStopTime: temp.stop_time,
                txtClampTime: temp.clamp_time,
                txtPerfusionTime: temp.perfusion_time,
                txtIABP: temp.iabp,
                txtWhenInserted: temp.when_inserted,
                txtIabpIndication: temp.iabp_indication,
                txtVentricularAssistDevice: temp.ventricular_assist_device,
                txtBloodProdUsed: temp.blood_prod_used,
                txtInitialHoursVentilatedPostop: temp.initial_hours_ventilated_postop,
                txtReintubated: temp.reintubated,
                txtTotalHoursVentilatedPostop: temp.total_hours_ventilated_postop,
                txtComplications: temp.complications,
                txtOperative: temp.operative,
                txtInfection: temp.infection,
                txtNeuroLogic: temp.neuro_logic,
                txtPulmonary: temp.pulmonary,
                txtVascular: temp.vascular,
                txtRenal: temp.renal,
                txtOther: temp.other

              };

              setArteryBypass(temp.artery_bypass);
              setOtherCardiacProcedure(temp.other_cardiac_proc)
              setOtherNonCardiacProcedure(temp.non_other_cardiac_proc)
              setCoversionToStndIncision(temp.coversion_to_stnd_incision)
              setIabp(temp.iabp)
              setReintubated(temp.reintubated)
              setComplications(temp.complications)
              setStartTime(temp.start_time)
              setStopTime(temp.stop_time)
              setClampTime(temp.clamp_time)
              setPerfusionTime(temp.perfusion_time)
              setSelectedIMA(temp.minimally_invasive);
              setSelectedRadialArteryUsed(temp.radial_artery_used);
              setSelectedMinimallyInvasive(temp.imas_used);
              setSelectedIndication(temp.indication);
              setSelectedCannulation(temp.cannulation_method);
              setSelectedAorticOcclusion(temp.aortic_occlusion_method);
              setSelectedSutureTechnique(temp.suture_technique);
              setSelectedVesselStabTech(temp.vessel_stab_tech);
              setSelectedIMAHarvestTechn(temp.ima_harvest_techn);
              setSelectedWhenInserted(temp.when_inserted);
              setComplications(temp.complications);

              console.log(defaultValues1, "data");
              reset(defaultValues1);
              setValues(defaultValues1);
              localStorage.setItem(
                "formPostData_" + patientId,
                JSON.stringify(defaultValues1)
              );
            } else {
              var defaultValues1 = {
                txtSurgeonName: "",
                txtSurgeonGroup: "",
                txtEmergentReason: "",
                txtUrgentElective: "",
                txtArteryBypass: "",
                txtAortic: "",
                txtMitral: "",
                txtTricuspid: "",
                txtPulmonic: "",
                txtOtherCardiacProc: "",
                txtNonOtherCardiacProc: "",
                txtUnplannedCABG: "",
                txtDistalAnastomosesArterial: "",
                txtDistalAnastomosesVein: "",
                txtIMAsUsed: "",
                txtRadialArteryUsed: "",
                txtIMADistalAnastomoses: "",
                txtRadialArteryDistalAnastomoses: "",
                txtGastroEpiploicArteryDistalAnastomoses: "",
                txtAorticImplantType: "",
                txtAorticImplant: "",
                txtAorticImplantSize: "",
                txtAorticExplantType: "",
                txtAorticExplant: "",
                txtAorticExplantSize: "",
                txtMitralImplantType: "",
                txtMitralImplant: "",
                txtMitralImplantSize: "",
                txtMitralExplantType: "",
                txtMitralExplant: "",
                txtMitralExplantSize: "",
                txtTricuspidImplantType: "",
                txtTricuspidImplant: "",
                txtTricuspidImplantSize: "",
                txtTricuspidExplantType: "",
                txtTricuspidExplant: "",
                txtTricuspidExplantSize: "",
                txtPulmonicImplantType: "",
                txtPulmonicImplant: "",
                txtPulmonicImplantSize: "",
                txtPulmonicExplantType: "",
                txtPulmonicExplant: "",
                txtPulmonicExplantSize: "",
                txtCardioPulmonaryUsed: "",
                txtConversionToCPB: "",
                txtMinimallyInvasive: "",
                txtPrimaryIncision: "",
                txtTotalNumberOfIncisions: "",
                txtCoversionToStndIncision: "",
                txtIndication: "",
                txtCannulationMethod: "",
                txtAorticOcclusionMethod: "",
                txtIntracoronaryShuntUsed: "",
                txtSutureTechnique: "",
                txtVesselStabTech: "",
                txtIMAHarvestTechn: "",
                txtFlowPatency: "",
                txtOtherCardiacProcedures: "",
                txtOtherNonCardiacProcedures: "",
                txtStartTime: "",
                txtStopTime: "",
                txtClampTime: "",
                txtPerfusionTime: "",
                txtCardioplegia: "",
                txtIABP: "",
                txtWhenInserted: "",
                txtIabpIndication: "",
                txtVentricularAssistDevice: "",
                txtBloodProdUsed: "",
                txtInitialHoursVentilatedPostop: "",
                txtReintubated: "",
                txtTotalHoursVentilatedPostop: "",
                txtComplications: "",
                txtOperative: "",
                txtInfection: "",
                txtNeuroLogic: "",
                txtPulmonary: "",
                txtVascular: "",
                txtRenal: "",
                txtOther: "",
              };
              setValues(defaultValues1);
              localStorage.setItem(
                "formPostData_" + patientId,
                JSON.stringify(defaultValues1)
              );
            }
          }
          setIsLoading(false);
        } else {
          setValues(JSON.parse(formPostData));
          setArteryBypass(JSON.parse(formPostData).txtArteryBypass);
          setOtherCardiacProcedure(JSON.parse(formPostData).txtOtherCardiacProc)
          setOtherNonCardiacProcedure(JSON.parse(formPostData).txtNonOtherCardiacProc)
          setCoversionToStndIncision(JSON.parse(formPostData).txtCoversionToStndIncision)
          setIabp(JSON.parse(formPostData).txtIABP)
          setReintubated(JSON.parse(formPostData).txtReintubated)
          setComplications(JSON.parse(formPostData).txtComplications)
          setStartTime(dayjs(JSON.parse(formPostData).txtStartTime))
          setStopTime(dayjs(JSON.parse(formPostData).txtStopTime))
          setClampTime(dayjs(JSON.parse(formPostData).txtClampTime))
          setPerfusionTime(dayjs(JSON.parse(formPostData).txtPerfusionTime))
          setSelectedIMA(JSON.parse(formPostData).txtIMAsUsed);
          setSelectedRadialArteryUsed(JSON.parse(formPostData).txtRadialArteryUsed);
          setSelectedMinimallyInvasive(JSON.parse(formPostData).txtMinimallyInvasive);
          setSelectedIndication(JSON.parse(formPostData).txtIndication);
          setSelectedCannulation(JSON.parse(formPostData).txtCannulationMethod);
          setSelectedAorticOcclusion(JSON.parse(formPostData).txtAorticOcclusionMethod);
          setSelectedSutureTechnique(JSON.parse(formPostData).txtSutureTechnique);
          setSelectedVesselStabTech(JSON.parse(formPostData).txtVesselStabTech);
          setSelectedIMAHarvestTechn(JSON.parse(formPostData).txtIMAHarvestTechn);
          setSelectedWhenInserted(JSON.parse(formPostData).txtWhenInserted);
          setSelectedIabpIndication(JSON.parse(formPostData).txtIabpIndication);
          setComplications(JSON.parse(formPostData).txtComplications);

          reset(JSON.parse(formPostData));
        }
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
        console.error(error);
        return;
      }
    }
    fetch();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setValues((prevValues) => {
      // Update form values
      const updatedValues = { ...prevValues, [name]: value };

      // Save the updated values to localStorage
      localStorage.setItem(
        "formPostData_" + patientId,
        JSON.stringify(updatedValues)
      );

      // Return the updated values to update state
      return updatedValues;
    });
  };
  const handleDropdownChange = (name, value) => {
    // const { name, value } = event.target;
    //debugger;
    setValues((prevValues) => {
      // Update form values
      const updatedValues = { ...prevValues, [name]: value };

      // Save the updated values to localStorage
      localStorage.setItem(
        "formPostData_" + patientId,
        JSON.stringify(updatedValues)
      );

      // Return the updated values to update state
      return updatedValues;
    });
  };

  const navigate = useNavigate();

  const handlePopupResponse = (response) => {
    setShowPopup(false);
    if (response === 'yes') {
      // Redirect to the 'Preoperative Data Form' page
      navigate(`/patients/dischargeSummary/${patientId}`);
    } else {
      // Redirect to a different page
      navigate(`/patients/patients`);
    }
  };

  const [showPopup, setShowPopup] = useState(false);


  const onSubmit = async (data) => {
    try {
      console.log(data);
      setIsBtnLoading(true);
      // Retrieve and parse data from localStorage
      //   const savedFormData = localStorage.getItem("formData");
      //   const formData = savedFormData ? JSON.parse(savedFormData) : {};
      //   console.log(formData);

      // Add additional properties to formData if needed

      // Create a FormData object
      const formData = new FormData();

      // Populate FormData with array values
      for (const key in data) {
        formData.append(key, data[key]);
      }

      formData.append("intSPType", 1);
      formData.append("intID", patientId);
      formData.txtArteryBypass = arteryBypass;
      formData.txtOtherCardiacProc = otherCardiacProcedure;
      formData.txtNonOtherCardiacProc = otherNonCardiacProcedure;
      formData.txtCoversionToStndIncision = coversionToStndIncision;
      formData.txtIABP = iabp;
      formData.txtReintubated = reintubated;
      formData.txtComplications = complications;
      formData.txtIMAsUsed = selectedIMA;
      formData.txtRadialArteryUsed = selectedRadialArteryUsed;
      formData.txtMinimallyInvasive = selectedMinimallyInvasive;
      formData.txtIndication = selectedIndication;
      formData.txtCannulationMethod = selectedCannulation;
      formData.txtAorticOcclusionMethod = selectedAorticOcclusionM;
      formData.txtSutureTechnique = selectedSutureTechnique;
      formData.txtVesselStabTech = selectedVesselStabTech;
      formData.txtIMAHarvestTechn = selectedIMAHarvestTechn;
      formData.txtFlowPatency = selectedFlowPatency;
      formData.txtWhenInserted = selectedWhenInserted;
      formData.txtIabpIndication = selectedIabpIndication;
      // Send the formData to the server
      const saveResult = await APIService.post(API.PostoperativeDataForm, formData);
      console.log(saveResult, "saveResult");
      responseRef.current.responseFlag = true;
      responseRef.current.responseCode = saveResult.code;
      responseRef.current.responseMessage = saveResult.message;
      console.log(responseRef);
      // Check if the data is successfully saved to the database
      if (saveResult.code === 200 || saveResult.code === "200") {
        setShowPopup(true);
        // If successful, remove data from local storage
        localStorage.removeItem("formPostData_" + patientId);

        // Reset form values and loading state
        // setValues({});
        setIsBtnLoading(false);
        setIsLoading(false);
      }
      if (saveResult.code === 201 || saveResult.code === "201") {
        setIsBtnLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsBtnLoading(false);
      if (error?.code === 5001) setResponseError(error?.message);
      if (error?.code === 5002) setResponseListError(error?.errors);
    }
    //reset();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ padding: "10px" }}>
        <Card sx={{ padding: "40px" }}>
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            {/* J. Operative */}
            <Typography
              className="custom-heading"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              J. Operative
            </Typography>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              {/* Surgeon's Name */}
              <MDBox mb={3} width="calc(50% - 10px)">

                {/* Adjusted width and added right margin */}
                <MDInput
                  type="text"
                  fullWidth
                  label="Surgeon's Name"
                  name="txtSurgeonName"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtSurgeonName || ""}
                  error={
                    errors?.txtSurgeonName || responseListError?.txtSurgeonName
                  }
                />
              </MDBox>

              {/* Surgeon Group */}
              <MDBox mb={3} width="calc(50% - 10px)">

                {/* Adjusted width */}
                <MDInput
                  type="text"
                  fullWidth
                  label="Surgeon Group"
                  name="txtSurgeonGroup"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtSurgeonGroup || ""}
                  error={
                    errors?.txtSurgeonGroup || responseListError?.txtSurgeonGroup
                  }
                />
              </MDBox>
            </MDBox>
            <>
              {/* Status of the procedure */}
              <Typography
                className="custom-heading"
                style={{ marginBottom: "10px", fontWeight: "bold" }}
              >
                Status of the procedure:
              </Typography>

              {/* First Row */}
              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  {/* {values?.txtEmergentReason && ( */}
                  <MDAutoComplete
                    options={reasonsOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtEmergentReason}
                    id={"txtEmergentReason"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtEmergentReason || ""}
                    error={
                      errors?.txtEmergentReason ||
                      responseListError?.txtEmergentReason
                    }
                    label={"Emergent Salvage Emergent Reason"}
                  />
                  {/* )} */}
                </MDBox>

                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDAutoComplete
                    options={electiveReasonsOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtUrgentElective}
                    id={"txtUrgentElective"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtUrgentElective || ""}
                    error={
                      errors?.txtUrgentElective ||
                      responseListError?.txtUrgentElective
                    }
                    label={"Urgent Elective Reason"}
                  />
                </MDBox>

                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <Autocomplete
                    id="tags-outlined"
                    className="drop-down"
                    options={YesNoOptions.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    defaultValue={YesNoOptions.find((i) => i.id === arteryBypass)}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Coronary Artery Bypass"}
                        placeholder={"Coronary Artery Bypass"}
                        error={isError ? true : false}
                        value={values?.txtArteryBypass || ""}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        setArteryBypass(newValue.value);
                        handleDropdownChange("txtArteryBypass", newValue.value);
                      }
                    }}
                  />
                </MDBox>
              </MDBox>

              {/* Second Row */}
              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDAutoComplete
                    options={aorticOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtAortic}
                    id={"txtAortic"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtAortic || ""}
                    error={errors?.txtAortic || responseListError?.txtAortic}
                    label={"Aortic"}
                  />
                </MDBox>

                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDAutoComplete
                    options={mitralOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtMitral}
                    id={"txtMitral"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtMitral || ""}
                    error={errors?.txtMitral || responseListError?.txtMitral}
                    label={"Mitral"}
                  />
                </MDBox>

                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDAutoComplete
                    options={tricuspidOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtTricuspid}
                    id={"txtTricuspid"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtTricuspid || ""}
                    error={
                      errors?.txtTricuspid || responseListError?.txtTricuspid
                    }
                    label={"Tricuspid"}
                  />
                </MDBox>
              </MDBox>

              {/* Third Row */}
              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDAutoComplete
                    options={pulmonicOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtPulmonic}
                    id={"txtPulmonic"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtSameDayElectiveAdmission || ""}
                    error={errors?.txtPulmonic || responseListError?.txtPulmonic}
                    label={"Pulmonic"}
                  />
                </MDBox>

                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <Autocomplete
                    id="tags-outlined"
                    className="drop-down"
                    options={YesNoOptions.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    defaultValue={YesNoOptions.find(
                      (i) => i.id === otherCardiacProcedure
                    )}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Other Cardiac Procedure"}
                        placeholder={"Other Cardiac Procedure"}
                        error={isError ? true : false}
                        value={values?.txtOtherCardiacProc || ""}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        setOtherCardiacProcedure(newValue.value);
                        handleDropdownChange("txtOtherCardiacProc", newValue.value);
                      }
                    }}
                  />
                </MDBox>

                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <Autocomplete
                    id="tags-outlined"
                    className="drop-down"
                    options={YesNoOptions.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    defaultValue={YesNoOptions.find(
                      (i) => i.id === otherNonCardiacProcedure
                    )}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Other Non-Cardiac Procedure"}
                        placeholder={"Other Non-Cardiac Procedure"}
                        error={isError ? true : false}
                        value={values?.txtNonOtherCardiacProc || ""}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        setOtherNonCardiacProcedure(newValue.value);
                        handleDropdownChange("txtNonOtherCardiacProc", newValue.value);
                      }
                    }}
                  />
                </MDBox>
              </MDBox>
            </>
            {arteryBypass === "yes" && (
              <>
                <Typography
                  className="custom-heading"
                  style={{ marginBottom: "20px", fontWeight: "bold" }}
                >
                  K. Coronary Surgery
                </Typography>

                {/* First Row */}
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                    <MDAutoComplete
                      options={YesNoOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={values?.txtUnplannedCABG}
                      id={"txtUnplannedCABG"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtUnplannedCABG || ""}
                      error={
                        errors?.txtUnplannedCABG ||
                        responseListError?.txtUnplannedCABG
                      }
                      label={"Unplanned CABG"}
                    />

                  </MDBox>

                  <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                    <MDInput
                      type="text"
                      fullWidth
                      label="Number of Distal Anastomoses with Arterial Conduits"
                      name="txtDistalAnastomosesArterial"
                      register={register}
                      onChange={handleChange}
                      value={values?.txtDistalAnastomosesArterial || ""}
                      error={
                        errors?.txtDistalAnastomosesArterial ||
                        responseListError?.txtDistalAnastomosesArterial
                      }
                    />
                  </MDBox>
                  <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                    <MDInput
                      type="text"
                      fullWidth
                      label="Number of Distal Anastomoses with Vein Grafts"
                      name="txtDistalAnastomosesVein"
                      register={register}
                      onChange={handleChange}
                      value={values?.txtDistalAnastomosesVein || ""}
                      error={
                        errors?.txtDistalAnastomosesVein ||
                        responseListError?.txtDistalAnastomosesVein
                      }
                    />
                  </MDBox>
                </MDBox>

                {/* Second Row */}
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <MDBox mb={3} width="50%">
                    <FormControl>
                      <FormLabel
                        id="ima-radio-buttons-group-label"
                        sx={{ fontSize: "14px" }}
                      >
                        IMAs Used as Grafts:
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="ima-radio-buttons-group-label"
                        defaultValue={selectedIMA}
                        value={selectedIMA}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          // setValue("txtIMAsUsed", selectedValue);
                          setSelectedIMA(selectedValue);

                          handleDropdownChange("txtIMAsUsed", selectedValue);

                          // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtIMAsUsed: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem(
                          //     "formData",
                          //     JSON.stringify(updatedValues)
                          //   );

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }}
                        row
                      >
                        {imaOptions.map((option) => (
                          <FormControlLabel
                            key={option.id}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </MDBox>

                  <MDBox mb={3} width="60%">
                    <FormControl>
                      <FormLabel
                        id="radial-artery-radio-buttons-group-label"
                        sx={{ fontSize: "14px" }}
                      >
                        Radial Artery(ies) Used as Grafts:
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="radial-artery-radio-buttons-group-label"
                        defaultValue={selectedRadialArteryUsed}
                        value={selectedRadialArteryUsed}
                        onChange={(e) => {
                          const selectedValue = e.target.value;

                          //setValue("txtRadialArteryUsed", selectedValue);
                          setSelectedRadialArteryUsed(selectedValue);

                          handleDropdownChange("txtRadialArteryUsed", selectedValue);

                          // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtRadialArteryUsed: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem(
                          //     "formData",
                          //     JSON.stringify(updatedValues)
                          //   );

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }}
                        row
                      >
                        {radialArteryOptions.map((option) => (
                          <FormControlLabel
                            key={option.id}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </MDBox>
                </MDBox>

                {/* Third Row */}
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                    <MDInput
                      type="text"
                      fullWidth
                      label="Number of IMA Distal Anastomoses"
                      name="txtIMADistalAnastomoses"
                      register={register}
                      error={
                        errors?.txtIMADistalAnastomoses ||
                        responseListError?.txtIMADistalAnastomoses
                      }
                    />
                  </MDBox>
                  <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                    <MDInput
                      type="text"
                      fullWidth
                      label="Number of Radial Artery Distal Anastomoses"
                      name="txtRadialArteryDistalAnastomoses"
                      register={register}
                      error={
                        errors?.txtRadialArteryDistalAnastomoses ||
                        responseListError?.txtRadialArteryDistalAnastomoses
                      }
                    />
                  </MDBox>
                  <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                    <MDInput
                      type="text"
                      fullWidth
                      label="Number of Gastro-Epiploic Artery Distal Anastomoses"
                      name="txtGastroEpiploicArteryDistalAnastomoses"
                      register={register}
                      error={
                        errors?.txtGastroEpiploicArteryDistalAnastomoses ||
                        responseListError?.txtGastroEpiploicArteryDistalAnastomoses
                      }
                    />
                  </MDBox>
                </MDBox>
              </>
            )}

            {/* L. Valve Surgery */}
            <Typography
              className="custom-heading"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              L. Valve Surgery
            </Typography>
            <>
              {/* Aortic Prosthesis */}
              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                {/* Aortic Prosthesis Implant Type */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDAutoComplete
                    options={valveOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtAorticImplantType}
                    id={"txtAorticImplantType"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtAorticImplantType || ""}
                    error={
                      errors?.txtAorticImplantType ||
                      responseListError?.txtAorticImplantType
                    }
                    label={"Aortic Prosthesis Implant Type"}
                  />

                </MDBox>
                {/* Aortic Prosthesis Implant */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Aortic Prosthesis Implant"
                    name="txtAorticImplant"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtAorticImplant || ""}
                    error={
                      errors?.txtAorticImplant ||
                      responseListError?.txtAorticImplant
                    }
                  />
                </MDBox>
                {/* Aortic Prosthesis Implant Size */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Aortic Prosthesis Implant Size (mm)"
                    name="txtAorticImplantSize"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtAorticImplantSize || ""}
                    error={
                      errors?.txtAorticImplantSize ||
                      responseListError?.txtAorticImplantSize
                    }
                  />
                </MDBox>
              </MDBox>

              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                {/* Aortic Prosthesis Explant Type */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDAutoComplete
                    options={valveOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtAorticExplantType}
                    id={"txtAorticExplantType"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtAorticExplantType || ""}
                    error={
                      errors?.txtAorticExplantType ||
                      responseListError?.txtAorticExplantType
                    }
                    label={"Aortic Prosthesis Explant Type"}
                  />

                </MDBox>
                {/* Aortic Prosthesis Explant */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Aortic Prosthesis Explant"
                    name="txtAorticExplant"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtAorticExplant || ""}
                    error={
                      errors?.txtAorticExplant ||
                      responseListError?.txtAorticExplant
                    }
                  />
                </MDBox>
                {/* Aortic Prosthesis Explant Size (mm) */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Aortic Prosthesis Explant Size (mm)"
                    name="txtAorticExplantSize"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtAorticExplantSize || ""}
                    error={
                      errors?.txtAorticExplantSize ||
                      responseListError?.txtAorticExplantSize
                    }
                  />
                </MDBox>
              </MDBox>

              {/* Mitral Prosthesis */}
              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                {/* Mitral Prosthesis Implant Type */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDAutoComplete
                    options={valveOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtMitralImplantType}
                    id={"txtMitralImplantType"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtMitralImplantType || ""}
                    error={
                      errors?.txtMitralImplantType ||
                      responseListError?.txtMitralImplantType
                    }
                    label={"Mitral Prosthesis Implant Type"}
                  />
                </MDBox>

                {/* Mitral Prosthesis Implant */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Mitral Prosthesis Implant"
                    name="txtMitralImplant"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtMitralImplant || ""}
                    error={
                      errors?.txtMitralImplant ||
                      responseListError?.txtMitralImplant
                    }
                  />
                </MDBox>
                {/* Mitral Prosthesis Implant Size */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Mitral Prosthesis Implant Size (mm)"
                    name="txtMitralImplantSize"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtMitralImplantSize || ""}
                    error={
                      errors?.txtMitralImplantSize ||
                      responseListError?.txtMitralImplantSize
                    }
                  />
                </MDBox>
              </MDBox>

              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                {/* Mitral Prosthesis Explant Type */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDAutoComplete
                    options={valveOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtMitralExplantType}
                    id={"txtMitralExplantType"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtMitralExplantType || ""}
                    error={
                      errors?.txtMitralExplantType ||
                      responseListError?.txtMitralExplantType
                    }
                    label={"Mitral Prosthesis Explant Type"}
                  />
                </MDBox>

                {/* Mitral Prosthesis Explant */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Mitral Prosthesis Explant"
                    name="txtMitralExplant"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtMitralExplant || ""}
                    error={
                      errors?.txtMitralExplant ||
                      responseListError?.txtMitralExplant
                    }
                  />
                </MDBox>
                {/* Mitral Prosthesis Explant Size (mm) */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Mitral Prosthesis Explant Size (mm)"
                    name="txtMitralExplantSize"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtMitralExplantSize || ""}
                    error={
                      errors?.txtMitralExplantSize ||
                      responseListError?.txtMitralExplantSize
                    }
                  />
                </MDBox>
              </MDBox>

              {/* Tricuspid Prosthesis */}
              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                {/* Tricuspid Prosthesis Implant Type */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDAutoComplete
                    options={valveOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtTricuspidImplantType}
                    id={"txtTricuspidImplantType"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtTricuspidImplantType || ""}
                    error={
                      errors?.txtTricuspidImplantType ||
                      responseListError?.txtTricuspidImplantType
                    }
                    label={"Tricuspid Prosthesis Implant Type"}
                  />
                </MDBox>

                {/* Tricuspid Prosthesis Implant */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Tricuspid Prosthesis Implant"
                    name="txtTricuspidImplant"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtTricuspidImplant || ""}
                    error={
                      errors?.txtTricuspidImplant ||
                      responseListError?.txtTricuspidImplant
                    }
                  />
                </MDBox>
                {/* Tricuspid Prosthesis Implant Size */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Tricuspid Prosthesis Implant Size (mm)"
                    name="txtTricuspidImplantSize"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtTricuspidImplantSize || ""}
                    error={
                      errors?.txtTricuspidImplantSize ||
                      responseListError?.txtTricuspidImplantSize
                    }
                  />
                </MDBox>
              </MDBox>

              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                {/* Tricuspid Prosthesis Explant Type */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDAutoComplete
                    options={valveOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtTricuspidExplantType}
                    id={"txtTricuspidExplantType"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtTricuspidExplantType || ""}
                    error={
                      errors?.txtTricuspidExplantType ||
                      responseListError?.txtTricuspidExplantType
                    }
                    label={"Tricuspid Prosthesis Explant Type"}
                  />
                </MDBox>

                {/* Tricuspid Prosthesis Explant */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Tricuspid Prosthesis Explant"
                    name="txtTricuspidExplant"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtTricuspidExplant || ""}
                    error={
                      errors?.txtTricuspidExplant ||
                      responseListError?.txtTricuspidExplant
                    }
                  />
                </MDBox>
                {/* Tricuspid Prosthesis Explant Size (mm) */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Tricuspid Prosthesis Explant Size (mm)"
                    name="txtTricuspidExplantSize"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtTricuspidExplantSize || ""}
                    error={
                      errors?.txtTricuspidExplantSize ||
                      responseListError?.txtTricuspidExplantSize
                    }
                  />
                </MDBox>
              </MDBox>

              {/* Pulmonic Prosthesis */}
              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                {/* Pulmonic Prosthesis Implant Type */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDAutoComplete
                    options={valveOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtPulmonicImplantType}
                    id={"txtPulmonicImplantType"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtPulmonicImplantType || ""}
                    error={
                      errors?.txtPulmonicImplantType ||
                      responseListError?.txtPulmonicImplantType
                    }
                    label={"Pulmonic Prosthesis Implant Type"}
                  />
                </MDBox>

                {/* Pulmonic Prosthesis Implant */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Pulmonic Prosthesis Implant"
                    name="txtPulmonicImplant"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtPulmonicImplant || ""}
                    error={
                      errors?.txtPulmonicImplant ||
                      responseListError?.txtPulmonicImplant
                    }
                  />
                </MDBox>
                {/* Pulmonic Prosthesis Implant Size */}
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Pulmonic Prosthesis Implant Size (mm)"
                    name="txtPulmonicImplantSize"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtPulmonicImplantSize || ""}
                    error={
                      errors?.txtPulmonicImplantSize ||
                      responseListError?.txtPulmonicImplantSize
                    }
                  />
                </MDBox>
              </MDBox>

              {/* Pulmonic Prosthesis */}
              <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDAutoComplete
                    options={valveOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtPulmonicExplantType}
                    id={"txtPulmonicExplantType"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtPulmonicExplantType || ""}
                    error={
                      errors?.txtPulmonicExplantType ||
                      responseListError?.txtPulmonicExplantType
                    }
                    label={"Pulmonic Prosthesis Explant Type"}
                  />
                </MDBox>

                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Pulmonic Prosthesis Explant"
                    name="txtPulmonicExplant"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtPulmonicExplant || ""}
                    error={
                      errors?.txtPulmonicExplant ||
                      responseListError?.txtPulmonicExplant
                    }
                  />
                </MDBox>
                <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Pulmonic Prosthesis Explant Size (mm)"
                    name="txtPulmonicExplantSize"
                    register={register}
                    onChange={handleChange}
                    value={values?.txtPulmonicExplantSize || ""}
                    error={
                      errors?.txtPulmonicExplantSize ||
                      responseListError?.txtPulmonicExplantSize
                    }
                  />
                </MDBox>
              </MDBox>
            </>

            {/* M. Operative Techniques */}
            <Typography
              className="custom-heading"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              M. Operative Techniques
            </Typography>

            {/* Row 1 */}
            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} mr={1} width="calc(50% - 5px)">

                {/* Adjusted margin right here */}
                <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={YesNoOptions.find(
                    (i) => i.id === cardiopulmonaryBypassUsed
                  )}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Cardiopulmonary Bypass Used"}
                      placeholder={"Cardiopulmonary Bypass Used"}
                      error={isError ? true : false}
                      value={values?.txtCardioPulmonaryUsed || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setCardioPulmonaryUsed(newValue.value);
                      handleDropdownChange("txtCardioPulmonaryUsed", newValue.value);
                    }
                  }}
                />
              </MDBox>

              {cardiopulmonaryBypassUsed === "yes" && (
                <MDBox mb={3} width="calc(50% - 5px)">

                  {/* Width adjusted to align with the first box */}
                  <MDAutoComplete
                    options={YesNoOptions}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={values?.txtConversionToCPB}
                    id={"txtConversionToCPB"}
                    handleDropdownChange={handleDropdownChange}
                    value={values?.txtConversionToCPB || ""}
                    error={
                      errors?.txtConversionToCPB ||
                      responseListError?.txtConversionToCPB
                    }
                    label={"Conversion to CPB"}
                  />

                </MDBox>
              )}
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} width="100%">
                <FormControl component="fieldset">
                  <FormLabel component="legend" sx={{ fontSize: "14px" }}>
                    Primary Indication for minimally Invasive approach
                  </FormLabel>
                  <RadioGroup
                    aria-label="minimallyInvasive"
                    name="txtMinimallyInvasive"
                    defaultValue={selectedMinimallyInvasive}
                    value={selectedMinimallyInvasive}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      //setValue("txtMinimallyInvasive", selectedValue);
                      setSelectedMinimallyInvasive(selectedValue);
                      handleDropdownChange("txtMinimallyInvasive", selectedValue);

                      // Update the form values stored in local storage
                      // setValues((prevValues) => {
                      //   const updatedValues = {
                      //     ...prevValues,
                      //     txtMinimallyInvasive: selectedValue,
                      //   };

                      //   // Save the updated values to localStorage
                      //   localStorage.setItem(
                      //     "formData",
                      //     JSON.stringify(updatedValues)
                      //   );

                      //   // Return the updated values to update state
                      //   return updatedValues;
                      // });
                    }}
                    row
                  >
                    {minimallyInvasiveOptions.map((option) => (
                      <FormControlLabel
                        key={option.id}
                        value={option.id}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </MDBox>
            </MDBox>

            {/* Row 2 */}
            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                <MDAutoComplete
                  options={primaryIncisionOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtPrimaryIncision}
                  id={"txtPrimaryIncision"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtPrimaryIncision || ""}
                  error={
                    errors?.txtPrimaryIncision ||
                    responseListError?.txtPrimaryIncision
                  }
                  label={"Primary Incision"}
                />

              </MDBox>

              <MDBox mb={3} width="30%">
                <MDInput
                  type="text"
                  fullWidth
                  label="Total # of Incisions"
                  name="txtTotalNumberOfIncisions"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtTotalNumberOfIncisions || ""}
                  error={
                    errors?.txtTotalNumberOfIncisions ||
                    responseListError?.txtTotalNumberOfIncisions
                  }
                />
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={YesNoOptions.find(
                    (i) => i.id === coversionToStndIncision
                  )}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Conversion to Stnd Incision"}
                      placeholder={"Conversion to Stnd Incision"}
                      error={isError ? true : false}
                      value={values?.txtCoversionToStndIncision || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setCoversionToStndIncision(newValue.value);
                      handleDropdownChange("txtCoversionToStndIncision", newValue.value);
                    }
                  }}
                />
              </MDBox>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {coversionToStndIncision === "yes" && (
                <>
                  <MDBox mb={3} width="100%">
                    <FormControl component="fieldset">
                      <FormLabel component="legend" sx={{ fontSize: "14px" }}>
                        Indication
                      </FormLabel>
                      <RadioGroup
                        aria-label="indication"
                        name="txtIndication"
                        defaultValue={selectedIndication}
                        value={selectedIndication}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          //setValue("txtIndication", selectedValue);
                          setSelectedIndication(selectedValue);

                          handleDropdownChange("txtIndication", selectedValue);
                          // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtIndication: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem(
                          //     "formData",
                          //     JSON.stringify(updatedValues)
                          //   );

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }}
                        row
                      >
                        {indicationOptions.map((option) => (
                          <FormControlLabel
                            key={option.id}
                            value={option.id}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </MDBox>
                </>
              )}
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} width="100%">
                <FormControl component="fieldset">
                  <FormLabel
                    id="cannulationMethod-radio-buttons-group-label"
                    sx={{ fontSize: "14px" }}
                  >
                    Cannulation Meth
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="cannulationMethod-radio-buttons-group-label"
                    defaultValue={selectedCannulation}
                    value={selectedCannulation}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      // setValue("txtCannulationMethod", selectedValue);
                      setSelectedCannulation(selectedValue)
                      handleDropdownChange("txtCannulationMethod", selectedValue);

                      // // Update the form values stored in local storage
                      // setValues((prevValues) => {
                      //   const updatedValues = {
                      //     ...prevValues,
                      //     txtCannulationMethod: selectedValue,
                      //   };

                      //   // Save the updated values to localStorage
                      //   localStorage.setItem(
                      //     "formData",
                      //     JSON.stringify(updatedValues)
                      //   );

                      //   // Return the updated values to update state
                      //   return updatedValues;
                      // });
                    }}
                    row
                  >
                    {cannulationMethodOptions.map((option) => (
                      <FormControlLabel
                        key={option.id}
                        value={option.id}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>

                </FormControl>
              </MDBox>
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} width="50%">
                <FormControl>
                  <FormLabel
                    id="ima-radio-buttons-group-label"
                    sx={{ fontSize: "14px" }}
                  >
                    Aortic Occlusion Method:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="ima-radio-buttons-group-label"
                    defaultValue={selectedAorticOcclusionM}
                    value={selectedAorticOcclusionM}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      setSelectedAorticOcclusion(selectedValue)
                      handleDropdownChange("txtAorticOcclusionMethod", selectedValue);

                      // setValue("txtAorticOcclusionMethod", selectedValue);

                      // // Update the form values stored in local storage
                      // setValues((prevValues) => {
                      //   const updatedValues = {
                      //     ...prevValues,
                      //     txtAorticOcclusionMethod: selectedValue,
                      //   };

                      //   // Save the updated values to localStorage
                      //   localStorage.setItem(
                      //     "formData",
                      //     JSON.stringify(updatedValues)
                      //   );

                      //   // Return the updated values to update state
                      //   return updatedValues;
                      // });
                    }}
                    row
                  >
                    {aorticOcclusionMethodOptions.map((option) => (
                      <FormControlLabel
                        key={option.id}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </MDBox>

              <MDBox mb={3} width="calc(50% - 5px)" mr={2}>
                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtIntracoronaryShuntUsed}
                  id={"txtIntracoronaryShuntUsed"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtIntracoronaryShuntUsed || ""}
                  error={
                    errors?.txtIntracoronaryShuntUsed ||
                    responseListError?.txtIntracoronaryShuntUsed
                  }
                  label={"Intracoronary Shunt used during distal anastomoses"}
                />
              </MDBox>
            </MDBox>

            {/* Row 4 */}
            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} width="100%">
                <FormControl>
                  <FormLabel
                    id="ima-radio-buttons-group-label"
                    sx={{ fontSize: "14px" }}
                  >
                    Suture Technique:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="ima-radio-buttons-group-label"
                    defaultValue={selectedSutureTechnique}
                    value={selectedSutureTechnique}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      // setValue("txtSutureTechnique", selectedValue);
                      setSelectedSutureTechnique(selectedValue);

                      handleDropdownChange("txtSutureTechnique", selectedValue);
                      // // Update the form values stored in local storage
                      // setValues((prevValues) => {
                      //   const updatedValues = {
                      //     ...prevValues,
                      //     txtSutureTechnique: selectedValue,
                      //   };

                      //   // Save the updated values to localStorage
                      //   localStorage.setItem(
                      //     "formData",
                      //     JSON.stringify(updatedValues)
                      //   );

                      //   // Return the updated values to update state
                      //   return updatedValues;
                      // });
                    }}
                    row
                  >
                    {sutureTechniqueOptions.map((option) => (
                      <FormControlLabel
                        key={option.id}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </MDBox>
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} width="100%">
                <FormControl>
                  <FormLabel
                    id="ima-radio-buttons-group-label"
                    sx={{ fontSize: "14px" }}
                  >
                    Vessel Stabilization Technique:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="ima-radio-buttons-group-label"
                    defaultValue={selectedVesselStabTech}
                    value={selectedVesselStabTech}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      // setValue("txtVesselStabTech", selectedValue);
                      setSelectedVesselStabTech(selectedValue);

                      handleDropdownChange("txtVesselStabTech", selectedValue);
                      // // Update the form values stored in local storage
                      // setValues((prevValues) => {
                      //   const updatedValues = {
                      //     ...prevValues,
                      //     txtVesselStabTech: selectedValue,
                      //   };

                      //   // Save the updated values to localStorage
                      //   localStorage.setItem(
                      //     "formData",
                      //     JSON.stringify(updatedValues)
                      //   );

                      //   // Return the updated values to update state
                      //   return updatedValues;
                      // });
                    }}
                    row
                  >
                    {vesselStabilizationTechniqueOptions.map((option) => (
                      <FormControlLabel
                        key={option.id}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </MDBox>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} width="100%">
                <FormControl>
                  <FormLabel
                    id="ima-radio-buttons-group-label"
                    sx={{ fontSize: "14px" }}
                  >
                    IMA Harvest Technique:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="ima-radio-buttons-group-label"
                    defaultValue={selectedIMAHarvestTechn}
                    value={selectedIMAHarvestTechn}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      // setValue("txtIMAHarvestTechn", selectedValue);
                      setSelectedIMAHarvestTechn(selectedValue)
                      handleDropdownChange("txtIMAHarvestTechn", selectedValue);
                      // // Update the form values stored in local storage
                      // setValues((prevValues) => {
                      //   const updatedValues = {
                      //     ...prevValues,
                      //     txtIMAHarvestTechn: selectedValue,
                      //   };

                      //   // Save the updated values to localStorage
                      //   localStorage.setItem(
                      //     "formData",
                      //     JSON.stringify(updatedValues)
                      //   );

                      //   // Return the updated values to update state
                      //   return updatedValues;
                      // });
                    }}
                    row
                  >
                    {imaHarvestTechniqueOptions.map((option) => (
                      <FormControlLabel
                        key={option.id}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </MDBox>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} width="100%">
                <FormControl>
                  <FormLabel
                    id="ima-radio-buttons-group-label"
                    sx={{ fontSize: "14px" }}
                  >
                    Acute Flow Patency Assess of Grafts (Periop):
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="ima-radio-buttons-group-label"
                    defaultValue={selectedFlowPatency}
                    value={selectedFlowPatency}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      // setValue("txtFlowPatency", selectedValue);
                      setSelectedFlowPatency(selectedValue);

                      handleDropdownChange("txtFlowPatency", selectedValue);
                      // // Update the form values stored in local storage
                      // setValues((prevValues) => {
                      //   const updatedValues = {
                      //     ...prevValues,
                      //     txtFlowPatency: selectedValue,
                      //   };

                      //   // Save the updated values to localStorage
                      //   localStorage.setItem(
                      //     "formData",
                      //     JSON.stringify(updatedValues)
                      //   );

                      //   // Return the updated values to update state
                      //   return updatedValues;
                      // });
                    }}
                    row
                  >
                    {acuteFlowPatencyOptions.map((option) => (
                      <FormControlLabel
                        key={option.id}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </MDBox>
            </MDBox>

            {otherCardiacProcedure === "yes" && (
              <>
                <Typography
                  className="custom-heading"
                  style={{ marginBottom: "20px", fontWeight: "bold" }}
                >
                  N. Other Cardiac Procedures
                </Typography>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <MDBox mb={3} width="calc(100% - 10px)" mr={2}>
                    <MDAutoComplete
                      options={otherCardiacProceduresOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={values?.txtOtherCardiacProcedures}
                      id={"txtOtherCardiacProcedures"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtOtherCardiacProcedures || ""}
                      error={
                        errors?.txtOtherCardiacProcedures ||
                        responseListError?.txtOtherCardiacProcedures
                      }
                      label={"Other Cardiac Procedures"}
                    />

                  </MDBox>
                </MDBox>
              </>
            )}

            {/* O. Other Non Cardiac Procedures */}

            {otherNonCardiacProcedure === "yes" && (
              <>
                <Typography
                  className="custom-heading"
                  style={{ marginBottom: "20px", fontWeight: "bold" }}
                >
                  O. Other Non Cardiac Procedures
                </Typography>

                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <MDBox mb={3} width="calc(100% - 5px)" mr={2}>
                    <MDAutoComplete
                      options={otherNonCardiacProceduresOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={values?.txtOtherNonCardiacProcedures}
                      id={"txtOtherNonCardiacProcedures"}
                      handleDropdownChange={handleDropdownChange}
                      error={
                        errors?.txtOtherNonCardiacProcedures ||
                        responseListError?.txtOtherNonCardiacProcedures
                      }
                      label={"Other Non Cardiac Procedures"}
                    />

                  </MDBox>
                </MDBox>
              </>
            )}

            {/* P. CPB and Support */}

            <Typography
              className="custom-heading"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              P. CPB and Support
            </Typography>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox
                mb={2}
                width="calc(50% - 10px)"
                mr={2}
                className="datepicker-parent"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateTimePicker"]}>
                    <DateTimePicker
                      label="Skin Incision Start Time"
                      inputVariant="outlined"
                      className="datepicker"
                      value={startTime}
                      defaultValue={getValues("txtStartTime")}
                      format="DD/MM/YYYY hh:mm A"
                      onChange={(e) => {
                        setStartTime(e);
                        setValue(
                          "txtStartTime",
                          dayjs(e).format("YYYY-MM-DD HH:mm:ss")
                        );
                        handleDropdownChange("txtStartTime", e);
                      }}
                      renderInput={(params) => (
                        <MDInput
                          fullWidth
                          {...params}
                          {...register("txtStartTime")}
                          value={values?.txtStartTime || ""}
                          error={
                            errors.txtStartTime || responseListError?.txtStartTime
                          }
                          errors={
                            errors.txtStartTime || responseListError?.txtStartTime
                          }
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>

              </MDBox>

              <MDBox
                mb={2}
                width="calc(50% - 10px)"
                mr={2}
                className="datepicker-parent"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateTimePicker"]}>
                    <DateTimePicker
                      label="Skin Incision Stop Time"
                      inputVariant="outlined"
                      className="datepicker"
                      value={stopTime}
                      //defaultValue={getValues("txtStopTime")}
                      format="DD/MM/YYYY hh:mm A"
                      onChange={(e) => {
                        setStopTime(e);
                        setValue(
                          "txtStopTime",
                          dayjs(e).format("YYYY-MM-DD HH:mm:ss")
                        );
                        handleDropdownChange("txtStopTime", e);
                      }}
                      renderInput={(params) => (
                        <MDInput
                          fullWidth
                          {...params}
                          {...register("txtStopTime")}
                          value={values?.txtStopTime || ""}
                          error={
                            errors.txtStopTime || responseListError?.txtStopTime
                          }
                          errors={
                            errors.txtStopTime || responseListError?.txtStopTime
                          }
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>

              </MDBox>
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox
                mb={2}
                width="calc(50% - 10px)"
                mr={2}
                className="datepicker-parent"
              >

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateTimePicker"]}>
                    <DateTimePicker
                      label="Cross Clamp Time (min)"
                      inputVariant="outlined"
                      className="datepicker"
                      value={clampTime}
                      //defaultValue={getValues("txtClampTime")}
                      format="DD/MM/YYYY hh:mm A"
                      onChange={(e) => {
                        setClampTime(e);
                        setValue(
                          "txtClampTime",
                          dayjs(e).format("YYYY-MM-DD HH:mm:ss")
                        );
                        handleDropdownChange("txtClampTime", e);
                      }}
                      renderInput={(params) => (
                        <MDInput
                          fullWidth
                          {...params}
                          {...register("txtClampTime")}
                          value={values?.txtClampTime || ""}
                          error={
                            errors.txtClampTime || responseListError?.txtClampTime
                          }
                          errors={
                            errors.txtClampTime || responseListError?.txtClampTime
                          }
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>

              </MDBox>

              <MDBox
                mb={2}
                width="calc(50% - 10px)"
                mr={2}
                className="datepicker-parent"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateTimePicker"]}>
                    <DateTimePicker
                      label="Perfusion Time (min)"
                      inputVariant="outlined"
                      className="datepicker"
                      value={perfusionTime}
                      defaultValue={getValues("txtPerfusionTime")}
                      format="DD/MM/YYYY hh:mm A"
                      onChange={(e) => {
                        setPerfusionTime(e);
                        setValue(
                          "txtPerfusionTime",
                          dayjs(e).format("YYYY-MM-DD HH:mm:ss")
                        );
                        handleDropdownChange("txtPerfusionTime", e);
                      }}
                      renderInput={(params) => (
                        <MDInput
                          fullWidth
                          {...params}
                          {...register("txtPerfusionTime")}
                          value={values?.txtPerfusionTime || ""}
                          error={
                            errors.txtPerfusionTime ||
                            responseListError?.txtPerfusionTime
                          }
                          errors={
                            errors.txtPerfusionTime ||
                            responseListError?.txtPerfusionTime
                          }
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>

              </MDBox>
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} width="calc(50% - 5px)" mr={2}>
                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtCardioplegia}
                  id={"txtCardioplegia"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtCardioplegia || ""}
                  error={
                    errors?.txtCardioplegia || responseListError?.txtCardioplegia
                  }
                  label={"Cardioplegia"}
                />

              </MDBox>

              <MDBox mb={3} width="calc(50% - 5px)" mr={2}>
                <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={YesNoOptions.find((i) => i.id === iabp)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"IABP"}
                      placeholder={"IABP"}
                      error={isError ? true : false}
                      value={values?.txtIABP || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setIabp(newValue.value);
                      handleDropdownChange("txtIABP", newValue.value);
                    }
                  }}
                />
              </MDBox>
            </MDBox>

            {iabp === "yes" && (
              <>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <MDBox mb={3} width="100%">
                    <FormControl>
                      <FormLabel
                        id="ima-radio-buttons-group-label"
                        sx={{ fontSize: "14px" }}
                      >
                        When Inserted:
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="ima-radio-buttons-group-label"
                        defaultValue={selectedWhenInserted}
                        value={selectedWhenInserted}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          // setValue("txtWhenInserted", selectedValue);
                          setSelectedWhenInserted(selectedValue);

                          handleDropdownChange("txtWhenInserted", selectedValue);
                          // setValue("txtWhenInserted", selectedValue);

                          // // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtWhenInserted: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem(
                          //     "formData",
                          //     JSON.stringify(updatedValues)
                          //   );

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }}
                        row
                      >
                        {whenInsertedOptions.map((option) => (
                          <FormControlLabel
                            key={option.id}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </MDBox>
                </MDBox>

                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <MDBox mb={3} width="100%">
                    <FormControl>
                      <FormLabel
                        id="ima-radio-buttons-group-label"
                        sx={{ fontSize: "14px" }}
                      >
                        Indication:
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="ima-radio-buttons-group-label"
                        defaultValue={selectedIabpIndication}
                        value={selectedIabpIndication}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          // setValue("txtIabpIndication", selectedValue);
                          setSelectedIabpIndication(selectedValue);

                          handleDropdownChange("txtIabpIndication", selectedValue);
                          // // Update the form values stored in local storage
                          // setValues((prevValues) => {
                          //   const updatedValues = {
                          //     ...prevValues,
                          //     txtIabpIndication: selectedValue,
                          //   };

                          //   // Save the updated values to localStorage
                          //   localStorage.setItem(
                          //     "formData",
                          //     JSON.stringify(updatedValues)
                          //   );

                          //   // Return the updated values to update state
                          //   return updatedValues;
                          // });
                        }}
                        row
                      >
                        {iabpIndicationOptions.map((option) => (
                          <FormControlLabel
                            key={option.id}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </MDBox>
                </MDBox>
              </>
            )}

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} width="calc(50% - 5px)" mr={2}>
                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtVentricularAssistDevice}
                  id={"txtVentricularAssistDevice"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtVentricularAssistDevice || ""}
                  error={
                    errors?.txtVentricularAssistDevice ||
                    responseListError?.txtVentricularAssistDevice
                  }
                  label={"Ventricular Assist Device"}
                />

              </MDBox>
            </MDBox>

            {/* Q. Post Operative */}
            <Typography
              className="custom-heading"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              Q. Post Operative
            </Typography>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                <MDAutoComplete
                  options={YesNoOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtBloodProdUsed}
                  id={"txtBloodProdUsed"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtBloodProdUsed || ""}
                  error={
                    errors?.txtBloodProdUsed ||
                    responseListError?.txtBloodProdUsed
                  }
                  label={"Blood Products Used"}
                />

              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                <MDInput
                  type="text"
                  fullWidth
                  label="Initial # of Hrs Ventilated Postop"
                  name="txtInitialHoursVentilatedPostop"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtInitialHoursVentilatedPostop || ""}
                  error={errors?.txtInitialHoursVentilatedPostop || responseListError?.txtInitialHoursVentilatedPostop}

                />
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={YesNoOptions.find((i) => i.id === reintubated)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Re-intubated During Hosp Stay"}
                      placeholder={"Re-intubated During Hosp Stay"}
                      error={isError ? true : false}
                      value={values?.txtReintubated || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setReintubated(newValue.value);
                      handleDropdownChange("txtReintubated", newValue.value);
                    }
                  }}
                />
              </MDBox>
            </MDBox>

            {reintubated === "yes" && (
              <>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <MDBox mb={3} width="calc(50% - 5px)" mr={1}>
                    <MDInput
                      type="text"
                      fullWidth
                      label="Addl Hours Ventilated Postop"
                      name="txtAdditionalHoursVentilatedPostop"
                      register={register}
                      onChange={handleChange}
                      value={values?.txtAdditionalHoursVentilatedPostop || ""}
                      error={errors?.txtAdditionalHoursVentilatedPostop || responseListError?.txtAdditionalHoursVentilatedPostop}


                    />
                  </MDBox>

                  <MDBox mb={3} width="calc(50% - 5px)">
                    <MDInput
                      type="text"
                      fullWidth
                      label="Total Hours Ventilated Postop"
                      name="txtTotalHoursVentilatedPostop"
                      register={register}
                      onChange={handleChange}
                      value={values?.txtTotalHoursVentilatedPostop || ""}
                      error={
                        errors?.txtTotalHoursVentilatedPostop ||
                        responseListError?.txtTotalHoursVentilatedPostop
                      }
                    />
                  </MDBox>
                </MDBox>
              </>
            )}

            {/* R. Complications In hospital Complications */}
            <Typography
              className="custom-heading"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              R. Complications
            </Typography>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} width="calc(100% - 5px)" mr={2}>
                <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={YesNoOptions.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  defaultValue={YesNoOptions.find((i) => i.id === complications)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"In hospital Complications"}
                      placeholder={"In hospital Complications"}
                      error={isError ? true : false}
                      value={values?.txtComplications || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setComplications(newValue.value);
                      handleDropdownChange("txtComplications", newValue.value);
                    }
                  }}
                />
              </MDBox>
            </MDBox>

            {complications === "yes" && (
              <>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                    <MDAutoComplete
                      options={OperativeOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={values?.txtOperative}
                      id={"txtOperative"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtOperative || ""}
                      error={
                        errors?.txtOperative || responseListError?.txtOperative
                      }
                      label={"Operative"}
                    />

                  </MDBox>

                  <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                    <MDAutoComplete
                      options={InfectionOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={values?.txtInfection}
                      id={"txtInfection"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtInfection || ""}
                      error={
                        errors?.txtInfection || responseListError?.txtInfection
                      }
                      label={"Infection"}
                    />

                  </MDBox>

                  <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                    <MDAutoComplete
                      options={neurologicOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={values?.txtNeuroLogic}
                      id={"txtNeuroLogic"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtNeuroLogic || ""}
                      error={
                        errors?.txtNeuroLogic || responseListError?.txtNeuroLogic
                      }
                      label={"Neurologic"}
                    />

                  </MDBox>
                </MDBox>

                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                    <MDAutoComplete
                      options={pulmonaryOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={values?.txtPulmonary}
                      id={"txtPulmonary"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtPulmonary || ""}
                      error={
                        errors?.txtPulmonary || responseListError?.txtPulmonary
                      }
                      label={"Pulmonary"}
                    />

                  </MDBox>
                  <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                    <MDAutoComplete
                      options={vascularOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={values?.txtVascular}
                      id={"txtVascular"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtVascular || ""}
                      error={
                        errors?.txtVascular || responseListError?.txtVascular
                      }
                      label={"Vascular"}
                    />

                  </MDBox>
                  <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                    <MDAutoComplete
                      options={renalOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={values?.txtRenal}
                      id={"txtRenal"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtRenal || ""}
                      error={errors?.txtRenal || responseListError?.txtRenal}
                      label={"Renal"}
                    />

                  </MDBox>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <MDBox mb={3} width="calc(33% - 10px)" mr={2}>
                    <MDAutoComplete
                      options={otherOptions}
                      register={register}
                      getValues={getValues}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={values?.txtOther}
                      id={"txtOther"}
                      handleDropdownChange={handleDropdownChange}
                      value={values?.txtOther || ""}
                      error={errors?.txtOther || responseListError?.txtOther}
                      label={"Other"}
                    />

                  </MDBox>
                </MDBox>
              </>
            )}

            <Typography className="invalid-feedback">
              {responseError}
            </Typography>
            {/* Popup component */}
            {showPopup && (
              <Popup
                message="Do you want to fill 'Discharge Summary Form'?"
                onResponse={handlePopupResponse}
              />
            )}
            <MDBox display="flex" flexDirection="column" mb={3}>
              {!isBtnLoading ? (
                <MDBox mt={4} display="flex" justifyContent="end">
                  <MDButton variant="gradient" color="info" type="submit">
                    submit
                  </MDButton>
                </MDBox>
              ) : (
                <MDBox mt={4} display="flex" justifyContent="end">
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                </MDBox>
              )}
            </MDBox>
          </form>
          {/* {isBtnLoading && <p>Data Updated Successfully...</p>} */}

        </Card>
      </Box>
      <Footer responseRef={responseRef} />

    </DashboardLayout>

  );
};

export default PostoperativeDataForm;
