// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";

import MDSnackbar from "components/MDSnackbar";
import { useEffect, useRef, useState } from "react";

function Footer({ company, links, responseRef }) {
  // const responseRef1 = useRef();
  const { href, name } = company;
  const { size } = typography;

  let color = "";
  switch (responseRef.current.responseCode.toString()) {
    case "200":
      color = "green";
      break;
    case "201":
      color = "warning";
      break;
    case "5001" || "5002" || "400" || "502":
      color = "red";
      break;
    default:
      color = "green";
  }

  let SnackbarColor = "";
  switch (responseRef.current.responseCode.toString()) {
    case "200":
      SnackbarColor = "success";
      break;
    case "201":
      SnackbarColor = "warning";
      break;
    case "5001" || "5002" || "400" || "502":
      SnackbarColor = "error";
      break;
    default:
      SnackbarColor = "success";
  }

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDSnackbar
        color={SnackbarColor}
        icon="check"
        title={responseRef.current.responseMessage}
        dateTime=""
        open={responseRef.current.responseFlag}
        close={() => {
          responseRef.current.responseFlag = false;
        }}
        bgWhite
      />
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        Developed by DigiCom Guru
      </MDBox>
      {/* <MDBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {renderLinks()}
      </MDBox> */}
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: {},
  links: [],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
