// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
// import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import { useLocation, NavLink } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";

function SidenavCollapse({
  icon,
  name,
  active,
  key: customKey,
  subRoutes,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;

  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  const [openSubRoutes, setOpenSubRoutes] = useState({});

  const handleSubRouteClick = (customKey) => {
    setOpenSubRoutes((prevOpenSubRoutes) => ({
      ...prevOpenSubRoutes,
      [customKey]: !prevOpenSubRoutes[customKey],
    }));
  };

  const isSubRouteOpen = (customKey) => openSubRoutes[customKey];

  useEffect(() => {
    subRoutes?.[0] && handleSubRouteClick(subRoutes[0].parentKey);
  }, []);

  const renderListItem = (props) => (
    <ListItem component="li">
      <MDBox {...rest} {...props} />
    </ListItem>
  );

  return (
    <>
      {!subRoutes ? (
        renderListItem({
          sx: (theme) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
            }),
          children: (
            <>
              <ListItemIcon
                sx={(theme) =>
                  collapseIconBox(theme, {
                    transparentSidenav,
                    whiteSidenav,
                    darkMode,
                    active,
                  })
                }
              >
                {typeof icon === "string" ? (
                  <Icon sx={(theme) => collapseIcon(theme, { active })}>
                    {icon}
                  </Icon>
                ) : (
                  icon
                )}
              </ListItemIcon>

              <ListItemText
                primary={name}
                sx={(theme) =>
                  collapseText(theme, {
                    miniSidenav,
                    transparentSidenav,
                    whiteSidenav,
                    active,
                  })
                }
              />
            </>
          ),
        })
      ) : (
        <>
          {renderListItem({
            onClick: () => handleSubRouteClick(customKey),
            sx: (theme) =>
              collapseItem(theme, {
                active,
                transparentSidenav,
                whiteSidenav,
                darkMode,
                sidenavColor,
              }),
            children: (
              <>
                <ListItemIcon
                  sx={(theme) =>
                    collapseIconBox(theme, {
                      transparentSidenav,
                      whiteSidenav,
                      darkMode,
                      active,
                    })
                  }
                >
                  {typeof icon === "string" ? (
                    <Icon sx={(theme) => collapseIcon(theme, { active })}>
                      {icon}
                    </Icon>
                  ) : (
                    icon
                  )}
                </ListItemIcon>

                <ListItemText
                  primary={name}
                  sx={(theme) =>
                    collapseText(theme, {
                      miniSidenav,
                      transparentSidenav,
                      whiteSidenav,
                      active,
                    })
                  }
                />
                {isSubRouteOpen(customKey) ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </>
            ),
          })}

          <Collapse in={isSubRouteOpen(customKey)} timeout="auto" unmountOnExit>
            {subRoutes.map((subRoute, index) => (
              <NavLink
                key={subRoute.route + "_" + index}
                to={subRoute.route}
                className={"sub-menu"}
              >
                {renderListItem({
                  sx: (theme) =>
                    collapseItem(theme, {
                      active1: subRoute.key.includes(collapseName),
                      transparentSidenav,
                      whiteSidenav,
                      darkMode,
                      sidenavColor,
                    }),
                  children: (
                    <>
                      <ListItemIcon
                        sx={(theme) =>
                          collapseIconBox(theme, {
                            transparentSidenav,
                            whiteSidenav,
                            darkMode,
                            active1: subRoute.key.includes(collapseName),
                          })
                        }
                      >
                        {subRoute.name.charAt(0)}
                      </ListItemIcon>
                      <ListItemText
                        primary={subRoute.name}
                        sx={(theme) =>
                          collapseText(theme, {
                            miniSidenav,
                            transparentSidenav,
                            whiteSidenav,
                            active1: subRoute.key.includes(collapseName),
                          })
                        }
                      />
                    </>
                  ),
                })}
              </NavLink>
            ))}
          </Collapse>
        </>
      )}
    </>
  );
}

SidenavCollapse.defaultProps = {
  active: false,
};

SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
