import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Material Dashboard 2 React components
import Popup from "PopUp/popup";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MDTabs from "components/MDTabs/MDTabs";
import { MDAutoComplete } from "components/MDAutoComplete";
import { Button, Card, CircularProgress, Grid } from "@mui/material";
import { TextField, Autocomplete } from "@mui/material";

// Yup Validation imports
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import validationParams from "validationParams";

// Datepicker import
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// API Import
import API from "APIUrls";
import APIService from "services/api-service";

//Radio group
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function CustomAccordionPanel(props) {
  const { children, summary, expanded, onChange } = props;

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

CustomAccordionPanel.propTypes = {
  children: PropTypes.node,
  summary: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const PersonalDataForm = () => {
  const { patientId } = useParams();

  const [date, setDate] = useState(dayjs());
  const [dateOfAdmission, setDateOfAdmission] = useState(dayjs());
  const [dateOfSurgery, setDateOfSurgery] = useState(dayjs());
  const [dateOfDischarge, setDateOfDischarge] = useState(dayjs());
  const [txtRace, setTxtRace] = useState("");
  const [selectedGender, setSelectedGender] = useState("male");
  const [readmissionToICU, setReadmissionToICU] = useState("");
  const [values, setValues] = useState(defaultValues);

  const [responseError, setResponseError] = useState(null);
  const [responseListError, setResponseListError] = useState(null);

  const [formData, setFormData] = useState({});

  const responseRef = useRef({
    responseFlag: false,
    responseCode: 200,
    responseMessage: "",
  });

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const genderOptions = [
    { id: "male", value: "male", label: "Male", firstLetter: "M" },
    { id: "female", value: "female", label: "Female", firstLetter: "F" },
    { id: "other", value: "other", label: "Other", firstLetter: "O" },
  ];

  const raceOptions = [
    {
      id: "caucasian",
      value: "caucasian",
      label: "Caucasian",
      firstLetter: "C",
    },
    { id: "black", value: "black", label: "Black", firstLetter: "B" },
    { id: "hispanic", value: "hispanic", label: "Hispanic", firstLetter: "H" },
    { id: "asian", value: "asian", label: "Asian", firstLetter: "A" },
    {
      id: "native_american",
      value: "native_american",
      label: "Native American",
      firstLetter: "N",
    },
    { id: "other", value: "other", label: "Other", firstLetter: "O" },
  ];

  const ICUOptions = [
    { id: "yes", value: "yes", label: "Yes", firstLetter: "Y" },
    { id: "no", value: "no", label: "No", firstLetter: "N" },
  ];

  const validationSchema = Yup.object().shape({});

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm({ resolver: yupResolver(validationSchema) });

  // Load form data from localStorage when the component mounts
  useEffect(() => {
    const savedFormData = localStorage.getItem("formData");
    if (savedFormData) {
      // console.log(savedFormData);
      const parsedFormData = JSON.parse(savedFormData);
      //console.log(parsedFormData, "data");
      setValues(parsedFormData);
    }
  }, []);

  var defaultValues = {
    txtParticipantID: "",
    txtCostLinkField: "",
    txtStsTrialLinkNumber: "",
    txtMedicalRecordNumber: "",
    txtLastName: "",
    txtFirstName: "",
    txtMI: "",
    txtDOB: "",
    txtAge: "",
    txtGender: "",
    txtRace: "",
    txtSsn: "",
    txtZipCode: "",
    txtRefCardiologistName: "",
    txtRefPhysicianName: "",
    txtHospitalName: "",
    txtPrimaryPayor: "",
    txtDateOfAdmission: "",
    txtDateOfSurgery: "",
    txtDateOfDischarge: "",
    txtSameDayElectiveAdmission: "",
    txtInitialICUHours: "",
    txtReadmissionToICU: "",
    txtAdditionalICUHours: "",
    txtTotalICUHours: "",
  };

  useEffect(() => {
    async function fetch() {
      try {
        // debugger
        const formPersonalData = localStorage.getItem(
          "formPersonalData_" + patientId
        );
        // debugger;
        if (formPersonalData === null) {
          const payload = {
            intSPType: 2,
            txtUUID: patientId,
          };
          const tempResponseData = await APIService.post(
            API.PersonalDataForm,
            payload
          );
          console.log(tempResponseData);

          if (
            tempResponseData?.code === 200 ||
            tempResponseData?.code === "200"
          ) {
            //   setTblList(tempResponseData.data ?? []);
            //   setRowCount(tempResponseData.count ?? 0);

            // console.log(tempResponseData.data[0].participant_id);
            if (tempResponseData.data[0]) {
              const temp = tempResponseData.data[0];
              const defaultValues1 = {
                intID: temp.id,
                txtParticipantID: temp.participant_id,
                txtCostLinkField: temp.costlink_field,
                txtStsTrialLinkNumber: temp.sts_trial_link_number,
                txtMedicalRecordNumber: temp.medical_record_number,
                txtLastName: temp.last_name,
                txtFirstName: temp.first_name,
                txtMI: temp.mi,
                txtDOB: temp.dob,
                txtAge: temp.age,
                txtGender: temp.gender,
                txtRace: temp.race,
                txtSsn: temp.ssn,
                txtZipCode: temp.zip_code,
                txtRefCardiologistName: temp.ref_cardiologist_name,
                txtRefPhysicianName: temp.ref_physician_name,
                txtHospitalName: temp.hospital_name,
                txtPrimaryPayor: temp.primary_payor,
                txtDateOfAdmission: temp.date_of_admission,
                txtDateOfSurgery: temp.date_of_surgery,
                txtDateOfDischarge: temp.date_of_discharge,
                txtSameDayElectiveAdmission: temp.same_day_elective_admission,
                txtInitialICUHours: temp.initial_icu_hours,
                txtReadmissionToICU: temp.readmission_to_icu,
                txtAdditionalICUHours: temp.additional_icu_hours,
                txtTotalICUHours: temp.total_icu_hours,
              };
              setReadmissionToICU(temp.readmission_to_icu);
              setSelectedGender(temp.gender);
              setDate(dayjs(temp.dob));
              setDateOfAdmission(dayjs(temp.date_of_admission));
              setDateOfSurgery(dayjs(temp.date_of_surgery));
              setDateOfDischarge(dayjs(temp.date_of_discharge));
              setTxtRace(temp.race);
              console.log(defaultValues1, "data");
              reset(defaultValues1);
              setValues(defaultValues1);
              localStorage.setItem(
                "formPersonalData_" + patientId,
                JSON.stringify(defaultValues1)
              );
            } else {
              var defaultValues1 = {
                txtParticipantID: "",
                txtCostLinkField: "",
                txtStsTrialLinkNumber: "",
                txtMedicalRecordNumber: "",
                txtLastName: "",
                txtFirstName: "",
                txtMI: "",
                txtDOB: "",
                txtAge: "",
                txtGender: "",
                txtRace: "",
                txtSsn: "",
                txtZipCode: "",
                txtRefCardiologistName: "",
                txtRefPhysicianName: "",
                txtHospitalName: "",
                txtPrimaryPayor: "",
                txtDateOfAdmission: "",
                txtDateOfSurgery: "",
                txtDateOfDischarge: "",
                txtSameDayElectiveAdmission: "",
                txtInitialICUHours: "",
                txtReadmissionToICU: "",
                txtAdditionalICUHours: "",
                txtTotalICUHours: "",
              };
              setValues(defaultValues1);
              localStorage.setItem(
                "formPersonalData_" + patientId,
                JSON.stringify(defaultValues1)
              );
            }
          }
          setIsLoading(false);
        } else {
          setValues(JSON.parse(formPersonalData));
          setReadmissionToICU(JSON.parse(formPersonalData).txtReadmissionToICU);
          setSelectedGender(JSON.parse(formPersonalData).txtGender);
          setDate(dayjs(JSON.parse(formPersonalData).txtDOB));
          setDateOfAdmission(
            dayjs(JSON.parse(formPersonalData).txtDateOfAdmission)
          );
          setDateOfSurgery(
            dayjs(JSON.parse(formPersonalData).txtDateOfSurgery)
          );
          setDateOfDischarge(
            dayjs(JSON.parse(formPersonalData).txtDateOfDischarge)
          );
          setTxtRace(JSON.parse(formPersonalData).txtRace);
          console.log(JSON.parse(formPersonalData).txtRace);
          reset(JSON.parse(formPersonalData));
        }
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
        console.error(error);
        return;
      }
    }
    fetch();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setValues((prevValues) => {
      // Update form values
      const updatedValues = { ...prevValues, [name]: value };

      // Save the updated values to localStorage
      localStorage.setItem(
        "formPersonalData_" + patientId,
        JSON.stringify(updatedValues)
      );

      // Return the updated values to update state
      return updatedValues;
    });
  };

  const handleDropdownChange = (name, value) => {
    // const { name, value } = event.target;
    // debugger;
    setValues((prevValues) => {
      // Update form values
      const updatedValues = { ...prevValues, [name]: value };

      // Save the updated values to localStorage
      localStorage.setItem(
        "formPersonalData_" + patientId,
        JSON.stringify(updatedValues)
      );

      // Return the updated values to update state
      return updatedValues;
    });
  };

  const navigate = useNavigate();

  const handlePopupResponse = (response) => {
    setShowPopup(false);
    if (response === "yes") {
      // Redirect to the 'Preoperative Data Form' page
      navigate(`/patients/preoperativeData/${patientId}`);
    } else {
      // Redirect to a different page
      navigate(`/patients/patients`);
    }
  };

  const [showPopup, setShowPopup] = useState(false);

  const onSubmit = async (data) => {
    try {
      console.log(data);
      setIsBtnLoading(true);
      // Retrieve and parse data from localStorage
      //   const savedFormData = localStorage.getItem("formData");
      //   const formData = savedFormData ? JSON.parse(savedFormData) : {};
      //   console.log(formData);

      // Add additional properties to formData if needed

      // Create a FormData object
      const formData = new FormData();

      const savedFormData = JSON.parse(
        localStorage.getItem("formPersonalData_" + patientId)
      );

      //   debugger
      // Populate FormData with array values
      for (const key in savedFormData) {
        formData.append(key, savedFormData[key]);
      }
      formData.append("intSPType", 1);
      //   formData.append("intID", patientId);
      // formData.append("txtReadmissionToICU", readmissionToICU);
      //formData.append("txtDOB", date);
      console.log("txtDOB", date);
      formData.txtReadmissionToICU = readmissionToICU;
      formData.txtGender = selectedGender;
      formData.txtRace = txtRace;
      // Send the formData to the server
      const saveResult = await APIService.post(API.PersonalDataForm, formData);
      console.log(saveResult, "saveResult");
      responseRef.current.responseFlag = true;
      responseRef.current.responseCode = saveResult.code;
      responseRef.current.responseMessage = saveResult.message;
      console.log(responseRef);
      //debugger
      // Check if the data is successfully saved to the database
      if (saveResult.code === 200 || saveResult.code === "200") {
        setShowPopup(true);
        // If successful, remove data from local storage
        localStorage.removeItem("formPersonalData_" + patientId);
        // Reset form values and loading state
        // setValues({});
        setIsBtnLoading(false);
        setIsLoading(false);
      }
      if (saveResult.code === 201 || saveResult.code === "201") {
        setIsBtnLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsBtnLoading(false);
      if (error?.code === 5001) setResponseError(error?.message);
      if (error?.code === 5002) setResponseListError(error?.errors);
    }
    //reset();
  };

  // const handleRadioChange = (name, value) => {
  //     setValue(name, value);
  //     // Update local state or perform other actions as needed
  // };

  // const handleDateChange = (dateFieldName, dateValue) => {
  //     setDate((prevDate) => ({ ...prevDate, [dateFieldName]: dateValue }));
  //     setValue(dateFieldName, dayjs(dateValue).format("YYYY-MM-DD"));
  //     // Additional actions if needed
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box>
        <Card sx={{ padding: "40px" }}>
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <Typography
              className="custom-heading"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              A. Administrative
            </Typography>
            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="Participant ID"
                  name="txtParticipantID"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtParticipantID || ""}
                />
              </MDBox>
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="Cost Link Field"
                  name="txtCostLinkField"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtCostLinkField || ""}
                  error={
                    errors?.txtCostLinkField ||
                    responseListError?.txtCostLinkField
                  }
                />
              </MDBox>

              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="Optional STS Trial Link Number"
                  name="txtStsTrialLinkNumber"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtStsTrialLinkNumber || ""}
                  error={
                    errors?.txtStsTrialLinkNumber ||
                    responseListError?.txtStsTrialLinkNumber
                  }
                />
              </MDBox>
            </MDBox>

            {/* Demographics Section */}
            <Typography
              className="custom-heading"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              B. Demographics
            </Typography>
            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="Patient Medical Record Number"
                  name="txtMedicalRecordNumber"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtMedicalRecordNumber || ""}
                  error={
                    errors?.txtMedicalRecordNumber ||
                    responseListError?.txtMedicalRecordNumber
                  }
                />
              </MDBox>

              {/* Last Name */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="First Name"
                  name="txtFirstName"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtFirstName || ""}
                  error={
                    errors?.txtFirstName || responseListError?.txtFirstName
                  }
                />
              </MDBox>

              {/* First Name */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="Last Name"
                  name="txtLastName"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtLastName || ""}
                  error={errors?.txtLastName || responseListError?.txtLastName}
                />
              </MDBox>
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {/* MI */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="MI"
                  name="txtMI"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtMI || ""}
                  error={errors?.txtMI || responseListError?.txtMI}
                />
              </MDBox>

              {/* Date of Birth */}

              <MDBox mb={3} width="30%" className="datepicker-parent">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Date of Birth"
                      inputVariant="outlined"
                      className="datepicker"
                      value={date}
                      //defaultValue={values?.txtDOB}
                      format="DD/MM/YYYY"
                      onChange={(e) => {
                        //debugger
                        console.log(dayjs(e).format("YYYY-MM-DD"));
                        setDate(e);
                        setValue("txtDOB", dayjs(e).format("YYYY-MM-DD"));
                        handleDropdownChange("txtDOB", e);
                      }}
                      renderInput={(params) => (
                        <MDInput
                          fullWidth
                          {...params}
                          {...register("txtDOB")}
                          value={values?.txtDOB || ""}
                          error={errors.txtDOB || responseListError?.txtDOB}
                        //errors={errors.txtDOB || responseListError?.txtDOB}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </MDBox>

              {/* Age */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="Age"
                  name="txtAge"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtAge || ""}
                  error={errors?.txtAge || responseListError?.txtAge}
                />
              </MDBox>
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {/* Gender */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <FormControl component="fieldset">
                  <FormLabel
                    id="gender-radio-buttons-group-label"
                    sx={{ fontSize: "14px" }}
                  >
                    Gender
                  </FormLabel>
                  {/* {(values?.txtGender ) && ( */}
                  <RadioGroup
                    aria-labelledby="gender-radio-buttons-group-label"
                    defaultValue={selectedGender}
                    value={selectedGender}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      // setValue("txtGender", selectedValue);
                      setSelectedGender(selectedValue);

                      handleDropdownChange("txtGender", selectedValue);

                      // Update the form values stored in local storage
                      // setValues((prevValues) => {
                      //     const updatedValues = {
                      //         ...prevValues,
                      //         txtGender: selectedValue,
                      //     };

                      //     // Save the updated values to localStorage
                      //     localStorage.setItem(
                      //         "formData",
                      //         JSON.stringify(updatedValues)
                      //     );

                      //     // Return the updated values to update state
                      //     return updatedValues;
                      // });
                    }}
                    row
                  >
                    {genderOptions.map((option) => (
                      <FormControlLabel
                        key={option.id}
                        value={option.id}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>

                  {/* )} */}
                </FormControl>
              </MDBox>

              {/* Race */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDAutoComplete
                  options={raceOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  //setData={setTxtRace}
                  defaultValue={values?.txtRace}
                  id={"txtRace"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtRace || ""}
                  error={errors?.txtRace || responseListError?.txtRace}
                  label={"Race"}
                />
              </MDBox>

              {/* Social Security (or National ID) Number */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="Social Security (or National ID) Number"
                  name="txtSsn"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtSsn || ""}
                  error={errors?.txtSsn || responseListError?.txtSsn}
                />
              </MDBox>
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {/* ZIP or Postal Code */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="ZIP or Postal Code"
                  name="txtZipCode"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtZipCode || ""}
                  error={errors?.txtZipCode || responseListError?.txtZipCode}
                />
              </MDBox>

              {/* Referring Cardiologist's Name */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="Referring Cardiologist's Name"
                  name="txtRefCardiologistName"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtRefCardiologistName || ""}
                  error={
                    errors?.txtRefCardiologistName ||
                    responseListError?.txtRefCardiologistName
                  }
                />
              </MDBox>

              {/* Referring Physician's Name */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="Referring Physician's Name"
                  name="txtRefPhysicianName"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtRefPhysicianName || ""}
                  error={
                    errors?.txtRefPhysicianName ||
                    responseListError?.txtRefPhysicianName
                  }
                />
              </MDBox>
            </MDBox>

            {/* Hospitalization Section */}
            <Typography
              className="custom-heading"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              C. Hospitalization
            </Typography>
            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {/* Hospital Name */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="Hospital Name"
                  name="txtHospitalName"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtHospitalName || ""}
                  error={
                    errors?.txtHospitalName ||
                    responseListError?.txtHospitalName
                  }
                />
              </MDBox>

              {/* Primary Payor */}
              <MDBox mb={3} width="calc(33% - 10px)">
                <MDInput
                  type="text"
                  fullWidth
                  label="Primary Payor"
                  name="txtPrimaryPayor"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtPrimaryPayor || ""}
                  error={
                    errors?.txtPrimaryPayor ||
                    responseListError?.txtPrimaryPayor
                  }
                />
              </MDBox>

              {/* Date of Admission */}

              <MDBox mb={3} width="30%" className="datepicker-parent">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Date of Admission"
                      inputVariant="outlined"
                      className="datepicker"
                      value={dateOfAdmission}
                      //defaultValue={getValues("txtDateOfAdmission")}
                      format="DD/MM/YYYY"
                      onChange={(e) => {
                        setDateOfAdmission(e);
                        setValue(
                          "txtDateOfAdmission",
                          dayjs(e).format("YYYY-MM-DD")
                        );
                        handleDropdownChange("txtDateOfAdmission", e);
                      }}
                      renderInput={(params) => (
                        <MDInput
                          fullWidth
                          {...params}
                          {...register("txtDateOfAdmission")}
                          value={values?.txtDateOfAdmission || ""}
                          error={
                            errors.txtDateOfAdmission ||
                            responseListError?.txtDateOfAdmission
                          }
                          errors={
                            errors.txtDateOfAdmission ||
                            responseListError?.txtDateOfAdmission
                          }
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </MDBox>
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {/* Date of Surgery */}

              <MDBox mb={3} width="30%" className="datepicker-parent">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Date of Surgery"
                      inputVariant="outlined"
                      className="datepicker"
                      value={dateOfSurgery}
                      //defaultValue={getValues("txtDateOfSurgery")}
                      format="DD/MM/YYYY"
                      onChange={(e) => {
                        setDateOfSurgery(e);
                        setValue(
                          "txtDateOfSurgery",
                          dayjs(e).format("YYYY-MM-DD")
                        );
                        handleDropdownChange("txtDateOfSurgery", e);
                      }}
                      renderInput={(params) => (
                        <MDInput
                          fullWidth
                          {...params}
                          {...register("txtDateOfSurgery")}
                          value={values?.txtDateOfSurgery || ""}
                          error={
                            errors.txtDateOfSurgery ||
                            responseListError?.txtDateOfSurgery
                          }
                          errors={
                            errors.txtDateOfSurgery ||
                            responseListError?.txtDateOfSurgery
                          }
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </MDBox>

              {/* Date of Discharge */}

              <MDBox mb={3} width="30%" className="datepicker-parent">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Date of Discharge"
                      inputVariant="outlined"
                      className="datepicker"
                      value={dateOfDischarge}
                      //defaultValue={getValues("txtDateOfDischarge")}
                      format="DD/MM/YYYY"
                      onChange={(e) => {
                        setDateOfDischarge(e);
                        setValue(
                          "txtDateOfDischarge",
                          dayjs(e).format("YYYY-MM-DD")
                        );
                        handleDropdownChange("txtDateOfDischarge", e);
                      }}
                      renderInput={(params) => (
                        <MDInput
                          fullWidth
                          {...params}
                          {...register("txtDateOfDischarge")}
                          value={values?.txtDateOfDischarge || ""}
                          error={
                            errors.txtDateOfDischarge ||
                            responseListError?.txtDateOfDischarge
                          }
                          errors={
                            errors.txtDateOfAdmission ||
                            responseListError?.txtDateOfDischarge
                          }
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </MDBox>

              {/* Same Day Elective Admission */}

              <MDBox mb={3} width="calc(33% - 10px)">
                <MDAutoComplete
                  options={ICUOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={values?.txtSameDayElectiveAdmission}
                  id={"txtSameDayElectiveAdmission"}
                  handleDropdownChange={handleDropdownChange}
                  value={values?.txtSameDayElectiveAdmission || ""}
                  error={
                    errors?.txtSameDayElectiveAdmission ||
                    responseListError?.txtSameDayElectiveAdmission
                  }
                  label={"Same Day Elective Admission"}
                />
              </MDBox>
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {/* Initial ICU Hours */}
              <MDBox mb={3} width="calc(50% - 10px)">
                <MDInput
                  type="number"
                  fullWidth
                  label="Initial ICU Hours"
                  name="txtInitialICUHours"
                  register={register}
                  onChange={handleChange}
                  value={values?.txtInitialICUHours || ""}
                  error={
                    errors?.txtInitialICUHours ||
                    responseListError?.txtInitialICUHours
                  }
                />
              </MDBox>

              {/* Readmission to ICU */}
              <MDBox mb={3} width="calc(50% - 10px)">
                {/* <Autocomplete
                  id="tags-outlined"
                  className="drop-down"
                  options={ICUOptions.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  defaultValue={ICUOptions.find(
                    (i) => i.id === readmissionToICU
                  )}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Readmission to ICU"}
                      placeholder={"Readmission to ICU"}
                      error={isError ? true : false}
                      value={values?.txtReadmissionToICU || ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setReadmissionToICU(newValue.value);
                      handleDropdownChange(
                        "txtReadmissionToICU",
                        newValue.value
                      );
                    }
                  }}
                /> */}

                <MDAutoComplete
                  options={ICUOptions}
                  register={register}
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  setData={setReadmissionToICU}
                  defaultValue={readmissionToICU}
                  id={"txtReadmissionToICU"}
                  handleDropdownChange={handleDropdownChange}
                  value={readmissionToICU}
                  error={
                    errors?.txtReadmissionToICU ||
                    responseListError?.txtReadmissionToICU
                  }
                  label={"Readmission to ICU"}
                  />
              </MDBox>
            </MDBox>

            {readmissionToICU === "yes" && (
              <>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  {/* Additional ICU Hours */}
                  <MDBox mb={3} width="calc(50% - 10px)">
                    <MDInput
                      type="number"
                      fullWidth
                      label="Additional ICU Hours"
                      name="txtAdditionalICUHours"
                      register={register}
                      onChange={handleChange}
                      value={values?.txtAdditionalICUHours || ""}
                      error={
                        errors?.txtAdditionalICUHours ||
                        responseListError?.txtAdditionalICUHours
                      }
                    />
                  </MDBox>

                  {/* Total Hours in ICU */}
                  <MDBox mb={3} width="calc(50% - 10px)">
                    <MDInput
                      type="text"
                      fullWidth
                      label="Total Hours in ICU"
                      name="txtTotalICUHours"
                      register={register}
                      onChange={handleChange}
                      value={values?.txtTotalICUHours || ""}
                      error={
                        errors?.txtTotalICUHours ||
                        responseListError?.txtTotalICUHours
                      }
                    />
                  </MDBox>
                </MDBox>
              </>
            )}

            <Typography className="invalid-feedback">
              {responseError}
            </Typography>

            {/* Popup component */}
            {showPopup && (
              <Popup
                message="Do you want to fill 'Preoperative Data Form'?"
                onResponse={handlePopupResponse}
              />
            )}
            <MDBox display="flex" flexDirection="column" mb={3}>
              {!isBtnLoading ? (
                <MDBox mt={4} display="flex" justifyContent="end">
                  <MDButton variant="gradient" color="info" type="submit">
                    submit
                  </MDButton>
                </MDBox>
              ) : (
                <MDBox mt={4} display="flex" justifyContent="end">
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                </MDBox>
              )}
            </MDBox>
          </form>
          {/* {isBtnLoading && <p>Data Updated Successfully...</p>} */}
        </Card>
      </Box>
      <Footer responseRef={responseRef} />
    </DashboardLayout>
  );
};

export default PersonalDataForm;
