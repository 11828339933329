
import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDInput
import MDInputRoot from "components/MDInput/MDInputRoot";
import { TextField } from "@mui/material";
import MDTypography from "components/MDTypography";

const MDInput = forwardRef(
  (
    { error, success, disabled, label, name, register, errors, onChange, value, ...rest },
    ref
  ) => (
    <>
      <MDInputRoot
        {...rest}
        ref={ref}
        ownerState={{ error, success, disabled }}
        label={label}
        {...register(name)}
        variant="outlined"
        onChange={onChange}
        value={value}

      />
      {errors && (
        <MDTypography variant="caption" color="error" fontWeight="light">
          {errors.message}
        </MDTypography>
      )}
    </>
  )
);

// Setting default values for the props of MDInput
MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the MDInput
MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

export default MDInput;
